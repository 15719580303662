import * as type from "../../types/API/newsLetters";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getNewsLettersReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_NEWS_LETTERS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_NEWS_LETTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.GET_NEWS_LETTERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addNewsLettersReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.ADD_NEWS_LETTERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_NEWS_LETTERS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_NEWS_LETTERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};


export const unsubscribeNewsLetterReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.UNSUBSCRIBE_NEWS_LETTERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UNSUBSCRIBE_NEWS_LETTERS_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.UNSUBSCRIBE_NEWS_LETTERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
