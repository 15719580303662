import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Space, Tooltip, Table, Alert, Input, Form, notification, Tag, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./manageUOM.css";
import { connect } from "react-redux";
import { addUOM, deleteUOM, editUOM, getUOM } from "../../../redux/actions/API/uomAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { AppConstants } from "../../../Appconstants";

type NotificationType = "success" | "info" | "warning" | "error";

interface IManageUnitProps {
  title: string;
  onClose: () => void;
  open: boolean;
  companyId: number;
  unitOfMetricsData: any;
  getUOM: Function;
  addUOM: Function;
  editUOM: Function;
  deleteUOM: Function;
  getUOMState: IDataResponse;
  addUOMState: IDataResponse;
  editUOMState: IDataResponse;
  deleteUOMState: IDataResponse;
}

const ManageUOM: React.FC<IManageUnitProps> = ({
  title,
  onClose,
  open,
  companyId,
  getUOM,
  addUOM,
  editUOM,
  deleteUOM,
  getUOMState,
  addUOMState,
  editUOMState,
  deleteUOMState,
  unitOfMetricsData,
}) => {
  const prevPropsRef = useRef<IManageUnitProps>();
  const [isAddingNewUnit, setIsAddingNewUnit] = useState(false);
  const [newUnit, setNewUnit] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [isEditUOM, setIsEditUOM] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const formRef = useRef<any>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getUOM({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    if (prevPropsRef.current?.getUOMState?.loading && !getUOMState.loading) {
      if (getUOMState?.error) {
        setLoading(false);
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    if (prevPropsRef.current?.addUOMState?.loading && !addUOMState.loading) {
      if (addUOMState?.error) {
        openNotificationWithIcon("error", addUOMState?.error?.message);
        setLoading(false);
      } else {
        form.resetFields();
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success", addUOMState?.data?.message);
        handleReset();
      }
    }

    if (prevPropsRef.current?.editUOMState?.loading && !editUOMState.loading) {
      if (editUOMState.error) {
        openNotificationWithIcon("error", "Something went wrong");
        setLoading(false);
      } else {
        form.resetFields();
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success", editUOMState?.data?.message);
        handleReset();
      }
    }

    if (prevPropsRef.current?.deleteUOMState?.loading && !deleteUOMState.loading) {
      if (deleteUOMState.error) {
        setLoading(false);
        openNotificationWithIcon("error", 'Something went wrong');
      } else {
        getUOM({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteUOMState?.data?.message);
      }
    }

    prevPropsRef.current = {
      title,
      onClose,
      open,
      unitOfMetricsData,
      getUOM,
      addUOM,
      editUOM,
      deleteUOM,
      getUOMState,
      addUOMState,
      editUOMState,
      deleteUOMState,
      companyId,
    };
  }, [
    getUOMState,
    addUOMState,
    deleteUOMState,
    editUOMState,
    title,
    onClose,
    open,
    unitOfMetricsData,
    getUOM,
    addUOM,
    editUOM,
    deleteUOM,
    companyId,
  ]);

  const handleEdit = (record: any) => {
    console.log(record)
    setNewUnit(record.code);
    setNewDescription(record.name);
    setIsEditUOM(true);
    setIsAddingNewUnit(true);
    setEditingId(record.id);
    form.setFieldsValue({
      code: record.code,
      name: record.name,
    });
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const handleAddNewUnit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const payload = {
          code: values.code,
          name: values.name || '',
          companyId: Number(companyId),
          ip_address: "127.0.0.1",
        };

        if (isEditUOM && editingId !== null) {
          editUOM({ ...payload, uomId: editingId });
        } else {
          addUOM(payload);
        }
      })
      .catch((errorInfo) => {
      });
  };


  const handleDelete = (id: number) => {
    setLoading(true);
    deleteUOM({ uomId: id });
  };

  const handleReset = () => {
    setIsAddingNewUnit(false);
    setIsEditUOM(false);
    setNewUnit("");
    setNewDescription("");
    setSearchTerm("");
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredUOMData = unitOfMetricsData?.filter((item: any) => {
    return (
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    {
      title: "Unit of Measurement",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      width: "150px",
      render: (_: any, record: any) =>
        record.status === 0 ? (
          <div className="mobAddCategory">
            <Tag color="green" key="default">
              Default
            </Tag>
            <Tooltip
              title={"This is a default UOM, so you can not edit or delete it."}
            >
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          </div>
        ) : (
          <Space size="middle">
            <Tooltip title="Edit">
              <span className="actionIcons" onClick={() => handleEdit(record)}>
                <EditOutlined />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <span className="actionIcons">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </Tooltip>
          </Space>
        ),
    },
  ];

  return (
    <>
      <Loader loading={loading}></Loader>
      <Drawer
        title={title}
        size={"large"}
        onClose={() => {
          onClose();
          handleReset();
        }}
        open={open}
        extra={
          <Space>
            <Button
              className="buttomCancelMobView"
              onClick={() => {
                onClose();
                handleReset();
              }}
            >
              Cancel
            </Button>
            {isAddingNewUnit && (
              <Button type="primary" onClick={() => handleAddNewUnit()}>
                {isEditUOM ? "Update" : "Submit"}
              </Button>
            )}
          </Space>
        }
      >
        {isAddingNewUnit && (
          <p className="backLinkSM" onClick={() => {
            form.resetFields()
            setIsAddingNewUnit(false)
          }}>
            <ArrowLeftOutlined />
            Back to Units
          </p>
        )}
        <Alert message={AppConstants.UOM_DATA_MAPPING.ALERT_MESSAGE} />
        {isAddingNewUnit ? (
          <div className="add-unit-form mt-20">
            <Form form={form} ref={formRef} layout="vertical" className="mt-10">
              <Form.Item
                label="UOM Code"
                name="code"
                required
                rules={[
                  {
                    required: true,
                    message: "UOM is required.",
                  },
                  {
                    pattern: /^[A-Za-z][A-Za-z0-9]{1,9}$/,
                    message: "Enter valid UOM Start with letter, 2-10 chars.",
                  },
                  {
                    validator: (_, value) => {
                      if (!value || !value.trim()) {
                        return Promise.resolve();
                      }
                      for (let uom of filteredUOMData) {
                        if (!isEditUOM) {
                          if (uom.code === value.trim()) {
                            return Promise.reject("UOM Code must be unique.");
                          }
                        }
                        else {
                          if (editingId != uom.id && uom.code === value.trim()) {
                            return Promise.reject("UOM Code must be unique.");
                          }
                        }
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  onChange={e => {
                    form.setFieldValue('code', e.target.value.toUpperCase().trim())
                  }}
                  placeholder="Enter UOM Code"
                />
              </Form.Item>
              <Form.Item label="UOM Name" name="name">
                <Input
                  placeholder="Enter UOM Name"
                />
              </Form.Item>
            </Form>
          </div>
        ) : (
          <>
            <div className="flexBox mt-20 mobAddCategory">
              <Input
                placeholder="Search Unit of measurement"
                onChange={(e) => onSearch(e.target.value)}
              />
              <Button
                type="primary"
                onClick={() => {
                  setIsAddingNewUnit(true);
                  setIsEditUOM(false);
                  setNewUnit("");
                  setNewDescription("");
                }}
              >
                Add New Unit
              </Button>
            </div>
            <Table
              bordered
              className="table-unit mt-20"
              columns={columns}
              dataSource={filteredUOMData?.reverse()}
              pagination={false}
              rowKey="id"
            />
          </>
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  unitOfMetricsData: state.api.getUOM?.data,
  getUOMState: state.api?.getUOM,
  addUOMState: state.api.addUOM,
  editUOMState: state.api.editUOM,
  deleteUOMState: state.api.deleteUOM,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUOM: (payload: any) => dispatch(getUOM(payload)),
  addUOM: (payload: any) => dispatch(addUOM(payload)),
  editUOM: (payload: any) => dispatch(editUOM(payload)),
  deleteUOM: (payload: any) => dispatch(deleteUOM(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUOM);
