export const ADD_NEWS_LETTERS = 'ADD_NEWS_LETTERS';
export const ADD_NEWS_LETTERS_SUCCESS = 'ADD_NEWS_LETTERS_SUCCESS';
export const ADD_NEWS_LETTERS_FAILED = 'ADD_NEWS_LETTERS_FAILED';

export const GET_NEWS_LETTERS = 'GET_NEWS_LETTERS';
export const GET_NEWS_LETTERS_SUCCESS = 'GET_NEWS_LETTERS_SUCCESS';
export const GET_NEWS_LETTERS_FAILED = 'GET_NEWS_LETTERS_FAILED'; 

export const UNSUBSCRIBE_NEWS_LETTERS = 'UNSUBSCRIBE_NEWS_LETTERS';
export const UNSUBSCRIBE_NEWS_LETTERS_SUCCESS = 'UNSUBSCRIBE_NEWS_LETTERS_SUCCESS';
export const UNSUBSCRIBE_NEWS_LETTERS_FAILED = 'UNSUBSCRIBE_NEWS_LETTERS_FAILED'; 
