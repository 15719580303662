import { render } from "@testing-library/react";

const fromStoreObject = '<div>test</div>'

export const tableColumns = [
  {
    title: "Transfer Number",
    dataIndex: "transferNumber",
    key: "transferNumber",
    width: "130px",
  },
  {
    title: "Item Details",
    dataIndex: "itemName",
    key: "itemName",
    width: "200px",
  },
  {
    title: "From Store",
    dataIndex: "fromStore",
    key: "fromStore",
    width: "200px",
  },
  {
    title: "To Store",
    dataIndex: "toStore",
    key: "toStore",
    width: "200px",
  },
  {
    title: "Change Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "100px",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: "100px",
  },
  {
    title: "Transferred By",
    dataIndex: "transferredBy",
    key: "transferredBy",
    width: "220px",
  },
];
