import * as type from '../../types/API/blogTypes'

export function addBlog(payload: any) {
    return {
        type: type.ADD_BLOG,
        payload: payload
    }
}

export function editBlog(payload: any){
    return {
        type: type.EDIT_BLOG,
        payload: payload
    }
}

export function deleteBlog(payload: any){
    return{
        type: type.DELETE_BLOG,
        payload: payload
    }
}

export function getBlogById(payload: any){
    return{
        type: type.GET_BLOG_BY_ID,
        payload: payload
    }
}

export function getBlogs(payload: any){
    return{
        type: type.GET_BLOGS,
        payload: payload
    }
}

export function getBlogCategories(payload: any){
    return{
        type: type.GET_BLOGS_CATEGORIES,
        payload: payload
    }
}

export function blogsSearch(payload: any){
    return{
        type: type.BLOGS_SEARCH,
        payload: payload
    }
}