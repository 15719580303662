import { Col, Input, Row } from "antd";
import React, { ChangeEvent, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { AppConstants } from "../../../Appconstants";
import BOMSeries from "./BOMSeries";

interface IBomFormProps {}

const BomForm: React.FC<IBomFormProps> = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<string | null>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [documentMetaData, setDocumentMetaData] = useState<any>({
    documentNumber: "",
    currentSeriesNumber: 0,
    seriesId: 0,
  });

  const handleFileAttachment = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedAttachments = event.target.files;
    const fileSizeLimit = 5 * 1024 * 1024;
    const maxFileCount = 5;

    if (selectedAttachments) {
      const newFiles = Array.from(selectedAttachments);

      if (attachments.length + newFiles.length > maxFileCount) {
        return;
      }

      for (const file of newFiles) {
        if (file.size > fileSizeLimit) {
          return;
        }
      }
      setAttachments([...attachments, ...newFiles]);
    }
  };

  const handleDeleteAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, idx) => idx !== index));
    if (attachments.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const renderBOMID = () => (
    <Col className="gutter-row" span={6}>
      <div className="flexBox">
        <label>BOM ID *</label>
        <span className="expoText" onClick={() => setIsVisible(true)}>
          Customize
        </span>
      </div>
      <Input placeholder="Please enter BOM ID" style={{ marginTop: "5px" }} />
    </Col>
  );

  const renderBOMName = () => (
    <Col className="gutter-row" span={6}>
      <label>BOM Name</label>
      <Input placeholder="Please enter BOM Name" style={{ marginTop: "5px" }} />
    </Col>
  );

  const renderDescription = () => (
    <Col className="gutter-row" span={6}>
      <label>BOM Description</label>
      <Input
        placeholder="Please enter BOM Description"
        style={{ marginTop: "5px" }}
      />
    </Col>
  );

  const renderFileAttachment = () => (
    <Col className="gutter-row" span={6}>
      <label>Attachments</label>
      <input
        type="file"
        multiple
        onChange={handleFileAttachment}
        ref={fileInputRef}
      />
      {attachments.length > 0 ? (
        attachments.map((attachment, index) => (
          <div key={index} className="flexBox fileList">
            <div>{attachment?.name}</div>
            <div className="deleteIcon">
              <DeleteOutlined
                className="delete-icon"
                onClick={() => handleDeleteAttachment(index)}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="file-size-info">
          {AppConstants.ATTACHMENTS_DETAIL.ATTACHMENTS_UPLOAD_DETAILS}
        </div>
      )}
    </Col>
  );
  const handleSeriesModalClose = () => {
    setIsVisible(false);
  };
  const handleSeriesSelection = (selectedSeries: string) => {
    setSelectedDocument(selectedSeries);
    handleFieldChange("documentNumber", selectedSeries);
    handleSeriesModalClose();
  };

  const handleFieldChange = (field: string, value: string) => {
    setDocumentMetaData((prevFormData: any) => {
      const updatedFormData = prevFormData.map((form: any) =>
        form.documentNumber === documentMetaData.documentNumber
          ? { ...form, [field]: value }
          : form
      );
      return updatedFormData;
    });
  };
  return (
    <>
      <div className="documentMetaDataForm">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {renderBOMID()}
          {renderBOMName()}
          {renderDescription()}
          {renderFileAttachment()}
        </Row>
      </div>
      <BOMSeries
        isVisible={isVisible}
        onClose={handleSeriesModalClose}
        onSelectSeries={handleSeriesSelection}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

export default BomForm;
