import React, { createContext, useContext, useState } from "react";
import type { DragEndEvent, DragOverEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
    closestCenter,
    DndContext,
    DragOverlay,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    useSortable,
} from "@dnd-kit/sortable";
import { Table } from "antd";

interface DataType {
    key: string;
    name: string;
    gender: string;
    age: number;
    email: string;
    address: string;
}

interface ItemsTableProps {
    columns: any;
    dataSource: DataType[];
    rowSelection: any;
}

interface HeaderCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
    id: string;
}

interface BodyCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
    id: string;
}

interface DragIndexState {
    active: UniqueIdentifier;
    over: UniqueIdentifier | undefined;
    direction?: "left" | "right";
}

const dragActiveStyle = (dragState: DragIndexState, id: string) => {
    const { active, over, direction } = dragState;
    // drag active style
    let style: React.CSSProperties = {};
    if (active && active === id) {
        style = { backgroundColor: 'gray', opacity: 0.5 };
    }
    // dragover dashed style
    else if (over && id === over && active !== over) {
        style =
            direction === 'right'
                ? { borderRight: '1px dashed gray' }
                : { borderLeft: '1px dashed gray' };
    }
    return style;
};

const DragIndexContext = createContext<DragIndexState>({ active: -1, over: -1 });

const TableBodyCell: React.FC<BodyCellProps> = (props) => {
    const dragState = useContext<DragIndexState>(DragIndexContext);
    return <td {...props} style={{ ...props.style, ...dragActiveStyle(dragState, props.id) }} />;
};

const TableHeaderCell: React.FC<HeaderCellProps> = (props) => {
    const dragState = useContext(DragIndexContext);
    const { attributes, listeners, setNodeRef, isDragging } = useSortable({ id: props.id });
    const style: React.CSSProperties = {
        ...props.style,
        cursor: isDragging?'move':'pointer',
        ...(isDragging ? { position: 'relative', userSelect: 'none' } : {}),
        ...dragActiveStyle(dragState, props.id),
    };
    return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const ItemsTable: React.FC<ItemsTableProps> = ({ columns, dataSource, rowSelection }) => {
    const [dragIndex, setDragIndex] = useState<DragIndexState>({ active: -1, over: -1 });
    const [sortableColumns, setSortableColumns] = useState(() =>
        columns.map((column: any, i: any) => ({
            ...column,
            key: `${i}`,
            onHeaderCell: () => ({ id: `${i}` }),
            onCell: () => ({ id: `${i}` }),
        }))
    );

    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }));

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setSortableColumns((prevState: any) => {
                const activeIndex = prevState.findIndex((i: any) => i.key === active?.id);
                const overIndex = prevState.findIndex((i: any) => i.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
        setDragIndex({ active: -1, over: -1 });
    };

    const onDragOver = ({ active, over }: DragOverEvent) => {
        const activeIndex = sortableColumns.findIndex((i: any) => i.key === active.id);
        const overIndex = sortableColumns.findIndex((i: any) => i.key === over?.id);
        setDragIndex({
            active: active.id,
            over: over?.id,
            direction: overIndex > activeIndex ? 'right' : 'left',
        });
    };


    return (
        <DndContext sensors={sensors} modifiers={[restrictToHorizontalAxis]} onDragEnd={onDragEnd} onDragOver={onDragOver} collisionDetection={closestCenter}>
            <SortableContext items={sortableColumns.map((i: any) => i.key)} strategy={horizontalListSortingStrategy}>
                <DragIndexContext.Provider value={dragIndex}>
                    <Table<DataType>
                        rowKey="key"
                        bordered
                        columns={sortableColumns}
                        dataSource={dataSource}
                        components={{
                            header: { cell: TableHeaderCell },
                            body: { cell: TableBodyCell },
                        }}
                        pagination={false}
                        rowSelection={rowSelection}
                    />
                </DragIndexContext.Provider>
            </SortableContext>
            <DragOverlay>
                <th style={{ backgroundColor: "gray", padding: 16 }}>
                    {sortableColumns.find((i: any) => i.key === dragIndex.active)?.title as React.ReactNode}
                </th>
            </DragOverlay>
        </DndContext>
    );
};

export default ItemsTable;
