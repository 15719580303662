import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Drawer, Select, Table, Form, Space, notification, Tooltip, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import noRecord from "../../../Utility/images/norecord.png";
import { addLogisticDetails, deleteLogisticDetails, editLogisticDetails, getLogisticDetails } from "../../../redux/actions/API/logisticDetails";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";

interface ILogisticDetailsProps {
  userId: number;
  companyId: number;
  logisticDetailsData: any;
  getLogisticDetails: Function;
  addLogisticDetails: Function;
  editLogisticDetails: Function;
  deleteLogisticDetails: Function;
  getLogisticDetailsState: IDataResponse;
  addLogisticDetailsState: IDataResponse;
  editLogisticDetailsState: IDataResponse;
  deleteLogisticDetailsState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const LogisticDetails: React.FC<ILogisticDetailsProps> = ({
  userId,
  companyId,
  logisticDetailsData,
  getLogisticDetails,
  addLogisticDetails,
  editLogisticDetails,
  deleteLogisticDetails,
  getLogisticDetailsState,
  addLogisticDetailsState,
  editLogisticDetailsState,
  deleteLogisticDetailsState,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentDetails, setCurrentDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [ipAddress, setIpAddress] = useState("127.0.0.1");
  const prevPropsRef = useRef<any>();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    getLogisticDetails({ companyId: Number(companyId) });
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        openNotificationWithIcon("error", "Something went wrong!");
      });
  }, []);

  useEffect(() => {
    if (prevPropsRef?.current?.getLogisticDetailsState?.loading && !getLogisticDetailsState?.loading) {
      if (getLogisticDetailsState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    if (prevPropsRef?.current?.addLogisticDetailsState?.loading && !addLogisticDetailsState?.loading) {
      if (addLogisticDetailsState?.error) {
        openNotificationWithIcon("error", addLogisticDetailsState.error.message);
        setLoading(false);
      } else {
        getLogisticDetails({ companyId: Number(companyId) });
        closeDrawer();
        openNotificationWithIcon("success", addLogisticDetailsState?.data?.message);
      }
    }

    if (prevPropsRef?.current?.editLogisticDetailsState?.loading && !editLogisticDetailsState?.loading) {
      if (editLogisticDetailsState?.error) {
        openNotificationWithIcon("error", editLogisticDetailsState?.error?.message);
        setLoading(false);
      } else {
        getLogisticDetails({ companyId: Number(companyId) });
        openNotificationWithIcon("success", editLogisticDetailsState?.data?.message);
        closeDrawer();
      }
    }

    if (prevPropsRef?.current?.deleteLogisticDetailsState?.loading && !deleteLogisticDetailsState?.loading) {
      if (deleteLogisticDetailsState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false)
      } else {
        getLogisticDetails({ companyId: Number(companyId) });
        openNotificationWithIcon("success", deleteLogisticDetailsState?.data?.message);
      }
    }
    prevPropsRef.current = {
      userId,
      companyId,
      getLogisticDetails,
      addLogisticDetails,
      editLogisticDetails,
      deleteLogisticDetails,
      getLogisticDetailsState,
      addLogisticDetailsState,
      editLogisticDetailsState,
      deleteLogisticDetailsState,
    };
  }, [
    getLogisticDetailsState,
    addLogisticDetailsState,
    deleteLogisticDetailsState,
    editLogisticDetailsState,
  ]);

  const openDrawer = (logistic?: any) => {
    setCurrentDetails(logistic || null);
    form.setFieldsValue(logistic || null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    form.resetFields();
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          userId: userId,
          companyId: Number(companyId),
          documentType: values.documentType,
          logisticType: values.logisticType,
          description: values.description,
          ip_address: ipAddress,
          status: 1,
        };
        setLoading(true);
        if (currentDetails) {
          const editPayload = { ...payload, logisticDetailId: currentDetails.id };
          editLogisticDetails(editPayload);
        } else {
          addLogisticDetails(payload);
        }
      })
      .catch((errorInfo) => {
        openNotificationWithIcon("error", "Please fill in all the required fields.");
      });
  };

  const handleDelete = (id: number) => {
    setLoading(true)
    deleteLogisticDetails({ logisticDetailId: Number(id) });
  };

  const columns = [
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Logistic Type",
      dataIndex: "logisticType",
      key: "logisticType",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string) => (text ? text : "N/A"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Tooltip title="Edit Details">
            <EditOutlined onClick={() => openDrawer(record)} />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this details?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button className="actionIcons" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Loader loading={loading} />
      <div className="flexBox mb-10">
      <h5 style={{ color: "#1890ff" }}>Manage logistic details</h5>
      <div></div>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => openDrawer()}
        >
           Add Logistic Details
        </Button>
      </div>
      
      {logisticDetailsData?.length === 0 ? (
        <div className="noData">
          <img
            src={noRecord}
            alt="No Records Found"
            className="no-data-dimensions"
          />
        </div>
      ) : (
        <Table
          bordered
          dataSource={logisticDetailsData}
          columns={columns}
          rowKey="id"
          locale={{ emptyText: "No data available" }}
          pagination={false}
        />
      )}
      <Drawer
        title={currentDetails ? "Edit Logistic Details" : "Add Logistic Details"}
        open={isDrawerOpen}
        onClose={closeDrawer}
        width={600}
        extra={
          <Space>
            <Button type="default" onClick={closeDrawer}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => {form.submit();
              }}>
              Submit
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            label="Document Type"
            name="documentType"
            rules={[{ required: true, message: "Document Type is required" }]}
          >
            <Select placeholder="Select Document Type">
              <Select.Option value="Sales Enquiry">Sales Enquiry</Select.Option>
              <Select.Option value="Sales Quotation">
                Sales Quotation
              </Select.Option>
              <Select.Option value="Invoice">Invoice</Select.Option>
              <Select.Option value="Order Confirmation">
                Order Confirmation
              </Select.Option>
              <Select.Option value="Delivery Challan">
                Delivery Challan
              </Select.Option>
              <Select.Option value="Sales Return">Sales Return</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Logistic Type"
            name="logisticType"
            rules={[{ required: true, message: "Logistic Type is required" }]}
          >
            <Input placeholder="Enter Logistic Type" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              rows={4}
              placeholder="Enter Description (Optional)"
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  logisticDetailsData: state.api.getLogisticDetails?.data,
  getLogisticDetailsState: state.api.getLogisticDetails,
  addLogisticDetailsState: state.api.addLogisticDetails,
  editLogisticDetailsState: state.api.editLogisticDetails,
  deleteLogisticDetailsState: state.api.deleteLogisticDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  getLogisticDetails: (payload: any) => dispatch(getLogisticDetails(payload)),
  addLogisticDetails: (payload: any) => dispatch(addLogisticDetails(payload)),
  editLogisticDetails: (payload: any) => dispatch(editLogisticDetails(payload)),
  deleteLogisticDetails: (payload: any) => dispatch(deleteLogisticDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticDetails);
