import * as type from "../../types/API/itemTypes";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
  bulkUploadSuccess: null,
  bulkEditSuccess: null,
  bulkUploadAlternateUnitSuccess: null,
  bulkLoading: false,
  bulkReconcilationSuccess: null,
  bulkReconcilationLoading: false,
  bulkUploadAlternateUnitLoading:false,
  bulkUploadErrorData: [],
  bulkEditErrorData: [],
  bulkReconcilationErrorData: [],
  bulkUploadAlternateUnitErrorData: []
};

export const itemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const addItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_ITEM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADD_ITEM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.ADD_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_ITEM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_ITEM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_ITEM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_ITEM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const bulkDeleteItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.BULK_DELETE_ITEM:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.BULK_DELETE_ITEM_SUCCESS:
      return {
        error: null,
        loading: false,
        data: action.payload.responseData,
      };
    case type.BULK_DELETE_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const bulkUploadItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.BULK_UPLOAD_ITEM:
      return {
        ...state,
        loading: true,
        error: null,

      };
    case type.BULK_UPLOAD_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bulkUploadSuccess: action?.payload?.responseData?.message || '',
        bulkUploadErrorData: action?.payload?.responseData?.invalidData || []
      };
    case type.BULK_UPLOAD_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const bulkEditItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.BULK_EDIT_ITEM:
      return {
        ...state,
        bulkLoading: true,
        error: null,
      };
    case type.BULK_EDIT_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        bulkLoading: false,
        bulkEditSuccess: action?.payload?.responseData?.message || '',
        bulkEditErrorData: action?.payload?.responseData?.invalidData || []
      };
    case type.BULK_EDIT_ITEM_FAILED:
      return {
        ...state,
        bulkLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const bulkReconcilationItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.BULK_RECONCILATION_ITEM:
      return {
        ...state,
        bulkReconcilationLoading: true,
        error: null,
      };
    case type.BULK_RECONCILATION_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        bulkReconcilationLoading: false,
        bulkReconcilationSuccess: action?.payload?.responseData?.message || '',
        bulkReconcilationErrorData: action?.payload?.responseData?.invalidData || []
      };
    case type.BULK_RECONCILATION_ITEM_FAILED:
      return {
        ...state,
        bulkReconcilationLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const bulkUploadAlternateUnitReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.BULK_UPLOAD_ALTERNATE_UNIT:
      return {
        ...state,
        bulkUploadAlternateUnitLoading: true,
        error: null,
      };
    case type.BULK_UPLOAD_ALTERNATE_UNIT_SUCCESS:
      return {
        ...state,
        error: null,
        bulkUploadAlternateUnitLoading: false,
        bulkUploadAlternateUnitSuccess: action?.payload?.responseData?.message || '',
        bulkUploadAlternateUnitErrorData: action?.payload?.responseData?.invalidData || []
      };
    case type.BULK_UPLOAD_ALTERNATE_UNIT_FAILED:
      return {
        ...state,
        bulkReconcilationLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};