import React, { useState } from "react";
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
import { Button, Checkbox, Col, DatePicker, Form, Radio, RadioChangeEvent, Row, Select, Slider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import './filter.css'

interface IFilterMenuProps {
  filterData: any[];
  filterType: string;
  onApplyFilter: (filteredData: any[]) => void;
  categoryData?: any[];
}

const FilterMenu: React.FC<IFilterMenuProps> = ({ filterData, filterType, onApplyFilter, categoryData }) => {
  const [isMinStockFilter, setIsMinStockFilter] = useState(false);
  const [isMaxStockFilter, setIsMaxStockFilter] = useState(false);
  const [priceRangeFilter, setPriceRangeFilter] = useState<[number, number]>([0, 100000]);
  const [itemTypeFilter, setItemTypeFilter] = useState({
    buy: false,
    sell: false,
    both: false,
  });
  const [createdAtRange, setCreatedAtRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [docTypeFilter, setDocTypeFilter] = useState<string[]>([]);
  const [dealStatusFilter, setDealStatusFilter] = useState<string[]>([]);
  const [ocCreatedFilter, setOCCreatedFilter] = useState<string[]>([]);
  const [fromStoreFilter, setFromStoreFilter] = useState<string[]>([]);
  const [toStoreFilter, setToStoreFilter] = useState<string[]>([]);
  const [transferredByFilter, setTransferredByFilter] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [microCategory, setMicroCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>('');

  const filterStyle = {
    padding: "12px",
    marginBottom: "16px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
  };

  const headingStyle = {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "8px",
  };

  const checkboxGroupStyle = {
    display: "block",
    marginTop: "8px",
  };

  const dateRangePickerStyle = {
    width: "100%",
    marginTop: "8px",
  };

  const applyFilters = () => {
    let filteredData = [...filterData];

    if (filterType === "itemFilter") {
      filteredData = filteredData.reverse().filter((item) => {
        const minStockCondition = isMinStockFilter
          ? item.currentStock <= item.minStock
          : true;
        const maxStockCondition = isMaxStockFilter
          ? item.currentStock > item.maxStock
          : true;
        return minStockCondition && maxStockCondition;
      });
      if (itemTypeFilter.buy || itemTypeFilter.sell || itemTypeFilter.both) {
        filteredData = filteredData.filter((item) => {
          if (itemTypeFilter.buy && item.itemType === "Buy") {
            return true;
          }
          if (itemTypeFilter.sell && item.itemType === "Sell") {
            return true;
          }
          if (itemTypeFilter.both && item.itemType === "Both") {
            return true;
          }
          return false;
        });
      }

      filteredData = category ? filteredData?.filter((item) => item.category == category) : filteredData;
      filteredData = subCategory ? filteredData?.filter((item) => item.subCategory == subCategory) : filteredData;
      filteredData = microCategory ? filteredData?.filter((item) => item.microCategory == microCategory) : filteredData;

      const [minPrice, maxPrice] = priceRangeFilter;
      filteredData = filteredData.filter(
        (item) => Number(item?.price?.replaceAll('INR', '')?.replaceAll(',', '')) >= minPrice && Number(item?.price?.replaceAll('INR', '')?.replaceAll(',', '')) <= maxPrice
      );
    }

    if (filterType === "documentFilter") {
      if (filterType === "documentFilter" && docTypeFilter.length > 0) {
        filteredData = filteredData.filter((doc) =>
          docTypeFilter.includes(doc.documentType)
        );
      }

      if (filterType === "documentFilter" && dealStatusFilter.length > 0) {
        filteredData = filteredData.filter((doc) => {
          const statusLabel: "Draft" | "Save" | null =
            doc.status === 0 ? "Draft" : doc.status === 1 ? "Save" : null;

          return statusLabel !== null && dealStatusFilter.includes(statusLabel);
        });
      }

      if (ocCreatedFilter.length > 0) {
        filteredData = filteredData.filter((doc) => {
          if (ocCreatedFilter.includes("yes") && doc.OCDate !== null) {
            return true;
          }
          if (ocCreatedFilter.includes("no") && doc.OCDate === null) {
            return true;
          }
          return false;
        });
      }
    }

    if (filterType === "historyFilter") {
      if (fromStoreFilter?.length > 0) {
        filteredData = filteredData?.filter((item) =>
          fromStoreFilter.includes(item?.fromStore)
        );
      }
      if (toStoreFilter?.length > 0) {
        filteredData = filteredData?.filter((item) =>
          toStoreFilter.includes(item?.toStore)
        );
      }

      if (transferredByFilter?.length > 0) {
        filteredData = filteredData?.filter((item) =>
          transferredByFilter?.includes(item?.transferredBy)
        );
      }
    }

    if (createdAtRange && createdAtRange[0] && createdAtRange[1]) {
      const [startDate, endDate] = createdAtRange;

      filteredData = filteredData.filter((item) => {
        const itemDate = dayjs(item?.createdAt);
        return (
          (itemDate.isAfter(startDate, "day") &&
            itemDate.isBefore(endDate, "day")) ||
          itemDate.isSame(startDate, "day") ||
          itemDate.isSame(endDate, "day")
        );
      });
    }

    setFilteredData(filteredData);
    onApplyFilter(filteredData);
  };

  const handleStockFilterChange = (checkedValues: string[]) => {
    setIsMinStockFilter(checkedValues.includes("minStock"));
    setIsMaxStockFilter(checkedValues.includes("maxStock"));
  };

  const handleTypeFilterChange = (checkedValues: string[]) => {
    setItemTypeFilter({
      buy: checkedValues.includes("buy"),
      sell: checkedValues.includes("sell"),
      both: checkedValues.includes("both"),
    });
  };

  const handlePriceRangeChange = (value: [number, number]) => {
    setPriceRangeFilter(value);
  };

  const handleDocTypeFilterChange = (selectedValues: string[]) => {
    setDocTypeFilter(selectedValues);
  };

  const handleDealStatusFilterChange = (selectedValues: string[]) => {
    setDealStatusFilter(selectedValues);
  };

  const handleOCCreatedFilterChange = (selectedValues: string[]) => {
    setOCCreatedFilter(selectedValues);
  };

  const handleFromStoreChange = (selectedValues: string[]) => {
    setFromStoreFilter(selectedValues);
  };

  const handleToStoreChange = (selectedValues: string[]) => {
    setToStoreFilter(selectedValues);
  };

  const handleTransferredByChange = (selectedValues: string[]) => {
    setTransferredByFilter(selectedValues);
  };

  const renderStockTypeFilter = () => (
    <div style={filterStyle}>
      <strong style={headingStyle}>Filter by Stock</strong>
      <Checkbox.Group
        style={{ display: "block", marginTop: 8 }}
        onChange={handleStockFilterChange}
      >
        <Checkbox value="minStock">Min Stock</Checkbox>
        <Checkbox value="maxStock">Max Stock</Checkbox>
      </Checkbox.Group>
    </div>
  );

  const renderFilterByType = () => (
    <div style={filterStyle}>
      <strong style={headingStyle}>Filter by Type</strong>
      <Checkbox.Group
        style={{ display: "block", marginTop: 8 }}
        onChange={handleTypeFilterChange}
      >
        <Checkbox value="buy">Buy</Checkbox>
        <Checkbox value="sell">Sell</Checkbox>
        <Checkbox value="both">Both</Checkbox>
      </Checkbox.Group>
    </div>
  );

  const renderDocumentTypeFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>Filter by Doc Type</strong>
      <br />
      <Checkbox.Group onChange={handleDocTypeFilterChange}>
        <Checkbox value="Sales Enquiry">Sales Enquiry</Checkbox>
        <Checkbox value="Sales Quotation">Sales Quotation</Checkbox>
        <Checkbox value="Order Confirmation">Order Confirmation</Checkbox>
        <Checkbox value="Delivery Challan">Delivery Challan</Checkbox>
        <Checkbox value="Invoice">Invoice</Checkbox>
        <Checkbox value="Sales Return">Sales Return</Checkbox>
      </Checkbox.Group>
    </div>
  );

  const renderByDealStatusFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>Filter by Deal Status</strong>
      <br />
      <Checkbox.Group onChange={handleDealStatusFilterChange}>
        <Checkbox value="draft">Draft</Checkbox>
        <Checkbox value="sent">Sent</Checkbox>
        <Checkbox value="partiallyDispatch">Partially Dispatch</Checkbox>
        <Checkbox value="dispatch">Dispatch</Checkbox>
      </Checkbox.Group>
    </div>
  );

  const renderOCCreatedFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>OC Created</strong>
      <Checkbox.Group
        style={{ ...checkboxGroupStyle }}
        onChange={handleOCCreatedFilterChange}
      >
        <Checkbox value="yes">Yes</Checkbox>
        <Checkbox value="no">No</Checkbox>
      </Checkbox.Group>
    </div>
  );

  const renderFromStoreFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>From Store</strong>
      <Checkbox.Group
        style={checkboxGroupStyle}
        onChange={handleFromStoreChange}
        options={Array.from(new Set(filterData?.map((item) => item?.fromStore)))}
      />
    </div>
  );

  const renderToStoreFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>To Store</strong>
      <Checkbox.Group
        style={checkboxGroupStyle}
        onChange={handleToStoreChange}
        options={Array.from(new Set(filterData?.map((item) => item?.toStore)))}
      />
    </div>
  );

  const renderTransferredByFilter = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>Transferred By</strong>
      <Checkbox.Group
        style={checkboxGroupStyle}
        onChange={handleTransferredByChange}
        options={Array.from(new Set(filterData?.map((item) => item?.transferredBy)))}
      />
    </div>
  );

  const renderPriceRange = () => (
    <div style={filterStyle}>
      <strong style={headingStyle}>Price Range</strong>
      <Slider
        range
        defaultValue={priceRangeFilter}
        onChange={(value) => handlePriceRangeChange(value as [number, number])}
        max={100000}
        step={500}
        tooltip={{ formatter: (value) => `₹ ${value}` }}
      />
    </div>
  );

  const renderDateRange = () => (
    <div style={filterStyle}>
      <strong style={headingStyle}>Date Range</strong> <br />
      <Radio.Group onChange={handleDateSelect}>
        <Radio value="last7Days">Last 7 Days</Radio>
        <Radio value="last30Days">Last 30 Days</Radio>
        <Radio value="last3Months">Last 3 Months</Radio>
        <Radio value="last6Months">Last 6 Months</Radio>
      </Radio.Group>
    </div>
  );

  const handleDateSelect = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const today = dayjs();
    let startDate;

    switch (value) {
      case "last7Days":
        startDate = today.subtract(7, "days");
        break;
      case "last30Days":
        startDate = today.subtract(30, "days");
        break;
      case "last3Months":
        startDate = today.subtract(3, "months");
        break;
      case "last6Months":
        startDate = today.subtract(6, "months");
        break;
      default:
        startDate = null;
    }
    setCreatedAtRange([startDate, today]);
  };

  const renderCustomDateRange = () => (
    <div onClick={(e) => e.stopPropagation()} style={filterStyle}>
      <strong style={headingStyle}>Custom Date</strong>
      <DatePicker.RangePicker
        style={{ ...dateRangePickerStyle }}
        onChange={(dates) => setCreatedAtRange(dates)}
        disabledDate={(current) => {
          return current && current.isAfter(dayjs(), "day");
        }}
      />
    </div>
  );

  const renderApplyFilter = () => (
    <Button type="primary" onClick={applyFilters}>
      Apply Filters
    </Button>
  );

  const renderGenericFilter = () => {
    if (filterType === "itemFilter") {
      return (
        <>
          <div className="filterBody">
            <div className="filterItems">
              {renderStockTypeFilter()}
              <div className="categoryContainer">
                <div>
                  <strong>
                    Filter By Category
                  </strong>
                </div>
                <div>
                  <Select
                    style={{ display: 'flex' }}
                    showSearch
                    placeholder="Please choose the category"
                    optionFilterProp="children"
                    value={category}
                    onChange={(value) => {
                      const index = categoryData?.findIndex(category => category.id === value);
                      setSubCategory('');
                      setMicroCategory('');
                      setSelectedCategory(index);
                      setSelectedSubCategory('');
                      setCategory(value);
                    }}
                  >
                    <Select.Option key='Select Category' value=''>
                      All Category
                    </Select.Option>
                    {categoryData?.map((category: any, index: number) => (
                      <Select.Option onSelect={() => {
                      }} key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div>

                  <Select
                    style={{ display: 'flex' }}
                    placeholder="Please choose the sub category"
                    value={subCategory}
                    onChange={value => {
                      const index = categoryData && categoryData[selectedCategory]?.child?.findIndex((subCategory: any) => subCategory.id === value);
                      setSubCategory(value);
                      setSelectedSubCategory(index);
                      setMicroCategory('');
                    }
                    }
                    disabled={selectedCategory === ''}
                  >
                    <Select.Option key='Select Sub Category' value=''>
                      All Sub Category
                    </Select.Option>
                    {selectedCategory != '' && categoryData && categoryData[selectedCategory]?.child.map((subCategory: any) => (
                      <Select.Option
                        key={subCategory.id}
                        value={subCategory.id}
                      >
                        {subCategory.name}
                      </Select.Option>
                    ))}
                  </Select>

                </div>
                <div>
                  <Select
                    style={{ display: 'flex' }}
                    placeholder="Please choose the micro category"
                    value={microCategory}
                    onChange={(value) => setMicroCategory(value)}
                    disabled={selectedSubCategory === ''}
                  >
                    <Select.Option value='' disabled key='Select Micro Category'>
                      Select Micro Category
                    </Select.Option>
                    {categoryData && categoryData[selectedCategory]?.child[selectedSubCategory]?.child?.map(
                      (microCategory: any) => (
                        <Select.Option
                          key={microCategory.id}
                          value={microCategory.id}
                        >
                          {microCategory.name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </div>
              </div>
              {renderFilterByType()}
              {renderPriceRange()}
              {renderDateRange()}
              {renderCustomDateRange()}
            </div>
            <div className="filterFooter">
              {renderApplyFilter()}
            </div>
          </div>
        </>
      );
    }
    if (filterType === "documentFilter") {
      return (
        <>
          <div className="filterBody">
            <div className="filterItems">
              {renderDocumentTypeFilter()}
              {renderByDealStatusFilter()}
              {renderOCCreatedFilter()}
              {renderDateRange()}
              {renderCustomDateRange()}
            </div>
            <div className="filterFooter">
              {renderApplyFilter()}
            </div>
          </div >
        </>
      );
    }

    if (filterType === "historyFilter") {
      return (
        <>
          <div className="filterBody">
            <div className="filterItems">
              {renderFromStoreFilter()}
              {renderToStoreFilter()}
              {renderTransferredByFilter()}
              {renderDateRange()}
              {renderCustomDateRange()}
            </div>
            <div className="filterFooter">
              {renderApplyFilter()}
            </div>
          </div >
        </>
      );
    }
    return null;
  };

  return <div>{renderGenericFilter()}</div>;
};

export default FilterMenu;
