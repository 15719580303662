import * as type from "../../types/API/storeTypes";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  storeByItems: [],
  loading: false,
  error: null,
  storeLoading: false
};

export const getStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.GET_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getStoreByIdReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_STORE_BY_ID:
      return {
        ...state,
        loading: true,
      }
    case type.GET_STORE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData
      }
    case type.GET_STORE_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state;
  }
}

export const getStoreByItemsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_STORE_BY_ITEMS:
      return {
        ...state,
        loading: true,
      }
    case type.GET_STORE_BY_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData
      }
    case type.GET_STORE_BY_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state;
  }
}

export const addStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.ADD_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.ADD_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.ADD_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.EDIT_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteStoreReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.DELETE_STORE:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.DELETE_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getStoreByItem = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_STORE_BY_ITEM:
      return {
        ...state,
        storeLoading: true,
      };
    case type.GET_STORE_BY_ITEM_SUCCESS:
      return {
        ...state,
        storeLoading: false,
        error: null,
        storeByItems: action.payload.responseData?.storeItems || [],
      };
    case type.GET_STORE_BY_ITEM_FAILED:
      return {
        ...state,
        storeLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};