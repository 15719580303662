import { Button, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createDocument } from "../../../../redux/actions/API/documents";
import { IDataResponse } from "../../../../redux/types/API/ApiResponse";
import { notify } from "../../../../redux/actions/API/notifications";
import { updateLastDocumentNumber } from "../../../../redux/actions/API/documentSeriesAction";
import { resetCreateDocumentData, setAdvancePaymentData } from "../../../../redux/actions/UI/createDocument";
import './totalCalculation.css'
import { uploadImage } from "../../../../redux/actions/API/imageUpload";
import store from "../../../../redux/store";
 
interface ITotals {
  totalBeforeTax: number;
  totalTax: number;
  totalAfterTax: number;
  grandTotal: number;
}

interface ITotalCalculationProps {
  createDocument: Function;
  setAdvancePaymentData: Function;
  documentCompanyAddressAndDetails: any;
  documentCompanyFormsData: any;
  documentItemData: any;
  documentLogisticData: any;
  documentAdditionalData: any;
  commentData: string;
  documentAttachmentData: any;
  documentSignatureData: string;
  documentTermsAndCondition: any[];
  documentAdditionalChargesData: any;
  addressData: any;
  documentTypeData: any;
  documentSupplierDetails: any;
  documentBuyerDetails: any;
  companyId: string;
  createDocumentState: IDataResponse;
  bankDetailData: any;
  notify: Function;
  checkIsDocumentValid: Function;
  updateLastDocumentNumber: Function;
  documentType: string;
  userId: number,
  uploadImage: Function;
  resetCreateDocumentData: Function;
  uploadAttachAndSignState: any;
  documentTo: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TotalCalculation: React.FC<ITotalCalculationProps> = ({
  documentCompanyFormsData,
  documentItemData,
  documentLogisticData,
  documentAdditionalData,
  commentData,
  documentTermsAndCondition,
  documentAdditionalChargesData,
  documentTypeData,
  createDocument,
  documentSupplierDetails,
  documentBuyerDetails,
  companyId,
  createDocumentState,
  bankDetailData,
  notify,
  checkIsDocumentValid,
  updateLastDocumentNumber,
  setAdvancePaymentData,
  documentType,
  documentSignatureData,
  documentAttachmentData,
  userId,
  uploadImage,
  uploadAttachAndSignState,
  resetCreateDocumentData,
  documentTo,
  loading, 
  setLoading,
}) => {
  const openNotificationWithIcon = (type: any, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  const prevPropsRef = useRef<any>();
  const [totals, setTotals] = useState<ITotals>({
    totalBeforeTax: 0,
    totalTax: 0,
    totalAfterTax: 0,
    grandTotal: 0,
  });

  const [advancePayment, setAdvancePayment] = useState<string>("");
  const navigate = useNavigate();
  const [attachments, setAttachments] = useState<string[]>([]);
  const [signature, setSignature] = useState<string[]>([]);
  
  useEffect(() => {
    if (
      prevPropsRef?.current?.createDocumentState?.loading &&
      !createDocumentState?.loading
    ) {
      if (createDocumentState?.error) {
        openNotificationWithIcon("error", "Something went wrong please try again later!");
        setLoading(false);
      } else {
        updateLastDocumentNumber({
          companyId: Number(companyId),
          nextNumber: Number(documentCompanyFormsData.currentSeriesNumber) + 1,
          seriesId: Number(documentCompanyFormsData.seriesId),
        });
        openNotificationWithIcon("success",`${documentTypeData} created successfully`);
        setLoading(false);
        resetCreateDocumentData();
        navigate(`/previewDocument?documentNumber=${documentCompanyFormsData.documentNumber}`);
        notify({
          notification: `New document ${documentCompanyFormsData.documentNumber} created by ${userId}`,
          companyId: companyId,
          createdBy: companyId,
          createdByName: userId,
          status: 1,
          ip_address: "127.0.0.1",
        });
      }
    }

    if (prevPropsRef?.current?.uploadAttachAndSignState?.loading && !uploadAttachAndSignState?.loading) {
      if (uploadAttachAndSignState?.error) {
        openNotificationWithIcon("error", "Something went wrong while uploading attachments!");
      } else if (uploadAttachAndSignState?.imageUrl) {
        setAttachments((prev) => {
          const filteredPrev = Array.from(new Set(prev.filter(Boolean)));
          const updatedAttachments = Array.from(
            new Set([...filteredPrev, uploadAttachAndSignState.imageUrl])
          );
          return updatedAttachments;
        });
      }
    }
    prevPropsRef.current = { createDocumentState, uploadAttachAndSignState };
  }, [uploadAttachAndSignState, createDocumentState]);

  useEffect(() => {
    let totalBeforeTax = 0;
    let totalTax = 0;
    let totalAfterTax = 0;
    let totalAdditionPrice = 0;

    if (documentItemData && documentItemData.length > 0) {
      documentItemData?.forEach((item: any) => {
        totalBeforeTax += parseFloat(item.totalBeforeTax) || 0;
        totalTax += parseFloat(item.totalTax) || 0;
        totalAfterTax += parseFloat(item.totalAfterTax) || 0;
      });
    }

    if (documentAdditionalChargesData && documentAdditionalChargesData.length > 0) {
      documentAdditionalChargesData?.forEach((charge: any) => {
        const price = parseFloat(charge.price) || 0;
        const tax = parseFloat(charge.tax) || 0;
        const currentTotal = price + (price * tax) / 100;
        totalAdditionPrice += currentTotal;
      });
    }

    const grandTotal = totalAfterTax + totalAdditionPrice;
    setTotals({ totalBeforeTax, totalTax, totalAfterTax, grandTotal });
  }, [documentItemData, documentAdditionalChargesData]);

  const validateDocument = () => {
    const { documentNumber, documentDate, store, billDate, invoiceNumber } = documentCompanyFormsData;

    let isDocumentNumberValid = documentNumber != "";
    let isDocumentDateValid = documentDate != "";
    let isStoreValid = store != "";
    let isBillDateValid = billDate != "";
    let isInvoiceNumberValid = invoiceNumber != "";

    let isValid = false;
    // Validate based on the document type
    switch (documentTypeData) {
      case "Sales Enquiry":
      case "Sales Quotation":
      case "Order Confirmation":
      case "Delivery Challan":
        isValid = isDocumentNumberValid && isDocumentDateValid && isStoreValid;
        break;

      case "Invoice":
      case "Goods Receive Notes":
      case "Quality Report":
        isValid = 
          isDocumentDateValid &&
          isStoreValid  
        break;

      case "Sales Return":
        isValid = true
          // isDocumentNumberValid &&
          // isDocumentDateValid &&
          // isInvoiceNumberValid &&
          // isStoreValid;
        break;

      case "Purchase Order":
        isValid = isDocumentNumberValid && isDocumentDateValid && isStoreValid;
        break;

      default:
        isValid = false;
    }
    return [
      isValid,
      isDocumentNumberValid,
      isDocumentDateValid,
      isStoreValid,
      isBillDateValid,
      isInvoiceNumberValid,
    ];
  };

  const handleOnBlur = () => {
    setAdvancePaymentData(advancePayment);
  };

  const submitDocument = async (type: string) => {
    let isDocumentValid = validateDocument();
    checkIsDocumentValid(isDocumentValid);
    const uploadAttachmentUrls: string[] = [];
    const uploadSignatureUrls: string[] = [];

    if (isDocumentValid[0]) {
      const uploadAttachments = async () => {
        try {
          for (const file of documentAttachmentData) {
            const formData = new FormData();
            formData.append("file", file);

            await new Promise<void>((resolve) => {
              uploadImage(formData);

              const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const uploadedImageUrl = state.api.imageUpload.imageUrl;

                if (
                  uploadedImageUrl &&
                  !uploadAttachmentUrls.includes(uploadedImageUrl)
                ) {
                  uploadAttachmentUrls.push(uploadedImageUrl);
                  resolve();
                  unsubscribe();
                }
              });
            });
          }
          setAttachments((prev) => [...prev, ...uploadAttachmentUrls]);
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      };
      const uploadSignature = async () => {
        try {
          for (const file of documentSignatureData) {
            if (!file) {
              continue;
            }
            const formData = new FormData();
            formData.append("file", file);

            await new Promise<void>((resolve) => {
              uploadImage(formData);
              const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const uploadedImageUrl = state.api.imageUpload.imageUrl;

                if (uploadedImageUrl) {
                  uploadSignatureUrls.push(uploadedImageUrl);
                  resolve();
                  unsubscribe();
                }
              });
            });
          }
          setSignature((prev) => [...prev, ...uploadSignatureUrls]);
        } catch (error) {
          console.error("Error uploading signatures:", error);
        }
      };

      try {
        await uploadAttachments();
        await uploadSignature();
        setLoading(true);
        let documentData = {
          documentType: documentTypeData,
          documentNumber: documentCompanyFormsData.documentNumber,
          buyerName: documentBuyerDetails.buyerName,
          buyerBillingAddress: documentBuyerDetails.buyerBillingAddress,
          buyerDeliveryAddress: documentBuyerDetails.buyerDeliveryAddress,
          buyerContactNumber: documentBuyerDetails.buyerContactNumber,
          buyerEmail: documentBuyerDetails.buyerEmail,
          supplierName: documentSupplierDetails.supplierName,
          supplierBillingAddress: documentSupplierDetails.supplierBillingAddress,
          supplierDeliverAddress: documentSupplierDetails.supplierDeliverAddress,
          supplierContactNo: documentSupplierDetails.supplierContactNo,
          supplierEmail: documentSupplierDetails.supplierEmail,
          documentDate: documentCompanyFormsData.documentDate,
          ammendment: documentCompanyFormsData.ammendment,
          deliveryDate: documentCompanyFormsData.deliveryDate,
          indent_number: documentCompanyFormsData.indent_number,
          indent_date: documentCompanyFormsData.indent_date,
          paymentTerm: documentCompanyFormsData.paymentTerm,
          store: documentCompanyFormsData.store,
          enquiryNumber: documentCompanyFormsData.enquiryNumber,
          enquiryDate: documentCompanyFormsData.enquiryDate,
          orderConfirmationNumber: documentCompanyFormsData.orderConfirmationNumber,
          orderConfirmationDate: documentCompanyFormsData.orderConfirmationDate,
          logisticDetailsId: documentLogisticData?.id,
          additionalDetails: documentAdditionalData[0],
          documentComments: commentData,
          signature: uploadSignatureUrls[0],
          attachments: uploadAttachmentUrls,
          companyId: Number(companyId),
          createdBy: Number(userId),
          status: type == "save" ? 1 : 0,
          ip_address: "---",
          paymentDate: documentCompanyFormsData?.paymentDate,
          POCName: documentCompanyFormsData?.POCName,
          POCNumber: documentCompanyFormsData?.POCNumber,
          POCDate: documentCompanyFormsData?.POCDate,
          OCNumber: documentCompanyFormsData?.OCNumber,
          OCDate: documentCompanyFormsData?.OCDate,
          transporterName: documentCompanyFormsData?.transporterName,
          TGNumber: documentCompanyFormsData?.transporterGSTNumber,
          TDNumber: documentCompanyFormsData?.transportationDocumentNumber,
          TDDate: documentCompanyFormsData?.transportationDocumentDate,
          VehicleNumber: documentCompanyFormsData?.vehicleNumber,
          replyDate: documentCompanyFormsData?.expectedReplyDate,
          Attention: documentCompanyFormsData?.kindAttention,
          invoiceNumber: documentCompanyFormsData?.invoiceNumber,
          invoiceDate: documentCompanyFormsData?.invoiceDate,
          billDate: documentCompanyFormsData?.billDate,
          returnRecieveDate: documentCompanyFormsData?.returnRecieveDate,
          creditNoteNumber: documentCompanyFormsData?.creditNoteNumber,
          creditNotedate: documentCompanyFormsData?.creditNoteDate,
          quotationNumber: documentCompanyFormsData?.quotationNumber,
          quotationDate: documentCompanyFormsData?.quotationDate,
          purchaseOrderNumber: documentCompanyFormsData?.purchaseOrderNumber,
          purchaseOrderDate: documentCompanyFormsData?.purchaseOrderDate,
          supplier_invoice_number: documentCompanyFormsData?.supplierInvoiceNumber,
          supplier_invoice_date: documentCompanyFormsData?.supplierInvoiceDate,
          challan_number: documentCompanyFormsData?.challanNumber,
          challan_date: documentCompanyFormsData?.challanDate,

          items: documentItemData?.map((item: any) => ({
            itemId: item.itemId,
            itemName: item.itemName,
            HSN: item.HSN,
            UOM: item.UOM,
            quantity: item.quantity,
            price: item.price,
            discountOne: item.discount1,
            discountTwo: item.discount2,
            totalDiscount: item.totalDiscount,
            taxType: item.taxType,
            tax: item.tax,
            totalTax: item.totalTax,
            totalBeforeTax: item.totalBeforeTax,
            totalAfterTax: item.totalAfterTax,
          })),

          additionalCharges: documentAdditionalChargesData?.map(
            (charge: any) => ({
              chargingFor: charge.chargingFor,
              price: charge.price,
              tax: charge.tax + "%",
              total: charge.total,
              status: 1,
              ip_address: "127.0.0.1",
            })
          ),

          bankDetails: {
            bankName: bankDetailData.bankName,
            accountName: bankDetailData.accountHolderName,
            accountNumber: bankDetailData.accountNumber,
            branch: bankDetailData.branch,
            IFSCCode: bankDetailData.IFSCCode,
            MICRCode: bankDetailData.MICRCode,
            address: bankDetailData.address,
            SWIFTCode: bankDetailData.swiftCode,
            status: bankDetailData.status,
            ip_address: null,
          },
          termsCondition: documentTermsAndCondition,
        };
        createDocument(documentData);
      } catch (error) {
        console.error("Error during document submission:", error);
      } finally {
      }
    }
  };

  return (
    <div className="flex-grow-1">
      {documentType !== "Goods Receive Notes" && documentType !== "Quality Report" && documentType !== "Purchase Invoice" && ( <div className="col-gap-20 calculation">
        <div>
          <div className="title fS-18">Total Before Tax</div>
          <div className="title fS-18">Total Tax (CGST and SGST)</div>
          <div className="title fS-18">Total After Tax</div>
          <div className="title fS-18">Additional Charges</div>
          <div className="title fS-18">Grand Total</div>
          <div className="mt-20 fS-18">Advance Payment</div>
        </div>
        <div className="values">
          <div>₹ {Number(totals.totalBeforeTax).toFixed(2)}</div>
          <div>₹ {Number(totals.totalTax).toFixed(2)}</div>
          <div className="Mt-25">₹ {Number(totals.totalAfterTax).toFixed(2)}</div>
          <div>
            ₹ {(totals.grandTotal - (totals.totalAfterTax ?? 0)).toFixed(2)}
          </div>
          <div className="Mt-25">₹ {Number(totals.grandTotal).toFixed(2)}</div>
          <div className="mt-20">
            <Input
              type="number"
              placeholder="Advance Payment"
              value={advancePayment}
              onChange={(e) => setAdvancePayment(e.target.value)}
              onBlur={handleOnBlur}
            />
          </div>
        </div>
      </div>
      )}
        <div className="button-group">
          <div className="flexBox mobile-row mt-20">
            <Button
              type="primary"
              onClick={() => {submitDocument("saveAsDraft")}}
            >
              {AppConstants.SAVE_BUTTON.SAVE_DRAFT_BUTTON}
            </Button>
            <Button
              type="primary"
              onClick={() => {submitDocument("save")}}
            >
              {AppConstants.SAVE_BUTTON.SAVE_BUTTON}
            </Button>
          </div>
        </div> 
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  userId: state.api.login.data.id,
  addressData: state.api.getAddress?.data?.reverse(),
  documentTypeData: state.ui.createDocumentUIData?.documentType,
  documentCompanyAddressAndDetails: state.ui.createDocumentUIData?.documentCompanyData,
  documentCompanyFormsData: state.ui.createDocumentUIData?.formData?.documentMetaData,
  documentItemData: state.ui.createDocumentUIData?.itemData,
  documentLogisticData: state.ui.createDocumentUIData?.logisticDetails,
  documentAdditionalData: state.ui.createDocumentUIData?.additionalDetails,
  commentData: state.ui.createDocumentUIData?.commentInput,

  documentAttachmentData: state.ui.createDocumentUIData?.attachments,
  documentSignatureData: state.ui.createDocumentUIData?.signature,

  documentTermsAndCondition: state.ui.createDocumentUIData?.termsAndCondition,
  documentAdditionalChargesData: state.ui.createDocumentUIData?.additionalCharges,

  documentBuyerDetails: state.ui.createDocumentUIData?.buyerDetails,
  documentTo: state.ui.createDocumentUIData?.documentTo,

  bankDetailData: state.ui.createDocumentUIData.bankDetails,
  documentSupplierDetails: state.ui.createDocumentUIData.supplierDetails,
  createDocumentState: state.api.createDocument,
  documentType: state.ui.createDocumentUIData.documentType,
  uploadAttachAndSignState: state.api.imageUpload,
});

const mapDispatchToProps = (dispatch: any) => ({
  resetCreateDocumentData: () => dispatch(resetCreateDocumentData()),
  createDocument: (payload: any) => dispatch(createDocument(payload)),
  updateLastDocumentNumber: (payload: any) => dispatch(updateLastDocumentNumber(payload)),
  setAdvancePaymentData: (payload: any) => dispatch(setAdvancePaymentData(payload)),
  notify: (payload: any) => dispatch(notify(payload)),
  uploadImage: (payload: any) => dispatch(uploadImage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalCalculation);
