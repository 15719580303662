import { Radio, Tabs, Col, Form, Modal, Row, Select, Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { getBuyerSupplier } from "../../../redux/actions/API/buyerSupplier";
import { connect } from "react-redux";
import { AppConstants } from "../../../Appconstants";
import "./entitySelectionForm.css";
import { setDocumentSupplierData, setDocumentTo, setDocumentType, setIndividualData } from "../../../redux/actions/UI/createDocument";
import { stateData } from "../../utility/StateData";

const { Option } = Select;
interface IEntitySelectionFormProps {
  modelTitle: string;
  isModalOpen: boolean;
  handleSubmit: (companyData: any) => void;
  handleCancel: () => void;
  buyerSupplierData: any;
  getBuyerSupplier: Function;
  companyId: number;
  setIndividualData: Function;
  setDocumentType: Function;
  setDocumentTo: Function;
  setDocumentSupplierData: Function;
  buyerAddressDetails: any;
  selectedCompanyNameAsProps?: string;
  isEditing?: boolean;
  selectedBuyer: any;
  buyerDefaultBillId?: number;
  buyerDefaultDelId?: number;
  documentTo: string;
  individualsData: any;
  supplierAddressData: any;
}

const EntitySelectionForm: React.FC<IEntitySelectionFormProps> = ({
  modelTitle,
  isModalOpen,
  handleSubmit,
  handleCancel,
  companyId,
  getBuyerSupplier,
  buyerSupplierData,
  setIndividualData,
  setDocumentType,
  setDocumentTo,   
  buyerAddressDetails,
  selectedCompanyNameAsProps,
  isEditing,
  selectedBuyer,
  buyerDefaultBillId,
  buyerDefaultDelId,
  documentTo,
  individualsData,
  supplierAddressData,
}) => {
  const [form] = Form.useForm();
  const [selectedCompanyData, setSelectedCompanyData] = useState<any>(null);
  const [defaultDeliveryAddressId, setDefaultDeliveryAddressId] = useState<any>(null);
  const [defaultBillingAddressId, setDefaultBillingAddressId] = useState<any>(null);
  const [billToType, setBillToType] = useState("company");
  const [individualDetails, setIndividualDetails] = useState({
    buyerName: "",
    buyerEmail: "",
    buyerContactNumber: "",
    addressLineOne: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | number | null>(null);

  useEffect(() => {
    getBuyerSupplier({ companyId: Number(companyId) });
  }, [companyId]);

  useEffect(() => {
    console.log(selectedBuyer)
    if (isEditing && selectedCompanyNameAsProps) {
      const selectedCompany = buyerSupplierData?.find(
        (item: any) => item.companyName === selectedCompanyNameAsProps
      );
      if (selectedCompany) {
        console.log(selectedCompany)
        setSelectedCompanyData(selectedCompany);
        setDefaultDeliveryAddressId(selectedCompany.defaultDeliveryAddressId);
        setDefaultBillingAddressId(selectedCompany.defaultBillingAddressId);
      }
    }
  }, [isEditing, selectedCompanyNameAsProps, buyerSupplierData]);
  
  useEffect(() => {
    if (selectedCompanyData) {
      const deliveryAddresses = selectedCompanyData.addresses?.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
      );

      const billingAddresses = selectedCompanyData.addresses?.filter(
        (address: any) =>
          address.addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      );

      if (deliveryAddresses?.length > 0) {
        setDefaultDeliveryAddressId(deliveryAddresses[0].id);
      }

      if (billingAddresses?.length > 0) {
        setDefaultBillingAddressId(billingAddresses[0].id);
      } else if (deliveryAddresses?.length > 0) {
        setDefaultBillingAddressId(deliveryAddresses[0].id);
      }
    }
  }, [selectedCompanyData]);

  useEffect(() => {
    if (isEditing && selectedBuyer) {
      const selectedCompany = buyerSupplierData.find(
        (item: any) => item.companyName === selectedBuyer.buyerName
      );
      if (selectedCompany) {
        setSelectedCompanyId(selectedCompany.id);
        setSelectedCompanyData(selectedCompany);
        setDefaultDeliveryAddressId(selectedCompany.defaultDeliveryAddressId);
        setDefaultBillingAddressId(selectedCompany.defaultBillingAddressId);
      }
    }
  }, [isEditing, selectedBuyer, buyerSupplierData]);
 
  
  const handleCompanySelect = (value: string | undefined) => {
    const selectedCompany = buyerSupplierData?.find(
      (item: any) => item.companyName === value
    );
  
    console.log("Matching Companies:", buyerSupplierData?.filter(
      (item: { companyName: string | undefined }) => item.companyName === selectedBuyer
    ));

    if (selectedCompany) {
      setSelectedCompanyData(selectedCompany); 
      setDefaultDeliveryAddressId(selectedCompany.defaultDeliveryAddressId); 
      setDefaultBillingAddressId(selectedCompany.defaultBillingAddressId);
    }
  };

  const getAddressType = (addressTypeId: number) => {
    return addressTypeId === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
      ? AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_NAME
      : AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_NAME;
  };

  const handleModalOk = () => {
    setDocumentType(modelTitle);     
    if (billToType === "company" && selectedCompanyData) {
      setDocumentTo("company");
      const selectedDeliveryAddress = selectedCompanyData.addresses?.find(
        (address: any) => address.id === defaultDeliveryAddressId
      );
      const selectedBillingAddress = selectedCompanyData.addresses.find(
        (address: any) => address.id === defaultBillingAddressId
      );

      const fullAddress = (address: any) =>
        address
          ? `${address.addressLineOne}, ${address.city}, ${address.state}, ${address.country}. ${address.pincode}`
          : "";
      
        let buyerDeliveryAddress = fullAddress(selectedDeliveryAddress);
        let buyerBillingAddress = fullAddress(selectedBillingAddress);

        if (!selectedDeliveryAddress || !selectedBillingAddress) {
          buyerDeliveryAddress = buyerBillingAddress = buyerDeliveryAddress || buyerBillingAddress;
        }

      handleSubmit({
        buyerName: selectedCompanyData.companyName,
        buyerEmail: selectedCompanyData.companyEmail,
        buyerContactNumber: selectedCompanyData.phone,
        buyerSelectedDeliveryAddressId: selectedDeliveryAddress?.id || selectedBillingAddress?.id,
        buyerSelectedBillingAddressId: selectedBillingAddress?.id || selectedDeliveryAddress?.id,
        buyerDeliveryAddress,
        buyerBillingAddress,
      });
    } else if (billToType === "individual") {
      form
        .validateFields()
        .then((values) => {
          const {
            buyerName,
            buyerEmail,
            buyerContactNumber,
            addressLineOne = "",
            pincode = "",
            city = "",
            state = "",
            country = "",
          } = values;
          setDocumentTo("individual");
          const buyerBillingAddress = `${addressLineOne}, ${city}, ${state}, ${country}. ${pincode}`;

          const newIndividualDetails = {
            ...values,
            buyerBillingAddress, 
          };
          setIndividualDetails(newIndividualDetails);
          setIndividualData(newIndividualDetails);
          handleSubmit(newIndividualDetails);
        })
        .catch((error) => {
          console.error("Validation failed:", error);
        });
    }
  };

  const handleCancelClick = () => {
    if (!window.location.href.includes("create_document")) {
      form.resetFields();
      setSelectedCompanyData(null);
    }
    handleCancel();
  };

  const handleBuyerRadioChange = (addressId: number, addressType: number) => {
    if (addressType === AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID) {
      setDefaultDeliveryAddressId(addressId);
    } else if (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID) {
      setDefaultBillingAddressId(addressId);
    }
  };

  const renderBuyerAddress = (
    address: any,
    index: number,
    addressType: number
  ) => (
    <div key={index} className="addressList" style={{ position: "relative" }}>
      <div className="addressHeader flexBox">
        <div className="title">{getAddressType(address.addressType)}</div>
        <div className="radioContainer">
          <Radio
            checked={
              address.id ===
              (addressType === AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                ? defaultBillingAddressId
                : defaultDeliveryAddressId)
            }
            onChange={() => handleBuyerRadioChange(address.id, addressType)}
          />
        </div>
      </div>
      <div className="addressDetails">
        <div>
          {`${address.addressLineOne} ${address.city} ${address.state} ${address.pincode} ${address.country}`}
        </div>
      </div>
    </div>
  );

  const billToRadioChange = (e: any) => {
    setBillToType(e.target.value);
  };

  const handleBlur = (name: string, value: string) => {
    setIndividualDetails((prevDetails) => {
      return {
        ...prevDetails,
        [name]: value,
      };
    });
  };

  return (
    <Modal
      title={modelTitle}
      open={isModalOpen}
      onCancel={handleCancelClick}
      className="companyMetaDataModalMob"
      footer={[
        <Button key="cancel" onClick={handleCancelClick}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="ok"
          disabled={
            billToType === "company" &&
            (!selectedCompanyData ||
              !(defaultDeliveryAddressId || defaultBillingAddressId))
          }
          onClick={handleModalOk}
        >
          Submit
        </Button>
      ]}
      maskClosable={false}
    >
      <Form layout="vertical" form={form}>
        <Form.Item>
          <Radio.Group onChange={billToRadioChange} value={billToType}>
            <Radio value="company">To Company</Radio>
            <Radio value="individual">To Individual</Radio>
          </Radio.Group>
        </Form.Item>

        {billToType === "company" &&  (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="company"
                label="Company"
                rules={[{ required: true, message: "Please choose the company" }]}
              >
                <Select
                  placeholder="Select a company"
                  value={selectedCompanyData?.companyName || selectedBuyer?.buyerName || undefined}
                  onChange={handleCompanySelect} 
                >
                  {buyerSupplierData?.map((item: any) => (
                    <Select.Option key={item.id} value={item.companyName}>
                      {item.companyName}
                    </Select.Option>
                  ))}
                </Select>                  
              </Form.Item>
            </Col>
          </Row>
        )}

        {billToType === "individual" && (
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="buyerName"
                label="Full Name"
                rules={[
                  { required: true, message: "Please enter the full name" },
                ]}
              >
                <Input
                  placeholder="Enter full name"
                  onBlur={(e) => handleBlur("name", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="buyerEmail"
                label="Email"
                rules={[
                  { required: true, message: "Please enter the email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input
                  placeholder="Enter email"
                  onBlur={(e) => handleBlur("buyerEmail", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="buyerContactNumber"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid contact number",
                    pattern: /^[6-9]\d{9}$/,
                  },
                ]}
              >
                <Input
                  prefix="+91 "
                  maxLength={10}
                  placeholder="Enter contact number"
                  onBlur={(e) => handleBlur("buyerContactNumber", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="formLabel mb-10">Billing Address</div>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="addressLineOne"
                label="Address"
                rules={[{ required: true, message: "Please enter an address" }]}
              >
                <Input
                  placeholder="Please enter an address"
                  onBlur={(e) => handleBlur("addressLineOne", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="pincode"
                label="Pin Code"
                rules={[
                  { required: false, message: "Please enter a pincode" },
                  {
                    pattern: /^\d{6}$/,
                    message: "Pin code must be a 6-digit number",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter pincode"
                  maxLength={6}
                  onBlur={(e) => handleBlur("pincode", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  { required: true, message: "Please enter your city" },
                  { max: 35, message: "City must be less than 35 characters" },
                  {
                    pattern: /^[A-Za-z\s]*$/,
                    message:
                      "City must be a string with no numbers or special characters",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your city"
                  onBlur={(e) => handleBlur("city", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[{ required: true, message: "Please enter your state" }]}
              >
                <Select
                  showSearch
                  placeholder="Select a state"
                  onChange={(value) => handleBlur("state", value)}
                >
                  {stateData.India.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="country"
                label="Country"
                rules={[{ required: true, message: "Please select a country" }]}
                initialValue="India"
              >
                <Select
                  placeholder="Select a country"
                  value="India"
                  onChange={(value) => handleBlur("country", value)}
                >
                  <Option value="India">India</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {selectedCompanyData && billToType === "company" && (
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "Delivery Address",
                children:
                  selectedCompanyData.addresses?.filter(
                    (address: any) =>
                      address.addressType ===
                      AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
                  ).length > 0 ? (
                    selectedCompanyData.addresses
                      ?.filter(
                        (address: any) =>
                          address.addressType ===
                          AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
                      )
                      ?.map((address: any, index: number) =>
                        renderBuyerAddress(
                          address,
                          index,
                          AppConstants.ADDRESS_TYPE.DELIVERY.ADDRESS_ID
                        )
                      )
                  ) : (
                    <div className="addressList">
                      No delivery addresses available.
                    </div>
                  ),
              },
              {
                key: "2",
                label: "Billing Address",
                children:
                  selectedCompanyData.addresses?.filter(
                    (address: any) =>
                      address.addressType ===
                      AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                  ).length > 0 ? (
                    selectedCompanyData.addresses
                      ?.filter(
                        (address: any) =>
                          address.addressType ===
                          AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                      )
                      ?.map((address: any, index: any) =>
                        renderBuyerAddress(
                          address,
                          index,
                          AppConstants.ADDRESS_TYPE.BILLING.ADDRESS_ID
                        )
                      )
                  ) : (
                    <div className="addressList">
                      No billing addresses available.
                    </div>
                  ),
              },
            ]}
          />
        )}
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  buyerSupplierData: state.api.getBuyerSupplier?.data,
  supplierAddressData: state.api.getAddress?.data,
  companyId: state.api.login.data.companyId,
  buyerAddressDetails: state.ui.createDocumentUIData?.buyerDetails,
  documentTo: state.ui.createDocumentUIData?.documentTo,
  individualsData: state.ui.createDocumentUIData?.individualData,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
  setIndividualData: (payload: any) => dispatch(setIndividualData(payload)),
  setDocumentSupplierData: (payload: any) => dispatch(setDocumentSupplierData(payload)),
  setDocumentType: (payload: any) => dispatch(setDocumentType(payload)),
  setDocumentTo: (payload: any) => dispatch(setDocumentTo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitySelectionForm);
