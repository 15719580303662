import React, { useEffect, useState } from "react";
import { Input, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./documnetTermsAndCondition.css";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setDocsTermsCondition } from "../../../../redux/actions/UI/createDocument";
import { getTermsCondition } from "../../../../redux/actions/API/termsCondition";
import { Tag } from "antd";
const { TextArea } = Input;
const { Option } = Select;

interface ITermsAndConditionProps {
  companyId: number;
  dataSource: any[];
  documentType: string;
  termsConditionData: any;
  getTermsCondition: Function;
  setDocsTermsCondition: Function;
}

const TermsAndCondition: React.FC<ITermsAndConditionProps> = ({
  companyId,
  dataSource,
  termsConditionData,
  getTermsCondition,
  documentType,
  setDocsTermsCondition,
}) => {
  const [termsCondition, setTermsCondition] = useState<string>("");
  const [selectedTerms, setSelectedTerms] = useState<number[]>([]);

  useEffect(() => {
    getTermsCondition({ companyId: Number(companyId) });
  }, []);

  const getAllTerms = () => termsConditionData?.flatMap((doc: any) => doc.termsData) || [];

  const getTermById = (id: number) => 
    getAllTerms().find((term: any) => term.id === id);

  useEffect(() => {
    if (termsConditionData && Array.isArray(dataSource)) {
      const validTerms = dataSource
        ?.map(item => getTermById(item.id))
        .filter(Boolean);
      
      const validIds = validTerms?.map(term => term.id);
      setSelectedTerms(validIds);

      const descriptions = validTerms
        ?.map(term => term.desc)
        .join('\n');
      setTermsCondition(descriptions);
    }
  }, [dataSource, termsConditionData]);

  const filteredTerms = termsConditionData?.find(
    (doc: { docType: string }) => doc.docType === documentType
  )?.termsData || [];

  const handleTermsConditionChange = (selectedIds: number[]) => {
    const validTerms = selectedIds
      ?.map(id => getTermById(id))
      .filter(Boolean);
    
    const validIds = validTerms?.map(term => term.id);
    setSelectedTerms(validIds);
    
    setDocsTermsCondition(validTerms);
    
    const descriptions = validTerms
      ?.map(term => term.desc)
      .join('\n');
    setTermsCondition(descriptions);
  };

  const handleBlur = () => {
    const finalData = selectedTerms
      ?.map(id => getTermById(id))
      .filter(Boolean);
    setDocsTermsCondition(finalData);
  };

  return (
    <div className="flex-grow-1">
      <div className="title mb-20 fS-20">
        {AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL} &nbsp;
        <Tooltip title={AppConstants.TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS_LABEL}>
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      </div>
      <Select
        mode="multiple"
        placeholder="Select Terms"
        style={{ width: "100%", marginBottom: 10 }}
        value={selectedTerms}
        onChange={handleTermsConditionChange}
        optionFilterProp="children"
        filterOption={(input, option) => 
          option?.children?.toString().toLowerCase().includes(input.toLowerCase()) ?? false
        }
        tagRender={({ isMaxTag, ...restProps }) => {
          const term = getTermById(restProps.value);
          return (
            <Tag {...restProps}>
              {term?.term || restProps.value}
            </Tag>
          );
        }}
      >
        {filteredTerms?.map((term: { id: number; term: string }) => (
          <Option key={term.id} value={term.id}>
            {term.term}
          </Option>
        ))}
      </Select>

      <TextArea
        style={{ resize: "none" }}
        className="termCondition-textarea mb-20"
        rows={10}
        maxLength={1000}
        showCount
        placeholder="Select Terms and condition"
        value={termsCondition}
        onChange={(e) => setTermsCondition(e.target.value)}
        onBlur={handleBlur}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  termsConditionData: state.api.getTermsCondition?.data,
  documentType: state.ui.createDocumentUIData.documentType,
  dataSource: state.ui.createDocumentUIData?.termsAndCondition || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocsTermsCondition: (payload: any) => dispatch(setDocsTermsCondition(payload)),
  getTermsCondition: (payload: any) => dispatch(getTermsCondition(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndCondition);