import { useEffect, useState } from "react";
import { Card, Tooltip, Input, Select } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setLogisticDetailsData } from "../../../../redux/actions/UI/createDocument";
import { getLogisticDetails } from "../../../../redux/actions/API/logisticDetails";
const { TextArea } = Input;

interface ILogisticDetailsProps {
  companyId: number;
  logisticsData: any[];
  logisticDetailsValue: any;
  setLogisticDetailsData: Function;
  getLogisticDetails: Function;
}

const LogisticDetails: React.FC<ILogisticDetailsProps> = ({
  companyId,
  logisticsData,
  setLogisticDetailsData,
  logisticDetailsValue,
  getLogisticDetails,
}) => {
  const [selectedLogistic, setSelectedLogistic] = useState<any>(null);
  const [logisticDetails, setLogisticDetails] = useState("");

  useEffect(() => {
    getLogisticDetails({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {    
    if (logisticDetailsValue && Object.keys(logisticDetailsValue).length > 0) {
      setSelectedLogistic(logisticDetailsValue);
      setLogisticDetails(logisticDetailsValue.description || "");
      setLogisticDetailsData(logisticDetailsValue);
    } else {
      setSelectedLogistic(null);
      setLogisticDetails("");
      setLogisticDetailsData(undefined);
    }
  }, [logisticDetailsValue]);

  const handleBlur = () => {
    const newData = logisticDetails
      ?.toString()
      ?.split("\n")
      ?.filter((line: string) => line.trim() !== "");
    setLogisticDetailsData({
      ...selectedLogistic,
      description: logisticDetails,
      lines: newData,
    });
  };

  const handleLogisticChange = (value: string) => {
    const selectedData = logisticsData?.find((item: any) => item.logisticType === value);
    setSelectedLogistic(selectedData);
    setLogisticDetails(selectedData?.description || "");
    setLogisticDetailsData(selectedData);
  };

  const logisticOptions = logisticsData?.length > 0 ? logisticsData : logisticDetailsValue ? [logisticDetailsValue] : [];
  
  return (
    <Card className="custom-card no-header-border flex-grow-1">
      <div className="card-content">
        <div className="title mb-20 fS-20">
          {AppConstants.LOGISTIC_DETAILS.LOGISTIC_DETAILS_LABEL} &nbsp;
          <Tooltip title={AppConstants.LOGISTIC_DETAILS.LOGISTIC_DETAILS_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        <Select
          style={{ width: "100%" }}
          placeholder="Select Logistic Type"
          onChange={handleLogisticChange}
          value={selectedLogistic?.logisticType || undefined}
        >
          {logisticOptions?.map((logistic: any) => (
            <Select.Option key={logistic.id} value={logistic.logisticType}>
              {logistic.logisticType}
            </Select.Option>
          ))}
        </Select>

        <div className="mt-10">
          <div>{AppConstants.LOGISTIC_DETAILS.WRITE_LOGISTIC_DETAIL}</div>
          <TextArea
            style={{ resize: "none" }}
            readOnly
            rows={3}
            placeholder="Logistic Details"
            className="mb-20"
            maxLength={100}
            showCount
            value={logisticDetails}
            onChange={(e) => setLogisticDetails(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  logisticDetailsValue: state.ui.createDocumentUIData.logisticDetails,
  logisticsData: state.api.getLogisticDetails?.data, 
});

const mapDispatchToProps = (dispatch: any) => ({
  setLogisticDetailsData: (payload: any) => dispatch(setLogisticDetailsData(payload)),
  getLogisticDetails: (payload: any) => dispatch(getLogisticDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticDetails);
