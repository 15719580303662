import React, { Component } from "react";
import { Button, Input, Form, Row, Col, Popconfirm, Space, Table, Tag, Drawer, Select, notification, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SeriesData, ProfileSeriesMetaDataModalProps, SeriesMetaDataModalState } from "../IProfileSeries";
import { addDocumentSeries, deleteDocumentSeries, editDocumentSeries, getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { connect } from "react-redux";
import noData from "../../../Utility/images/noData.png";
import Loader from "../../../Home/Loader/Loader";

type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

class ProfileDocumentSeries extends Component<
  ProfileSeriesMetaDataModalProps,
  SeriesMetaDataModalState
> {
  formRef: any;

  constructor(props: ProfileSeriesMetaDataModalProps) {
    super(props);
    this.state = {
      seriesName: "",
      series: "",
      number: undefined,
      docType: "",
      dataSource: this.props.documentSeriesData,
      activeKey: null,
      isFormVisible: false,
      isEditing: false,
      editRecord: null,
      ip_address: "",
      loading: true,
      currentPage: 1,
      pageSize: 10,
    };
    this.formRef = React.createRef();
  }

  componentDidMount(): void {
    this.props.getDocumentSeries({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<ProfileSeriesMetaDataModalProps>,
    prevState: Readonly<SeriesMetaDataModalState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getDocumentSeriesState?.loading &&
      !this.props.getDocumentSeriesState?.loading
    ) {
      if (this.props.getDocumentSeriesState?.error ) {
        this.openNotificationWithIcon("error", this.props.addDocumentSeriesState?.error?.message);
        this.setState({
          loading: false, 
        });
      } else {
        this.setState({
          loading: false,
          dataSource: this.props.documentSeriesData,
        });
      }
    }

    if (
      prevProps.addDocumentSeriesState?.loading &&
      !this.props.addDocumentSeriesState?.loading
    ) {
      if (this.props.addDocumentSeriesState?.error ) {
        this.openNotificationWithIcon("error", this.props.addDocumentSeriesState?.error?.message);
        this.setState({loading:false})
      } else {
        this.openNotificationWithIcon("success", this.props.addDocumentSeriesState?.data?.message);
        this.props.getDocumentSeries({
          companyId: Number(this.props.companyId),
        });
        this.setState({ dataSource: this.props.documentSeriesData, });
        this.formRef.current.resetFields();
        this.setState({
          isEditing: false,
          editRecord: null,
          isFormVisible: false,
        });
      }
    }

    if (
      prevProps.editDocumentSeriesState?.loading &&
      !this.props.editDocumentSeriesState?.loading
    ) {
      if (this.props.editDocumentSeriesState?.error) {
        this.openNotificationWithIcon("error", this.props.editDocumentSeriesState?.error?.message)
        this.setState({ loading: false });
      } else {
        this.props.getDocumentSeries({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", this.props.editDocumentSeriesState?.data?.message);
        this.setState({
          dataSource: this.props.documentSeriesData,
        });
        this.formRef.current.resetFields();
        this.setState({
          isEditing: false,
          editRecord: null,
          isFormVisible: false,
        });
      }
    }

    if (
      prevProps.deleteDocumentSeriesState?.loading &&
      !this.props.deleteDocumentSeriesState?.loading
    ) {
      if (this.props.deleteDocumentSeriesState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete address");
      } else {
        this.props.getDocumentSeries({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",this.props.deleteDocumentSeriesState?.data?.message
        );
        this.setState({
          dataSource: this.props.documentSeriesData,
        });
      }
    }
    if (prevProps.documentSeriesData !== this.props.documentSeriesData) {
      this.setState({
        // filteredData: this.props.documentSeriesData,
      });
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      this.setState({ number: value });
    }
  };

  handleSubmit = () => {
    this.formRef.current
      .validateFields()
      .then((values: any) => {
        const { seriesName, series, number, docType } = values;
        const { dataSource, isEditing, editRecord } = this.state;
        const documentNumber = `${series}${number}`;
        this.setState({ loading: true });
        if (isEditing && editRecord) {
          this.props.editDocumentSeries({
            id: editRecord.id,
            seriesName: seriesName,
            series: series,
            number: number,
            nextNumber: number,
            default: dataSource.length > 0 ? 0 : 1,
            docType: docType,
            companyId: Number(this.props.companyId),
            userId: Number(this.props.userId),
            ip_address: "192.168.1.1",
            isActive: editRecord.isActive,
            documentNumber: documentNumber,
          });

        } else {
          this.props.addDocumentSeries({
            seriesName: seriesName,
            series: series,
            number: number,
            nextNumber: number,
            default: dataSource.length > 0 ? 0 : 1,
            docType: docType,
            companyId: Number(this.props.companyId),
            userId: Number(this.props.userId),
            ip_address: "127.0.0.1",
          });

          this.setState((prevState) => ({
            dataSource: [
              ...prevState.dataSource.map((series) => ({
                ...series,
                isActive: false,
              })),
            ],
          }));
          const documentNumbers = [
            documentNumber,
            ...dataSource.map((series) => series.documentNumber),
          ];
        }
      })
      .catch((errorInfo: any) => {});
  };

  handleAddNewSeries = () => {
    this.setState({ isFormVisible: true, isEditing: false });
  };

  handleCancel = () => {
    this.setState({ isFormVisible: false });
    this.formRef.current.resetFields();
  };

  handleEditSeries = (record: SeriesData) => {
    this.setState(
      {
        isEditing: true,
        editRecord: record,
        isFormVisible: true,
      },
      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            seriesName: record.seriesName,
            series: record.prefix,
            number: record.number,
            docType: record.DocType,
          });
        }
      }
    );
  };

  handleDelete = (id: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteDocumentSeries({
      id: id,
    });
  };

  formatData = (dataSource: any) => {
    let data = this.state.dataSource?.map((elem: any) => {
      return {
        id: elem.id,
        DocType: elem.DocType,
        seriesName: elem.seriesName,
        prefix: elem.prefix,
        number: elem.number,
        numberData: elem.prefix + elem.number,
        nextNumber: elem.nextNumber,
        nextNumberData: elem.prefix + elem.nextNumber,
        default: elem.default,
        defaultData:
          elem.default == 1 ? (
            <Tag color="green">Default</Tag>
          ) : (
            <Tag color="blue" style={{ cursor: "pointer" }}>
              Set as default
            </Tag>
          ),
      };
    });
    return data;
  };

  onPageChange = (page: number, pageSize: number) => {
    this.setState({ currentPage: page });
    this.setState({ pageSize: pageSize });
  };

  render() {
    const {
      docType,
      seriesName,
      series,
      number,
      dataSource,
      activeKey,
      isFormVisible,
      isEditing,
      currentPage,
      pageSize,
    } = this.state;
    const startIndex = (currentPage - 1) * pageSize;
    const currentData = this.formatData(dataSource)?.slice(
      startIndex,
      startIndex + pageSize
    );

    const columns = [
      {
        title: "Document Type",
        dataIndex: "DocType",
        key: "docType",
        sorter: (a: { DocType: string }, b: { DocType: any }) =>
          a.DocType.localeCompare(b.DocType),
      },
      {
        title: "Name",
        dataIndex: "seriesName",
        key: "seriesName",
        sorter: (a: { seriesName: string }, b: { seriesName: any }) =>
          a.seriesName.localeCompare(b.seriesName),
      },
      {
        title: "Prefix",
        dataIndex: "prefix",
        key: "series",
        sorter: (a: { prefix: string }, b: { prefix: any }) =>
          a.prefix.localeCompare(b.prefix),
      },
      {
        title: "Doc. Starting No.",
        dataIndex: "numberData",
        key: "number",
        sorter: (a: any, b: any) => a.numberData.localeCompare(b.numberData),
      },
      {
        title: "Doc. Next No.",
        dataIndex: "nextNumberData",
        key: "nextNumber",
        sorter: (a: any, b: any) => {
          const nextA = String(a.nextNumberData);
          const nextB = String(b.nextNumberData);
          return nextA.localeCompare(nextB);
        },
      },
      {
        title: "Default",
        dataIndex: "defaultData",
        key: "defaultData",
        sorter: (a: any, b: any) => {
          const defaultA = a.default;
          const defaultB = b.default;
          return defaultA - defaultB;
        },
        render: (defaultData: any, record: any) =>
          record.default == 1 ? (
            <Tag color="green">Default</Tag>
          ) : (
            <Tag color="blue" style={{ cursor: "pointer" }}>
              Set as default
            </Tag>
          ),
      },
      {
        title: "Action",
        key: "action",
        width: "100px",
        render: (_: any, record: any) => (
          <Space size="middle">
            <a onClick={() => this.handleEditSeries(record)}>
              <EditOutlined />
            </a>
            <Popconfirm
              title="Are you sure to delete this?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => this.handleDelete(record.id)}
            >
              <a key="list-loadmore-more">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </Space>
        ),
      },
    ];

    return (
      <div>
        <Loader loading={this.state.loading} />
        <div className="flexBox mb-10">
          <h5 style={{ color: "#1890ff" }}>Customize document number</h5>
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={this.handleAddNewSeries}
          >
            Add New Series
          </Button>
        </div>

        {dataSource.length > 0 ? (
          <>
            <Table
              bordered
              dataSource={currentData}
              columns={columns}
              rowKey="id"
              pagination={false}
              sticky
              locale={{ emptyText: "No data available" }}
            />
            <Pagination
              total={this.formatData(dataSource)?.length}
              pageSize={pageSize}
              onChange={this.onPageChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "50"]}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              style={{ textAlign: "center", marginTop: 20 }}
            />
          </>
        ) : (
          <div className="center">
            <img src={noData} alt="bank details" />
            <div>No records available!</div>
          </div>
        )}

        <Drawer
          title={
            isEditing
              ? "Edit Custom Document Number Series"
              : "Create Custom Document Number Series"
          }
          width={720}
          onClose={this.handleCancel}
          open={isFormVisible}
          style={{ paddingBottom: 80 }}
          extra={
            <Space>
              <div style={{ textAlign: "right" }}>
                <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button onClick={() => this.handleSubmit()} type="primary">
                  {isEditing ? "Update" : "Submit"}
                </Button>
              </div>
            </Space>
          }
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            requiredMark
            className="series-form"
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Document Type"
                  name="docType"
                  rules={[
                    {
                      required: true,
                      message: "Please select a document type",
                    },
                  ]}
                >
                  <Select placeholder="Select document type">
                    <Option value="Sales Enquiry">Sales Enquiry</Option>
                    <Option value="Sales Quotation">Sales Quotation</Option>
                    <Option value="Order Confirmation">
                      Order Confirmation
                    </Option>
                    <Option value="Delivery Challan">Delivery Challan</Option>
                    <Option value="Invoice">Invoice</Option>
                    <Option value="Sales Return">Sales Return</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Series Name"
                  name="seriesName"
                  rules={[
                    { required: true, message: "Please fill the series name" },
                  ]}
                >
                  <Input
                    value={seriesName}
                    onChange={(e) =>
                      this.setState({ seriesName: e.target.value })
                    }
                    placeholder="Enter series name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Prefix"
                  name="series"
                  rules={[
                    { required: true, message: "Please fill the prefix" },
                  ]}
                >
                  <Input
                    value={series}
                    onChange={(e) => this.setState({ series: e.target.value })}
                    placeholder="Enter prefix"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Number"
                  name="number"
                  rules={[
                    { required: true, message: "Please fill the number" },
                  ]}
                >
                  <Input
                    type="number"
                    value={number}
                    onChange={this.handleNumberChange}
                    placeholder="Enter number"
                    disabled={isEditing}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  documentSeriesData: state.api.getDocumentSeries?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getDocumentSeriesState: state.api.getDocumentSeries,
  addDocumentSeriesState: state.api.addDocumentSeries,
  editDocumentSeriesState: state.api.editDocumentSeries,
  deleteDocumentSeriesState: state.api.deleteDocumentSeries,
});

const mapDispatchToProps = (dispatch: any) => ({
  addDocumentSeries: (payload: any) => dispatch(addDocumentSeries(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  editDocumentSeries: (payload: any) => dispatch(editDocumentSeries(payload)),
  deleteDocumentSeries: (payload: any) => dispatch(deleteDocumentSeries(payload)),
});

export default connect(mapStateToProps,  mapDispatchToProps)(ProfileDocumentSeries);
