import * as type from "../../types/API/documents";
import { IAction } from "../userMetadataReducer";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getDocumentsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.GET_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const getDocumentByIdReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.GET_DOCUMENTS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case type.GET_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.GET_DOCUMENTS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const createDocumentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.CREATE_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.responseData,
      };
    case type.CREATE_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const editDocumentReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case type.EDIT_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case type.EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.EDIT_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const discardDocumentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DISCARD_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case type.DISCARD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DISCARD_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.DELETE_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.DELETE_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const previewDocumentReducer = (
  state = initialState,
  action: IAction
) => {
  switch (action.type) {
    case type.PREVIEW_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case type.PREVIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.responseData,
      };
    case type.PREVIEW_DOCUMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};