import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Space, Timeline, Table, Input, Popover, Avatar } from "antd";
import { DownloadOutlined, FilterOutlined, UserOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { itemStockTransferHistory, stockTransferHistory } from "../../../redux/actions/API/transferHistory";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import Loader from "../../../Home/Loader/Loader";
import { tableColumns } from "./historyConstant";
import { TableRowSelection } from "antd/es/table/interface";
import { downloadExcel } from "../../../Utility/downloadExcel";
import FilterMenu from "../../../Home/Components/filter/FilterMenu";
import noRecord from "../../../Utility/images/norecord.png";

interface IHistoryProps {
  title: string;
  onClose: () => void;
  open: boolean;
  selectedItem: any;
  itemStockTransferHistoryData: any;
  stockTransferHistoryData: any;
  itemStockTransferHistory: Function;
  stockTransferHistory: Function;
  itemStockTransferHistoryDataState: IDataResponse;
  UOMData?: any;
  itemsData?: any;
}

const HistoryData: React.FC<IHistoryProps> = ({
  title,
  onClose,
  open,
  itemStockTransferHistoryData,
  stockTransferHistoryData,
  selectedItem,
  itemStockTransferHistory,
  stockTransferHistory,
  itemStockTransferHistoryDataState,
  UOMData,
  itemsData
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDocs, setFilteredDocs] = useState<any[]>([]);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [apiType, setApiType] = useState<"item" | "company">("item");
  const prevPropsRef = useRef<any>();
  const [metricsUnit, setMetricsUnit] = useState('');

  useEffect(() => {
    if (selectedItem) {
      setApiType("item");
      fetchStockTransferHistory();
    } else if (selectedItem) {
      setApiType("company");
      fetchStockTransferHistory();
    }
  }, [selectedItem]);

  const fetchStockTransferHistory = () => {
    setLoading(true);
    if (apiType === "item") {
      itemStockTransferHistory({ itemId: Number(selectedItem) });
    } else {
      stockTransferHistory({ companyId: Number(selectedItem) });
    }
  };

  useEffect(() => {
    const item: any = itemsData?.filter((items: any) => items?.id == selectedItem);
    if (item?.length) {
      const metrics: any = UOMData?.filter((uom: any) => uom.id == item[0]?.metricsUnit);
      if (metrics?.length) {
        setMetricsUnit(metrics[0]?.name + ' ( ' + metrics[0]?.code + ' ) ');
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    const safeStockTransferHistoryData = stockTransferHistoryData || [];
    if (apiType === "item" && Array.isArray(itemStockTransferHistoryData)) {
      setTableData(itemStockTransferHistoryData);
    } else if (apiType === "company" && Array.isArray(safeStockTransferHistoryData)) {
      setTableData(safeStockTransferHistoryData);
    } else {
      setTableData([]);
    }
    setLoading(false);
  }, [apiType, itemStockTransferHistoryData, stockTransferHistoryData]);

  useEffect(() => {
    if (prevPropsRef?.current?.itemStockTransferHistoryDataState?.loading && !itemStockTransferHistoryDataState?.loading) {
      if (itemStockTransferHistoryDataState?.error) {
        setTableData([]);
      }
      setLoading(false);
    }
    prevPropsRef.current = { itemStockTransferHistoryDataState };
  }, [itemStockTransferHistoryDataState]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = { onChange: onSelectChange, type: "checkbox" };

  const handleSearch = (searchString: string) => {
    setSearchText(searchString);
  };

  const highlightText = (text: string, searchText: string) => {
    if (!searchText) return text;
    const textToSearch = text?.toString() || "";
    const regex = new RegExp(`(${searchText})`, "gi");
    const parts = textToSearch?.split(regex);

    return parts?.map((part, index) =>
      regex?.test(part) ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (part)
    );
  };

  const updatedColumns = tableColumns?.map((col) => ({
    ...col,
    render: (text: any, record: any) => {
      const value = record[col.dataIndex];
      if (col.dataIndex === "fromStore") {
        const storeName = value?.name || "N/A";
        return (
          <>{record?.fromStore?.name != "Unknown Store" ? <div>
            <div className="title">{highlightText(storeName, searchText)}</div>
            <div>
              Prev Qty: <span style={{ color: "green" }}>{value?.previousQuantity}</span>
            </div>
            <div>
              Current Qty: <span style={{ color: "red" }}>{value?.currentQuantity}</span>
            </div>
          </div> : 'N/A'}</>
        );
      } else if (col.dataIndex === "toStore") {
        const storeName = value?.name || "N/A";
        return (
          <div>
            <div className="title">{highlightText(storeName, searchText)}</div>
            <div>
              Prev Qty: {value?.previousQuantity}
            </div>
            <div>
              Current Qty: {value?.currentQuantity}
            </div>
          </div>
        );
      } else if (col.dataIndex === "transferredBy") {
        return (
          <div>
            <Avatar
              style={{ backgroundColor: "#87d068" }}
              size="small"
              icon={<UserOutlined />}
            />
            <span className="title">{highlightText(value?.toString() || "", searchText)}</span>
          </div>
        );
      } else if (col.dataIndex === "itemName") {
        return (
          <div>
            <span className="title">
              {highlightText(`${value || ""} (${record.itemId || "N/A"})`, searchText)}
            </span>
            <div className="title">UOM: {metricsUnit || record.uom || "N/A"}  </div>
          </div>
        );
      } else if (col.dataIndex === "transferNumber") {
        return (
          <div>
            <span className="linkText">{highlightText(value?.toString() || "", searchText)}</span>
          </div>
        );
      }
      else if (col.dataIndex === "price") {
        return <span>{value} INR</span>
      }
      return <span style={{ color: value > 0 ? "#008000" : "#FF0000" }}>{highlightText(value?.toString() || "", searchText)}</span>;
    },
  }));

  const handleDownload = () => {
    const selectedData = itemStockTransferHistoryData
      .filter((item: any) => selectedRowKeys.includes(item.id))
      .map((item: any) => ({
        "Transfer number": item.transferNumber,
        "Item ID": item.itemId,
        "Item Name": item.itemName,
        "From Store": item.fromStore,
        "To Store": item.toStore,
        "Quantity": item.quantity,
        "Transferred By": item.transferredBy,
        "Price": item.price
      }));
    downloadExcel(selectedData, "Selected_Items");
  };

  const handleFilteredData = (filteredData: any[]) => {
    setFilteredDocs(filteredData);
  };

  const dataSource = filteredDocs?.length > 0 ? filteredDocs : tableData || [];
  const timelineItems = dataSource?.map((data: any, index: number) => ({
    key: `${data.id ?? index}`,
    children: (
      <>
        <div>
          {highlightText(
            `${data?.fromStore.name === 'Unknown Store' ? 'Updated' : ('Transferred from ' + data?.fromStore.name)} to ${data?.toStore.name} on ${new Date(data?.createdAt).toLocaleString()} by ${data?.transferredBy}`,
            searchText
          )}
        </div>
        <Table
          className="mt-10"
          bordered
          columns={updatedColumns}
          dataSource={[data]}
          pagination={false}
          rowKey={(record) => record.id?.toString() ?? index?.toString()}
          rowSelection={rowSelection}
        />
      </>
    ),
  }));

  const handleClose = () => {
    setTableData([]);
    setFilteredDocs([]);
    setSearchText("");
    onClose();
  };

  return (
    <>
      <Loader loading={loading} />
      <Drawer
        title={title}
        width={1200}
        onClose={handleClose}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
          </Space>
        }
      >
        {dataSource?.length > 0 && (
          <>
            <div className="flexBox mb-20" style={{ justifyContent: "flex-end" }}>
              <Button onClick={handleDownload} type="link">
                <DownloadOutlined /> Download
              </Button>
              <Popover
                overlayStyle={{ width: 300 }}
                placement="bottom"
                content={
                  <FilterMenu
                    filterType={"historyFilter"}
                    // filterData={itemStockTransferHistoryData}
                    filterData={[]}
                    onApplyFilter={(filteredData) => {
                      handleFilteredData(filteredData);
                      setShowFilterMenu(false);
                    }}
                  />
                }
                trigger="click"
                open={showFilterMenu}
                onOpenChange={() => setShowFilterMenu(!showFilterMenu)}
              >
                <Button type="link" icon={<FilterOutlined />}>
                  Filter
                </Button>

              </Popover>
              <Input
                style={{ width: 200 }}
                placeholder="Search Timeline"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </>
        )}
        {dataSource.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "10%" }}>
            <img src={noRecord} alt="historyData" style={{ width: 'auto', height: '300px' }} />
            <p>No history found for stock transfer !</p>
          </div>
        ) : (
          <Timeline items={timelineItems} />
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  itemStockTransferHistoryData: state.api.itemStockTransferHistory?.data?.stockTransfers,
  stockTransferHistoryData: state.api.stockTransferHistory?.data?.stockTransfers,
  itemStockTransferHistoryDataState: state.api.itemStockTransferHistory,
});

const mapDispatchToProps = (dispatch: any) => ({
  itemStockTransferHistory: (payload: any) => dispatch(itemStockTransferHistory(payload)),
  stockTransferHistory: (payload: any) => dispatch(stockTransferHistory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryData);
