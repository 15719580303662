import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Drawer, Result, Space, Progress, Typography, notification, Select, Form } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import MicrosoftLogo from "../../../Utility/images/microsoft-icon.png";
import Success from "../../../Utility/images/success.png";
import "./reconcileAndUpload.css";
import { connect, useDispatch } from "react-redux";
import { stockReconcile } from "../../../redux/actions/API/reconcileAndUploadAction";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { bulkEditItems, bulkReconcilationItems, bulkUploadAlternateUnit, bulkUploadItems, getItems } from "../../../redux/actions/API/items";
import { downloadBulkUploadErrorData, downloadExcel, downloadExcelTemplateForBulkupload, downloadExcelWithDropDown } from "../../../Utility/downloadExcel";
import Loader from "../../../Home/Loader/Loader";
import { getCategories } from "../../../redux/actions/API/categories";
const { Option } = Select;

const { Text } = Typography;
interface IReconcileAndUploadProps {
  title: string;
  onClose: () => void;
  open: boolean;
  uploadTemplateText: string;
  templatePath: string;
  resultTitle: string;
  resultSubTitle: string;
  stockReconcile: Function;
  stockReconcileState: IDataResponse;
  itemsData: any;
  getItems: any;
  companyId: string;
  bulkUploadItems: Function;
  bulkEditItems: Function;
  bulkReconcilationItems: Function;
  bulkUploadAlternateUnits: Function;
  bulkUploadSuccess: string;
  bulkEditSuccess: string;
  bulkUploadAlternateUnitSuccess: string;
  bulkUploadAlternateUnitLoading: boolean;
  bulkUploadAlternateUnitErrorData: any;
  error: string | null;
  storeData: any;
  loading: boolean;
  bulkLoading: boolean;
  bulkReconcilationSuccess: string;
  bulkReconcilationLoading: boolean;
  bulkUploadErrorData: any;
  bulkEditErrorData: any;
  bulkReconcilationErrorData: any;
  getCategories: Function;
  getCategoriesState: any;
  UOMData: any;
}

const ReconcileAndUpload: React.FC<IReconcileAndUploadProps> = ({
  title,
  onClose,
  open,
  uploadTemplateText,
  templatePath,
  resultTitle,
  resultSubTitle,
  stockReconcile,
  stockReconcileState,
  itemsData,
  getItems,
  companyId,
  bulkUploadItems,
  bulkEditItems,
  bulkReconcilationItems,
  bulkUploadSuccess,
  error,
  bulkEditSuccess,
  storeData,
  loading: bulkLoader,
  bulkLoading,
  bulkReconcilationLoading,
  bulkReconcilationSuccess,
  bulkUploadErrorData,
  bulkEditErrorData,
  bulkReconcilationErrorData,
  getCategories,
  getCategoriesState,
  UOMData,
  bulkUploadAlternateUnitLoading,
  bulkUploadAlternateUnitErrorData,
  bulkUploadAlternateUnitSuccess,
  bulkUploadAlternateUnits

}) => {
  const prevPropsRef = useRef<any>();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileError, setFileError] = useState<string | null>(null);
  const [file, setFile] = useState<RcFile | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState("");
  const [store, setStore] = useState("Select Store");
  const fileRef = useRef<HTMLInputElement | null>(null);

  const localDispatch = useDispatch();

  const MAX_FILE_SIZE_KB = 25 * 1024;
  const VALID_FILE_TYPES = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  type NotificationType = "success" | "info" | "warning" | "error";

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    if (
      prevPropsRef.current?.stockReconcileState?.loading &&
      !stockReconcileState?.loading
    ) {
      if (stockReconcileState?.error?.length > 0) {
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
      }
    }

    prevPropsRef.current = { stockReconcileState, stockReconcile };
  }, [stockReconcileState]);

  useEffect(() => {
    getCategories({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    getItems({ companyId: Number(companyId) });
    if (bulkUploadSuccess) {
      notification.open({ type: 'success', message: bulkUploadSuccess, duration: 3 });
      localDispatch({
        type: 'BULK_UPLOAD_ITEM_SUCCESS',
        payload: { responseData: { message: '' } },
      })
      if (fileRef.current) {
        fileRef.current.value = "";
      }
      onClose();
      resetState();
    }
    if (error) {
      notification.open({ type: 'error', message: error, duration: 3 });
    }
    if (bulkEditSuccess) {
      notification.open({ type: 'success', message: bulkEditSuccess, duration: 3 });
      localDispatch({
        type: 'BULK_EDIT_ITEM_SUCCESS',
        payload: { responseData: { message: '' } },
      })
      if (fileRef.current) {
        fileRef.current.value = "";
      }
      onClose();
      resetState();
    }
    if (bulkReconcilationSuccess) {
      notification.open({ type: 'success', message: bulkReconcilationSuccess, duration: 3 });
      localDispatch({
        type: 'BULK_RECONCILATION_ITEM_SUCCESS',
        payload: { responseData: { message: '' } },
      })
      if (fileRef.current) {
        fileRef.current.value = "";
      }
      onClose();
      resetState();
    }
    if (bulkUploadAlternateUnitSuccess) {
      notification.open({ type: 'success', message: bulkUploadAlternateUnitSuccess, duration: 3 });
      localDispatch({
        type: 'BULK_UPLOAD_ALTERNATE_UNIT_SUCCESS',
        payload: { responseData: { message: '' } },
      })
      if (fileRef.current) {
        fileRef.current.value = "";
      }
      onClose();
      resetState();
    }
  }, [bulkUploadSuccess, error, bulkEditSuccess, bulkLoading, bulkReconcilationLoading, bulkReconcilationSuccess, bulkUploadAlternateUnitLoading, bulkUploadAlternateUnitSuccess]);

  useEffect(() => {
    if (bulkUploadErrorData && bulkUploadErrorData.length) {
      const filterdData = bulkUploadErrorData.map((item: any) => {
        return {
          "* Item ID": item?.['* Item ID'] || '',
          "* Item Name": item?.['* Item Name'] || '',
          "* Item Type": item?.["* Item Type"] || '',
          "HSN": item?.HSN || '',
          "Price": item?.Price || '',
          "Tax Type": item?.["Tax Type"] || '',
          "Tax": item?.Tax || '',
          "Min Stock": item?.["Min Stock"] || '',
          "Max Stock": item?.["Max Stock"] || '',
          "Description": item?.Description || '',
          "Category": item?.Category || '',
          "Sub Category": item?.["Sub Category"] || '',
          "Micro Category": item?.["Micro Category"] || '',
          "Error": item?.Error || ''
        }
      });

      downloadBulkUploadErrorData(filterdData, "Bulk Upload Error Data.", [...getCategoriesState?.data?.data]);
    }
    if (bulkEditErrorData && bulkEditErrorData.length) {
      const filterdData = bulkEditErrorData.map((item: any) => {
        return {
          "Item ID": item?.['Item ID'],
          "Item Name": item?.['Item Name'],
          "Item type": item?.['Item Type'] == 1 ? 'Buy' : item?.['Item Type'] == 2 ? 'Sell' : 'Both',
          "HSN": item?.['HSN'] || '',
          "Price": item?.['Price'] || '',
          "Tax Type": item?.['Tax Type'] || '',
          "Tax": item?.Tax || '',
          "Min Stock": item?.["Min Stock"] || '',
          "Max Stock": item?.["Max Stock"] || '',
          "Description": item?.Description || '',
          "Category": item?.Category || '',
          "Sub Category": item?.["Sub Category"] || '',
          "Micro Category": item?.["Micro Category"] || '',
          "Error": item?.Error || ''
        }
      });
      downloadExcelWithDropDown(filterdData, "Bulk Edit Error Data.", [...getCategoriesState?.data?.data], true);
    }
    if (bulkReconcilationErrorData && bulkReconcilationErrorData.length) {
      const filteredData = bulkUploadErrorData?.map((item: any) => {
        return {
          'Item ID': item?.['Item ID'] || '',
          'Item Name': item?.['Item Name'] || '',
          'Current Stock': item?.['Current Stock'] || '',
          'Final Stock': item?.['Final Stock'] || '',
          'Price/Unit': item?.['Price/Unit'] || '',
          comment: item?.comment || '',
          Error: item?.Error || ''
        }
      })
      downloadExcel(filteredData, "Bulk Reconcilation Error Data.");
    }
    if (bulkUploadAlternateUnitErrorData && bulkUploadAlternateUnitErrorData.length) {
      downloadExcel(UOMData, "Bulk Upload Alternate Unit Error Data.", bulkUploadAlternateUnitErrorData);
    }

  }, [bulkUploadErrorData, bulkEditErrorData, bulkReconcilationErrorData, bulkUploadAlternateUnitErrorData]);

  const handleDownload = () => {
    try {
      if (title === 'Bulk Edit Item') {
        const filterdData = itemsData.map((item: any) => {
          return {
            "Item ID": item?.itemId,
            "Item Name": item?.itemName,
            "Item type": item?.itemType == 1 ? 'Buy' : item.itemType == 2 ? 'Sell' : 'Both',
            "HSN": item?.HSNCode,
            "Price": item?.price,
            "Tax Type": !item?.taxType ? '' : item?.taxType == 1 ? 'Inclusive' : 'Exclusive',
            "Tax": item?.tax || '',
            "Min Stock": item?.minStock,
            "Max Stock": item?.maxStock,
            "Description": item?.description,
            "Category": item?.category || '',
            "Sub Category": item?.subCategory || '',
            "Micro Category": item?.microCategory || ''
          }
        });
        downloadExcelWithDropDown(filterdData, "Edit_Items", [...getCategoriesState?.data?.data], null);
      } else if (title === 'Physical Stock Reconciliation') {
        let storeName = '';
        for (const stores of storeData) {
          if (stores.id === store) {
            storeName = stores.name;
            break;
          }
        }
        const filterdData = itemsData.map((item: any) => {
          let currentStock = 0;

          for (const stores of item.stores) {
            if (stores.storeId === store) {
              currentStock = stores.quantity;
              break;
            }
          }
          return {
            "Item ID": item?.itemId,
            "Item Name": item?.itemName,
            "Current Stock": currentStock,
            "Final Stock": "",
            "Price/Unit": item?.price,
            "comment": "",
            "store": storeName
          }
        });
        downloadExcel(filterdData, "Reconcile_Items");
      } else if (title === 'Bulk Upload Alternate Unit') {
        downloadExcel(UOMData, "BulkUploadAlternateUnit");
      } else {
        downloadExcelTemplateForBulkupload('BulkUploadItemTemplate', [...getCategoriesState?.data?.data], UOMData);
      }
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const validateFile = (file: RcFile): boolean => {
    const isValidType = VALID_FILE_TYPES.includes(file.type);
    const isValidSize = file.size / 1024 < MAX_FILE_SIZE_KB;

    if (!isValidType) {
      setFileError(
        "Invalid file format. Please upload a valid XLS, XLSX, or CSV file."
      );
      return false;
    }

    if (!isValidSize) {
      setFileError(
        `File size exceeds the limit of ${25} MB.`
      );
      return false;
    }
    setFileError(null);
    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] as RcFile;
    if (selectedFile) {
      const isValid = validateFile(selectedFile);
      if (isValid) {
        setFile(selectedFile);
      }
    }
  };

  const handleUpload = () => {
    if (!file) {
      setFileError("No file selected.");
      return;
    }
    if (!validateFile(file)) return;
    if (title === 'Bulk Upload') {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyId', companyId);
      bulkUploadItems(formData);
    }
    if (title === 'Bulk Edit Item') {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyId', companyId);
      bulkEditItems(formData);
    }
    if (title === 'Physical Stock Reconciliation') {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyId', companyId);
      formData.append('storeId', store);
      formData.append('addedBy', companyId);
      bulkReconcilationItems(formData);
    }
    if (title === 'Bulk Upload Alternate Unit') {
      const formData = new FormData();
      formData.append('file', file);
      bulkUploadAlternateUnits(formData);
    }
  };

  const resetState = () => {
    setFile(null);
    setFileError(null);
    setUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
  };

  return (
    <>
      <Loader loading={bulkLoader || bulkLoading || bulkReconcilationLoading || bulkUploadAlternateUnitLoading}></Loader>
      <Drawer
        title={title}
        width={720}
        onClose={() => {
          if (fileRef.current) {
            fileRef.current.value = "";
          }
          resetState();
          onClose();
        }}
        open={open}
        maskClosable={false}
        extra={
          <Space>
            <Button
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.value = "";
                }
                resetState();
                onClose();
              }}
            >
              Cancel
            </Button>
            {!uploadSuccess && (
              <Button disabled={templatePath === "Reconcilation-excel-template" && date === ''} onClick={handleUpload} type="primary">
                Upload
              </Button>
            )}
          </Space>
        }
      >
        {!uploadSuccess ? (
          <>
            <Alert
              message={(() => {
                switch (title) {
                  case "Bulk Upload":
                    return "You can create a bulk upload here, you simply need to download the excel template from the button below, fill it according to your need and submit it and your items will be created in bulk.";
                  case "Physical Stock Reconciliation":
                    return "Download the reconciliation template, update it, and upload to reconcile stocks.";
                  case "Bulk Edit Item":
                    return "You can perform a bulk edit here. Edit multiple items at once and update them as needed.";
                  default:
                    return "Please follow the instructions for your selected operation.";
                }
              })()}
              type="info"
              showIcon
              style={{ marginBottom: 16 }}
            />
            {templatePath === "Reconcilation-excel-template" && <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ margin: 0, padding: 0, color: '#969292', fontSize: '18px' }}>Select Store</p>
              <div>
                <Select
                  placeholder="Select/Search item"
                  value={store}
                  onChange={(e:any) => {
                    setStore(e);
                  }}
                  optionLabelProp="label"
                  showSearch
                  filterOption={false}
                  style={{ flex: 1, width: '100%', height: '45px', backgroundColor: '#f0f0f0' }}
                >
                  <Option disabled value="Select Store">Select Store</Option>
                  {
                    storeData?.map((store: any) => {
                      return <Option key={store?.id} value={store.id} label={store.name}>
                        {store.name}
                      </Option>
                    })
                  }
                </Select>
              </div>

            </div>}
            <div className="link-container mt-20">
              <div className="sub-link-container">
                <Space>
                  <img
                    src={MicrosoftLogo}
                    className="logo-excel"
                    alt="Microsoft Logo"
                  />
                  <Text>{uploadTemplateText}</Text>
                </Space>
                <Button disabled={title === 'Physical Stock Reconciliation' && store === 'Select Store'} className="download-button" onClick={handleDownload}>
                  Download
                </Button>
              </div>
            </div>
            <div className="bulk-drawer-container">
              <div className="upload-input-container">
                <label htmlFor="file-upload" className="custom-file-upload">
                  <UploadOutlined className="file-add-icon" /> Drag and Drop
                  file here or Choose file
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  onChange={handleFileChange}
                  ref={fileRef}
                />
              </div>
              <div className="text-container">
                <p className="ant-upload-hint">
                  Supported formats: XLS, XLSX, CSV
                </p>
                <p>Maximum size: 25MB</p>
              </div>
              {templatePath === "Reconcilation-excel-template" && <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ margin: 0, padding: 0, color: '#969292', fontSize: '18px' }}>Select Date</p>
                <input
                  style={{ borderRadius: '10px', border: '1px solid #d9d9d9', padding: '10px', backgroundColor: '#f0f0f0' }}
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>}
              {file && (
                <div className="file-info flexBox">
                  <Text>{file.name}</Text>
                  <DeleteOutlined
                    onClick={() => setFile(null)}
                    style={{ marginLeft: 8, cursor: "pointer", color: "red" }}
                  />
                </div>
              )}
              {fileError && (
                <Alert
                  message={fileError}
                  type="error"
                  showIcon
                  style={{ marginTop: 16 }}
                />
              )}
              {uploadProgress > 0 && (
                <Progress
                  percent={uploadProgress}
                  status={uploadProgress === 100 ? "success" : "active"}
                  style={{ marginTop: 16 }}
                />
              )}
            </div>
          </>
        ) : (
          <Result
            icon={<img src={Success} alt="Success" />}
            title={resultTitle}
            subTitle={resultSubTitle}
          />
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  userId: state.api.login.data?.id,
  stockReconcileState: state.api.stockReconcile,
  itemsData: state.api.getItems?.data,
  bulkUploadSuccess: state.api.bulkUploadItems?.bulkUploadSuccess,
  error: state.api.bulkUploadItems.error,
  bulkEditSuccess: state.api.bulkEditItems.bulkEditSuccess,
  bulkUploadAlternateUnitSuccess: state.api.bulkUploadAlternateUnits?.bulkUploadAlternateUnitSuccess,
  storeData: state.api.getStore?.data,
  loading: state.api.bulkUploadItems?.loading,
  bulkLoading: state.api.bulkEditItems?.bulkLoading,
  bulkReconcilationSuccess: state.api.bulkReconcilationItems?.bulkReconcilationSuccess,
  bulkReconcilationLoading: state.api.bulkReconcilationItems?.bulkReconcilationLoading,
  bulkUploadAlternateUnitLoading: state.api.bulkUploadAlternateUnits?.bulkUploadAlternateUnitLoading,
  bulkUploadErrorData: state.api.bulkUploadItems?.bulkUploadErrorData,
  bulkEditErrorData: state.api.bulkEditItems?.bulkEditErrorData,
  bulkUploadAlternateUnitErrorData: state.api.bulkUploadAlternateUnits?.bulkUploadAlternateUnitErrorData,
  bulkReconcilationErrorData: state.api.bulkReconcilationItems?.bulkReconcilationErrorData,
  getCategoriesState: state.api?.getCategory,
  UOMData: state.api.getUOM?.data
});

const mapDispatchToProps = (dispatch: any) => ({
  stockReconcile: (payload: any) => dispatch(stockReconcile(payload)),
  getItems: (payload: any) => dispatch(getItems(payload)),
  bulkUploadItems: (payload: any) => dispatch(bulkUploadItems(payload)),
  bulkEditItems: (payload: any) => dispatch(bulkEditItems(payload)),
  bulkReconcilationItems: (payload: any) => dispatch(bulkReconcilationItems(payload)),
  getCategories: (payload: any) => dispatch(getCategories(payload)),
  bulkUploadAlternateUnits: (payload: any) => dispatch(bulkUploadAlternateUnit(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconcileAndUpload); 
