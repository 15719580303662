import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import history from "../History/History";
import HomePage from "./HomePage";
import PublicRoute from "./Route/PublicRoute";
import Signin from "./Signin";
import PrivateRoute from "./Route/PrivateRoute";
import Main from "./Main";
import TermCondition from "./TermCondition";
import Privacy from "./Privacy";
import BlogDetails from "./BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Blogs from "./Blogs";
import PaymentInvoice from "./PaymentInvoice";
import AboutUs from "./AboutUs";
import InventoryLanding from "./InventoryFeature";
import PurchaseFeature from "./PurchaseFeature";
import SalesFeature from "./SalesFeature";
import ContactUs from "./contactUS/ContactUs";
import RefundAndCancellation from "./RefundAndCancellation";
import RequestDemo from "./demoQuery/DemoQuery";

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<PublicRoute><HomePage /></PublicRoute>} />
                    <Route path="/sign-in" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="/sign-up" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="/forgot-password" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="/reset-password" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="/login" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/buyer_supplier" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/items" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/stores" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/settings" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/user_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/team_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/profile_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/terms-and-condition" element={<TermCondition />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/our-blogs" element={<PublicRoute><Blogs /></PublicRoute>} />
                    <Route path="/manage_blogs" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/permissions" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/blog_details/:id/:title" element={<PublicRoute><BlogDetails /></PublicRoute>} />
                    <Route path="/sales_documents" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/purchase_documents" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/docs_approval" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/bill_of_material" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/production" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/new_work_order" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/create_bill_of_material" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/integrations" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/sales_transaction" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/purchase_transaction" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/reports" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/create_document" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/update_document" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/previewDocument" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/comments" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/query" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/demoRequest" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/newsLetters" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/timeline" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/purchase_timeline" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/payment-invoice" element={<PublicRoute><PaymentInvoice /></PublicRoute>} />
                    <Route path="/about_us" element={<PublicRoute><AboutUs /></PublicRoute>} />
                    <Route path="/services/inventory" element={<PublicRoute><InventoryLanding /></PublicRoute>} />
                    <Route path="/services/sales" element={<PublicRoute><SalesFeature /></PublicRoute>} />
                    <Route path="/services/purchase" element={<PublicRoute><PurchaseFeature /></PublicRoute>} />
                    <Route path="/contactus" element={<PublicRoute><ContactUs /></PublicRoute>} />
                    <Route path="/requestdemo" element={<PublicRoute><RequestDemo /></PublicRoute>} />
                    <Route path="/refund-cancellation" element={<PublicRoute><RefundAndCancellation /></PublicRoute>} />
                </Routes>
            </ScrollToTop>
        </Router>
    )
}

export default AppRoutes