export const GET_ITEMS = 'GET_ITEMS';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILED = 'GET_ITEMS_FAILED';

export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILED = 'ADD_ITEM_FAILED';

export const EDIT_ITEM = 'EDIT_ITEM';
export const EDIT_ITEM_SUCCESS = 'EDIT_ITEM_SUCCESS';
export const EDIT_ITEM_FAILED = 'EDIT_ITEM_FAILED';

export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILED = 'DELETE_ITEM_FAILED';

export const BULK_DELETE_ITEM = 'BULK_DELETE_ITEM';
export const BULK_DELETE_ITEM_SUCCESS = 'BULK_DELETE_ITEM_SUCCESS';
export const BULK_DELETE_ITEM_FAILED = 'BULK_DELETE_ITEM_FAILED';

export const BULK_UPLOAD_ITEM = 'BULK_UPLOAD_ITEM';
export const BULK_UPLOAD_ITEM_SUCCESS = 'BULK_UPLOAD_ITEM_SUCCESS';
export const BULK_UPLOAD_ITEM_FAILED = 'BULK_UPLOAD_ITEM_FAILED';

export const BULK_EDIT_ITEM = 'BULK_EDIT_ITEM';
export const BULK_EDIT_ITEM_SUCCESS = 'BULK_EDIT_ITEM_SUCCESS';
export const BULK_EDIT_ITEM_FAILED = 'BULK_EDIT_ITEM_FAILED';

export const BULK_RECONCILATION_ITEM = 'BULK_RECONCILATION_ITEM';
export const BULK_RECONCILATION_ITEM_SUCCESS = 'BULK_RECONCILATION_ITEM_SUCCESS';
export const BULK_RECONCILATION_ITEM_FAILED = 'BULK_RECONCILATION_ITEM_FAILED';

export const BULK_UPLOAD_ALTERNATE_UNIT = 'BULK_UPLOAD_ALTERNATE_UNIT';
export const BULK_UPLOAD_ALTERNATE_UNIT_SUCCESS = 'BULK_UPLOAD_ALTERNATE_UNIT_SUCCESS';
export const BULK_UPLOAD_ALTERNATE_UNIT_FAILED = 'BULK_UPLOAD_ALTERNATE_UNIT_FAILED';