import React, { useState,useEffect,useRef } from "react";
import { Button, Drawer, Form, Input, Table, Space, Tooltip, notification, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import noRecord from "../../../Utility/images/norecord.png";
import { connect } from "react-redux";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";
import { addTransporterDetails, deleteTransporterDetails, editTransporterDetails, getTransporterDetails } from "../../../redux/actions/API/transporterDetails";
import Loader from "../../../Home/Loader/Loader";

interface transporterProps {
  userId: number;
  companyId: number;
  transporterDetailsData: any;
  addTransporterDetails: Function;
  editTransporterDetails: Function;
  getTransporterDetails: Function;
  deleteTransporterDetails: Function;
  addTransporterDetailsState: IDataResponse;
  getTransporterDetailsState: IDataResponse;
  editTransporterDetailsState: IDataResponse;
  deleteTransporterDetailsState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const TransporterDetails: React.FC<transporterProps> = ({
  userId,
  companyId,
  transporterDetailsData,
  addTransporterDetails,
  editTransporterDetails,
  getTransporterDetails,
  deleteTransporterDetails,
  addTransporterDetailsState,
  getTransporterDetailsState,
  editTransporterDetailsState,
  deleteTransporterDetailsState,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const [currentDetails, setCurrentDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [ipAddress, setIpAddress] = useState("127.0.0.1");
  const prevPropsRef = useRef<any>();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    getTransporterDetails({ companyId: Number(companyId) });
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        console.error("Failed to fetch IP address:", error);
      });
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getTransporterDetailsState?.loading &&
      !getTransporterDetailsState?.loading
    ) {
      if (getTransporterDetailsState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.addTransporterDetailsState?.loading &&
      !addTransporterDetailsState?.loading
    ) {
      if (addTransporterDetailsState?.error) {
        openNotificationWithIcon(
          "error",
          addTransporterDetailsState.error.message
        );
        setLoading(false);
      } else {
        getTransporterDetails({ companyId: Number(companyId) });
        closeDrawer();
        openNotificationWithIcon(
          "success",
          addTransporterDetailsState?.data?.message
        );
      }
    }

    if (
      prevPropsRef?.current?.editTransporterDetailsState?.loading &&
      !editTransporterDetailsState?.loading
    ) {
      if (editTransporterDetailsState?.error) {
        openNotificationWithIcon(
          "error",
          editTransporterDetailsState?.error?.message
        );
        setLoading(false);
      } else {
        getTransporterDetails({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          editTransporterDetailsState?.data?.message
        );
        closeDrawer();
      }
    }

    if (
      prevPropsRef?.current?.deleteTransporterDetailsState?.loading &&
      !deleteTransporterDetailsState?.loading
    ) {
      if (deleteTransporterDetailsState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false)
      } else {
        getTransporterDetails({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          deleteTransporterDetailsState?.data?.message
        );
      }
    }
    prevPropsRef.current = {
      userId,
      companyId,
      getTransporterDetails,
      addTransporterDetails,
      editTransporterDetails,
      deleteTransporterDetails,
      getTransporterDetailsState,
      addTransporterDetailsState,
      editTransporterDetailsState,
      deleteTransporterDetailsState,
    };
  }, [
    getTransporterDetailsState,
    addTransporterDetailsState,
    deleteTransporterDetailsState,
    editTransporterDetailsState,
  ]);
  
  const openDrawer = (transData?: any) => {
    console.log(transData)
    setCurrentDetails(transData || null);
    form.setFieldsValue(transData || null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    form.resetFields();
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values)
        const payload = {
          userId: userId,
          companyId: Number(companyId),
          GSTNumber: values.GSTNumber,
          name: values.name, 
          ip_address: ipAddress,
          status: 1,
        };
        setLoading(true);
        if (currentDetails) {
          const editPayload = { ...payload, transporterId: currentDetails.id };
          editTransporterDetails(editPayload);
        } else {
          addTransporterDetails(payload);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteTransporterDetails({ transporterId: Number(id) });
  };

  const columns = [
    {
      title: "GST Number",
      dataIndex: "GSTNumber",
      key: "GSTNumber",
    },
    {
      title: "Transporter Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => openDrawer(record)} />
          </Tooltip>
          <Popconfirm
            title="Are you sure you want to delete this details?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button className="actionIcons" icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Loader loading={loading} />
      <div className="flexBox mb-10">
      <h5 style={{ color: "#1890ff" }}>Manage transporter details</h5>
        <div></div>
        <Button type="link" onClick={() => openDrawer()}>
          <PlusOutlined />
          Add Transporter
        </Button>
      </div>

      <div>
        {transporterDetailsData?.length > 0 ? (
          <Table
            bordered
            dataSource={transporterDetailsData}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        ) : (
          <div className="noData">
            <img
              src={noRecord}
              alt="No Records Found"
              className="no-data-dimensions"
            />
          </div>
        )}
      </div>

      <Drawer
        title={currentDetails ? "Edit Transporter" : "Add Transporter"}
        width={600}
        onClose={closeDrawer}
        open={isDrawerOpen}
        style={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave}>
              {currentDetails ? "Update" : "Submit"}
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            name="GSTNumber"
            label="GST Number"
            rules={[
              { required: true, message: "Please enter GST Number!" },
              {
                pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                message: "Please enter a valid GST Number!",
              },
            ]}
          >
            <Input placeholder="Enter GST Number" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Transporter Name"
            rules={[
              { required: true, message: "Please enter Transporter Name!" },
            ]}
          >
            <Input placeholder="Enter Transporter Name" />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  transporterDetailsData: state.api.getTransporterDetails?.data,
  getTransporterDetailsState: state.api.getTransporterDetails,
  addTransporterDetailsState: state.api.addTransporterDetails,
  editTransporterDetailsState: state.api.editTransporterDetails,
  deleteTransporterDetailsState: state.api.deleteTransporterDetails, 
});

const mapDispatchToProps = (dispatch: any) => ({
  addTransporterDetails: (payload: any) => dispatch(addTransporterDetails(payload)),
  editTransporterDetails: (payload: any) => dispatch(editTransporterDetails(payload)),
  getTransporterDetails: (payload: any) => dispatch(getTransporterDetails(payload)),
  deleteTransporterDetails: (payload: any) => dispatch(deleteTransporterDetails(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TransporterDetails);