import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Button,
  Drawer,
  Table,
  Space,
  Popconfirm,
  notification,
  Form,
  Tooltip,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import Loader from "../../../Home/Loader/Loader";
import noRecord from "../../../Utility/images/norecord.png";
import { connect } from "react-redux";
import {
  addPaymentTerm,
  deletePaymentTerm,
  editPaymentTerm,
  getPaymentTerm,
} from "../../../redux/actions/API/paymentTerm";
import { IDataResponse } from "../../../redux/types/API/ApiResponse";

interface IPaymentTermProps {
  userId: number;
  companyId: number;
  paymentTermData: any;
  getPaymentTerm: Function;
  addPaymentTerm: Function;
  editPaymentTerm: Function;
  deletePaymentTerm: Function;
  getPaymentTermState: IDataResponse;
  addPaymentTermState: IDataResponse;
  editPaymentTermState: IDataResponse;
  deletePaymentTermState: IDataResponse;
}

type NotificationType = "success" | "info" | "warning" | "error";

const PaymentTerm: React.FC<IPaymentTermProps> = ({
  userId,
  companyId,
  paymentTermData,
  getPaymentTerm,
  addPaymentTerm,
  editPaymentTerm,
  deletePaymentTerm,
  getPaymentTermState,
  addPaymentTermState,
  editPaymentTermState,
  deletePaymentTermState,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [ipAddress, setIpAddress] = useState("127.0.0.1");
  const prevPropsRef = useRef<any>();

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };

  useEffect(() => {
    getPaymentTerm({ companyId: Number(companyId) });
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        console.error("Failed to fetch IP address:", error);
      });
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getPaymentTermState?.loading &&
      !getPaymentTermState?.loading
    ) {
      if (getPaymentTermState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false)
      } else {
        setLoading(false);
      }
    }

    if (
      prevPropsRef?.current?.addPaymentTermState?.loading &&
      !addPaymentTermState?.loading
    ) {
      if (addPaymentTermState?.error) {
        openNotificationWithIcon("error", addPaymentTermState.error.message);
        setLoading(false);
      } else {
        getPaymentTerm({ companyId: Number(companyId) });
        closeDrawer();
        openNotificationWithIcon("success", addPaymentTermState?.data?.message);
      }
    }

    if (
      prevPropsRef?.current?.editPaymentTermState?.loading &&
      !editPaymentTermState?.loading
    ) {
      if (editPaymentTermState?.error) {
        openNotificationWithIcon("error", editPaymentTermState?.error?.message);
        setLoading(false);
      } else {
        getPaymentTerm({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          editPaymentTermState?.data?.message
        );
        closeDrawer();
      }
    }

    if (
      prevPropsRef?.current?.deletePaymentTermState?.loading &&
      !deletePaymentTermState?.loading
    ) {
      if (deletePaymentTermState?.error) {
        openNotificationWithIcon("error", "Something went wrong!");
        setLoading(false)
      } else {
        getPaymentTerm({ companyId: Number(companyId) });
        openNotificationWithIcon(
          "success",
          deletePaymentTermState?.data?.message
        );
      }
    }
    prevPropsRef.current = {
      userId,
      companyId,
      getPaymentTerm,
      addPaymentTerm,
      editPaymentTerm,
      deletePaymentTerm,
      getPaymentTermState,
      addPaymentTermState,
      editPaymentTermState,
      deletePaymentTermState,
    };
  }, [
    getPaymentTermState,
    addPaymentTermState,
    deletePaymentTermState,
    editPaymentTermState,
  ]);

  const openDrawer = (term?: any) => {
    setCurrentTerm(term || null);
    form.setFieldsValue(term || null);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    form.resetFields();
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const payload = {
          userId: userId,
          companyId: Number(companyId),
          name: values.name,
          days: parseInt(values.days, 10),
          description: values.description,
          ip_address: ipAddress,
          status: 1,
        };
        setLoading(true);
        if (currentTerm) {
          const editPayload = { ...payload, paymentTermId: currentTerm.id };
          editPaymentTerm(editPayload);
        } else {
          addPaymentTerm(payload);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deletePaymentTerm({ paymentTermId: Number(id) });
  };

  const columns = [
    {
      title: "Term name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => openDrawer(record)} />
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this payment term?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button className="actionIcons" icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Loader loading={loading} />
      <div className="flexBox mb-10">
      <h5 style={{ color: "#1890ff" }}>Manage payment terms</h5>
        <div></div>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => openDrawer()}
        >
          Add Payment Term
        </Button>
      </div>
      <div>
        {paymentTermData?.length > 0 ? (
          <Table
            bordered
            dataSource={paymentTermData}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        ) : (
          <div className="noData">
            <img
              src={noRecord}
              alt="No Records Found"
              style={{
                width: "auto",
                height: "300px",
              }}
            />
          </div>
        )}
      </div>
      <Drawer
        title={currentTerm ? "Edit Payment Term" : "Add Payment Term"}
        open={isDrawerOpen}
        onClose={closeDrawer}
        width={600}
        extra={
          <Space>
            <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical" onFinish={handleSave}>
          <Form.Item
            label="Term name"
            name="name"
            rules={[
              { required: true, message: "Term name is required" },
              {
                pattern: /^(?=.*\d).{3,}$/,
                message:
                  "Term name must be at least 3 characters and include at least one number",
              },
            ]}
          >
            <Input placeholder="Enter term name" />
          </Form.Item>
          <Form.Item
            label="Days"
            name="days"
            rules={[
              { required: true, message: "Days are required" },
              {
                pattern: /^[1-9]\d*$/,
                message: "Days must be a positive number greater than 0",
              },
            ]}
          >
            <Input min={0} type="number" placeholder="Enter Days" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                max: 200,
                message: "Description cannot exceed 200 characters",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Enter Description (Optional)"
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.api.login.data?.id,
  companyId: state.api.login.data.companyId,
  paymentTermData: state.api.getPaymentTerm?.data,
  getPaymentTermState: state.api.getPaymentTerm,
  addPaymentTermState: state.api.addPaymentTerm,
  editPaymentTermState: state.api.editPaymentTerm,
  deletePaymentTermState: state.api.deletePaymentTerm,
});

const mapDispatchToProps = (dispatch: any) => ({
  getPaymentTerm: (payload: any) => dispatch(getPaymentTerm(payload)),
  addPaymentTerm: (payload: any) => dispatch(addPaymentTerm(payload)),
  editPaymentTerm: (payload: any) => dispatch(editPaymentTerm(payload)),
  deletePaymentTerm: (payload: any) => dispatch(deletePaymentTerm(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerm);
