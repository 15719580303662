import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { blogsSearch, getBlogCategories, getBlogs } from "../../redux/actions/API/blogs";
import { connect } from "react-redux";
import "./Blogs.css";
import Loader from "../Loader/Loader";
import { Col, Input, notification, Row, Select, Skeleton, Space } from "antd";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import noRecord from "../../Utility/images/norecord.png";
import { SearchProps } from "antd/es/input";
interface IBlogs {
  blogsData: any[];
  blogCategoriesData: any;
  filteredData: any[];
  getBlogs: Function;
  getBlogsState: IDataResponse;
  blogsSearch: Function;
  blogSearchState: IDataResponse;
  getBlogCategories: Function;
  getBlogCategoriesState: IDataResponse;
}
type NotificationType = "success" | "info" | "warning" | "error";

const { Search } = Input;

const openNotificationWithIcon = (
  type: NotificationType,
  message: string
) => {
  notification.open({
    message: message,
    type: type,
    duration: 3,
  });
};

const Blogs: React.FC<IBlogs> = ({ getBlogs, blogsData, filteredData, getBlogsState, getBlogCategories, getBlogCategoriesState, blogCategoriesData, blogsSearch, blogSearchState }) => {
  const prevPropsRef = useRef<any>();
  const [loading, setLoading] = useState(true); 
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>(blogsData);

  useEffect(() => {
    getBlogs();
    getBlogCategories();
    setFilteredBlogs(blogsData); 
  }, []);

  useEffect(() => {
    if (
      prevPropsRef?.current?.getBlogsState?.loading &&
      !getBlogsState?.loading
    ) {
      if (getBlogsState?.error) {
        setLoading(false);
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setFilteredBlogs(blogsData);
        setLoading(false);
      }
    }
    if (
      prevPropsRef?.current?.getBlogCategoriesState?.loading &&
      !getBlogCategoriesState?.loading
    ) {
      if (getBlogCategoriesState?.error) {
        setLoading(false);
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
        setFilteredBlogs(blogsData);
      }
    }

    if (
      prevPropsRef?.current?.blogSearchState?.loading &&
      !blogSearchState?.loading
    ) {
      if (blogSearchState?.error) {
        setLoading(false);
        setFilteredBlogs(blogsData);
        openNotificationWithIcon("error", "Something went wrong!");
      } else {
        setLoading(false);
        setFilteredBlogs(filteredData);
      }
    }
    prevPropsRef.current = {
      getBlogsState,
      getBlogCategoriesState,
      blogSearchState,
    };
  }, [getBlogsState, getBlogCategoriesState, blogSearchState]);

  const truncateText = (text: any, maxLength = 150) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const renderBlogs = (blogs: any) => {
    return blogs.map((blogData: any, index: number) => {
      const updatedAtDate = new Date(blogData.updatedAt).toLocaleDateString();
      return (
        <div id="our-blogs" key={index} className="col-md-6 col-lg-4">
          <div className="blog-post mb-40 wow fadeInUp clearfix">
            <div className="blog-post-img mb-35">
              <img
                className="img-fluid r-16 mainBlogImage"
                src={blogData.imageURL}
                alt="blog-post-image"
              />
            </div>
            <div className="blog-post-txt">
              <span className={`post-tag`}>{blogData.header}</span>
              <h6 className="s-20 w-700">
                <Link to={`/blog_details/${blogData?.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                  {blogData.title}
                </Link>
              </h6>
              <div className="blog-post-meta mt-20">
                <ul className="post-meta-list ico-10">
                  <li>
                    <p className="p-sm w-500">Author : {blogData.author}</p>
                  </li>
                  <li className="meta-list-divider">
                    <p>
                      <span className="flaticon-minus"></span>
                    </p>
                  </li>
                  <li>
                    <p className="p-sm">{updatedAtDate}</p>
                  </li>
                </ul>
              </div>
              <p>{truncateText(blogData.shortDesc)}</p>
              <Link to={`/blog_details/${blogData.id}/${encodeURIComponent(blogData.title).replace(/%20/g, '-').replace(/%7D/g, '')}`}>
                <div className="readMore">Read more...</div>
              </Link>
            </div>
          </div>
        </div>
      );
    });
  };

  const onSearch: SearchProps["onSearch"] = (value) => {
    setLoading(true);
    setSelectedCategory(null); 
    setFilteredBlogs(blogsData);
    if (!value) { 
      getBlogs();  
    } else {
      blogsSearch({ query: value, blogCategory: null });
    }
  };
  
  const onCategoryChange = (value: string) => {
    setLoading(true);
    setSelectedCategory(value);
    if (!value) {
      getBlogs(); 
      setFilteredBlogs(blogsData);
    } else {
      blogsSearch({ query: "", blogCategory: value });
    }
  };

  return (
    <>
      <Header />
      <div>
        {/* <Loader loading={loading} /> */}
        <section id="blog" className="container">
          <div className="flexBox mb-20">
            <div></div>
            <div className="flexBox">
              <Search
                placeholder="Search blogs..."
                onSearch={onSearch}
                enterButton
                allowClear
              />
              <Select
                placeholder="Select Category"
                className="categorySelection"
                value={selectedCategory}
                onChange={onCategoryChange}  
                allowClear 
              >
                {blogCategoriesData?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.category}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="posts-wrapper">
            <div className="row">
              {loading ? (
                <Row gutter={[32, 32]}>
                  <Col xs={24} sm={12} md={8}>
                    <Skeleton active={loading} />
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Skeleton active={loading} />
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Skeleton active={loading} />
                  </Col>
                </Row>
              ) : filteredBlogs?.length > 0 ? (
                renderBlogs(filteredBlogs)
              ) : (
                <div className="noData">
                  <img
                    src={noRecord}
                    alt="No Records Found"
                    style={{ width: "auto", height: "300px" }}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  blogsData: state.api.getBlogs?.data,
  filteredData: state.api?.blogSearch?.data,
  getBlogsState: state.api.getBlogs,
  blogCategoriesData: state.api.getBlogCategories?.data,
  getBlogCategoriesState: state.api.getBlogCategories,
  blogSearchState: state.api.blogSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBlogs: (payload: any) => dispatch(getBlogs(payload)),
  getBlogCategories: (payload: any) => dispatch(getBlogCategories(payload)),
  blogsSearch: (payload: any) => dispatch(blogsSearch(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
