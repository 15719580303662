import { Layout, Breadcrumb, Card, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./createDocument.css";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import EntitySelectionForm from "./EntitySelectionForm";
import { connect } from "react-redux";
import TermsAndCondition from "./documentTermsAndCondition/DocumentTermsAndCondition";
import { AppConstants } from "../../../Appconstants";
import DocumentItemGrid from "./documentItemGrid/DocumentItemGrid";
import AdditionalCharge from "./documentAdditionalCharge/AdditionalCharge";
import { resetCreateDocumentData, setDocumentBuyerData, setDocumentSupplierData } from "../../../redux/actions/UI/createDocument";
import { EditOutlined } from "@ant-design/icons";
import SupplierAddressModal from "./SupplierAddressModal";
import DocumentMetaDataForm from "./DocumentMetaDataForm";
import "./documentMetaDataForm.css";
import TotalCalculation from "./documentTotalCalculation/TotalCalculation";
import LogisticDetails from "./documentLogisticDetails/DocumentsLogisticDetails";
import AdditionalDetails from "./documentAdditionalDetails/AdditionalDetails";
import Attachments from "./documentAttachmentDetails/Attachments";
import Signature from "./documentSignatureDetails/Signature";
import BankDetails from "../bankAccountDetails/BankDetails";
import CommentInput from "./CommentInput";
import { jwtDecode } from "jwt-decode";
import { getAddress } from "../../../redux/actions/API/addressActions";
import Loader from "../../../Home/Loader/Loader";

interface ICreateDocumentProps {
  individualsData: any;
  setDocumentBuyerData: Function;
  setDocumentSupplierData: Function;
  resetCreateDocumentData: Function;
  buyerDetails: any;
  supplierDetails: any;
  documentType: string;
  supplierComapnyData: any;
  supplierAddress: any;
  documentTo: string;
  getAddress: Function,
  companyId: number,
}

const CreateDocument: React.FC<ICreateDocumentProps> = ({
  resetCreateDocumentData,
  setDocumentBuyerData,
  setDocumentSupplierData,
  individualsData,
  buyerDetails,
  supplierDetails,
  documentType,
  supplierComapnyData,
  supplierAddress,
  documentTo,
  getAddress,
  companyId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [changeAddressModalTitle, setChangeAddressModalTitle] = useState<string>("");
  const [documentValidationData, setDocumentValidationData] = useState(null);
  const [documentItemValidationData, setDocumentItemValidationData] = useState(null);
  const [isSupplierDetailsEdit, setIsSupplierDetailsEdit] = useState(false);
  const decodedData = jwtDecode<any>(supplierComapnyData);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<string>("");
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState<string>("");
  const [selectedBuyer, setSelectedBuyer] = useState<string>("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getAddress({ companyId: Number(companyId) });
    const billingAddress = supplierAddress?.find((address: any) => address.addressType === 1);
    const deliveryAddress = supplierAddress?.find((address: any) => address.addressType === 2);

    const formattedSupplierData = {
      supplierBillingAddress: billingAddress
        ? `${billingAddress.addressLineOne}, ${billingAddress.city}, ${billingAddress.state}, ${billingAddress.country}. ${billingAddress.pincode}`
        : "No Billing Address Available",

      supplierDeliveryAddress: deliveryAddress
        ? `${deliveryAddress.addressLineOne}, ${deliveryAddress.city}, ${deliveryAddress.state}, ${deliveryAddress.country}. ${deliveryAddress.pincode}`
        : "No Delivery Address Available",
    };
    setDocumentSupplierData(formattedSupplierData);
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const onSubmitBuyersData = (entityData: any) => {
    console.log("Entity Data", entityData);
    setDocumentBuyerData(entityData);
    setIsModalOpen(false);
  };

  const handleSupplierEdit = () => {
    setChangeAddressModalTitle("Change Supplier's Addresses");
    setIsSupplierModalOpen(true);
    setIsSupplierDetailsEdit(true);
    setSelectedBillingAddress(supplierDetails?.supplierBillingAddress);
    setSelectedDeliveryAddress(supplierDetails?.supplierDeliveryAddress);
  };

  const handleSaveSupplierAddress = (supplierData: any) => {
    setDocumentSupplierData(supplierData);
    setIsSupplierModalOpen(false);
    setIsSupplierDetailsEdit(false);
  };

  const handleSupplierCancel = () => {
    setIsSupplierModalOpen(false);
  };

  const handleBuyerEdit = () => {
    console.log(buyerDetails, "buyerDetails")
    setIsEditing(true);
    setIsModalOpen(true);
    setChangeAddressModalTitle("Change Buyer's Addresses");
    setSelectedBuyer(buyerDetails)
    setSelectedBillingAddress(
      documentTo === "company" ? buyerDetails?.buyerBillingAddress : formattedAddress()
    );
    setSelectedDeliveryAddress(
      documentTo === "company" ? buyerDetails?.buyerDeliveryAddress : formattedAddress()
    );
  };

  const checkIsDocumentValid = (data: any) => {
    setDocumentValidationData(data);
    setDocumentItemValidationData(data);
  };

  const formattedAddress = () => {
    const { addressLineOne, city, state, country, pincode } = individualsData;
    return `${addressLineOne || ""}, ${city || ""}, ${state || ""}, ${country || ""}. ${pincode || ""}`.replace(/,(\s*)$/, "");
  };

  return (
    <>
      <Loader loading={loading}></Loader>
      {buyerDetails && (
        <Layout className="layout">
          <Breadcrumb
            items={[
              { title: "Dashboard" },
              { title: "Sales" },
              { title: "Documents" },
              { title: documentType },
            ]}
          />
          <div className="divDocuments mt-10">
            <Link
              to="/sales_documents"
              className="linkToDocs"
            >
              <p className="textDocument">
                <ArrowLeftOutlined /> {AppConstants.BACK_TO_DOCUMENTS_BUTTONS}
              </p>
            </Link>
          </div>
          <Content style={{ overflow: "auto" }}>
            <div className="addressContainer">
              <div className="halfGrow">
                <h6 className="title mb-10 fS-20">Bill From</h6>
                <Card className="addressCard">
                  <div className="flexBox mobile-row">
                    <h6>{decodedData?.companyName}</h6>
                    {/* <Tooltip title="Change Supplier's Billing and Delivery address">
                      <p onClick={handleSupplierEdit} className="actionIcons">
                        <EditOutlined />
                      </p>
                    </Tooltip> */}
                  </div>
                  <div>Email: {decodedData?.email}</div>
                  <div>Contact Number: +91 {decodedData?.contactPersonNumber}</div>
                  <div>
                    Delivery Address: &nbsp;
                    {supplierDetails.supplierDeliveryAddress}
                  </div>
                  <div>
                    Billing Address: &nbsp;
                    {supplierDetails.supplierBillingAddress}
                  </div>
                </Card>
              </div>
              <div className="halfGrow">
                <h6 className="title mb-10 fS-20 Mt-20"> Bill to </h6>
                <Card className="addressCard billingAddressCard">
                  <div className="flexBox mobile-row">
                    <h6>
                      {documentTo === "company"
                        ? buyerDetails?.buyerName
                        : documentTo === "individual"
                        ? individualsData?.buyerName
                        : ""}
                    </h6>

                    {/* <Tooltip title="Change Buyer's Billing and Delivery address">
                      <p onClick={handleBuyerEdit} className="actionIcons">
                        <EditOutlined />
                      </p>
                    </Tooltip> */}
                  </div>
                  <div>
                    Email: &nbsp;
                    {documentTo === "company"
                      ? buyerDetails?.buyerEmail
                      : documentTo === "individual"
                      ? individualsData?.buyerEmail
                      : ""}
                  </div>
                  <div>
                    Contact Number:&nbsp;
                    +91 {documentTo === "company"
                      ? buyerDetails?.buyerContactNumber
                      : documentTo === "individual"
                      ? individualsData?.buyerContactNumber
                      : ""}
                  </div>

                  <div>
                    Billing Address:&nbsp;
                    {documentTo === "company"
                      ? buyerDetails?.buyerBillingAddress
                      : documentTo === "individual"
                      ? formattedAddress()
                      : ""}
                  </div>
                  {documentTo === "company" && (
                    <div>
                      Delivery Address: {buyerDetails?.buyerDeliveryAddress}
                    </div>
                  )}
                </Card>
              </div>
            </div>

            <Card className="cardForm">
              <DocumentMetaDataForm
                documentValidationData={documentValidationData}
              />
            </Card>

            <DocumentItemGrid
              documentItemValidationData={documentItemValidationData}
            />
            {![
              "Goods Receive Notes",
              "Quality Report",
              "Purchase Invoice",
            ].includes(documentType) && (
              <>
                <Card className="cardForm">
                  <AdditionalCharge />
                </Card>
                <div className="mt-10">
                  <div className="flexBox">
                    <LogisticDetails />
                    <AdditionalDetails />
                  </div>
                </div>
              </>
            )}
            <div className="mt-10">
              <div className="flexBox">
                <Attachments />
                <Signature />
                {![
                  "Sales Enquiry",
                  "Sales Quotation",
                  "Order Confirmation",
                  "Delivery Challan",
                  "Invoice",
                  "Sales Return",
                  "Purchase Order",
                  "Purchase Invoice",
                  "Debit Note",
                  "Credit Note",
                  "Purchase Return",
                ].includes(documentType) && <CommentInput />}
                {![
                  "Sales Enquiry",
                  "Goods Receive Notes",
                  "Quality Report",
                  "Purchase Invoice",
                  "Purchase Order",
                  "Debit Note",
                  "Credit Note",
                  "Purchase Return",
                ].includes(documentType) && <BankDetails />}
              </div>
            </div>
            <Card className="mt-10 cardForm p-20 br-10">
              <div className="flexBox">
                {![
                  "Goods Receive Notes",
                  "Quality Report",
                  "Purchase Invoice",
                ].includes(documentType) && <TermsAndCondition />}
                <TotalCalculation checkIsDocumentValid={checkIsDocumentValid} loading={loading} setLoading={setLoading} />
              </div>
            </Card>
          </Content>
        </Layout>
      )}
      <EntitySelectionForm
        modelTitle={changeAddressModalTitle}
        isModalOpen={isModalOpen}
        handleSubmit={onSubmitBuyersData}
        handleCancel={handleCancel}
        isEditing={isEditing}
        selectedBuyer = {selectedBuyer}
        buyerDefaultBillId={buyerDetails?.buyerSelectedBillingAddressId}
        buyerDefaultDelId={buyerDetails?.buyerSelectedDeliveryAddressId}
      />
      <SupplierAddressModal
        modelTitle={changeAddressModalTitle}
        isSupplierModalOpen={isSupplierModalOpen}
        handleSaveSupplierAddress={handleSaveSupplierAddress}
        handleSupplierAddressCancel={handleSupplierCancel}
        isSupplierDetailsEdit={isSupplierDetailsEdit}
        supplierDefaultBillId={ supplierDetails?.supplierSelectedDeliveryAddressId }
        supplierDefaultDelId={supplierDetails?.supplierSelectedBillingAddressId}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login.data.companyId,
  individualsData: state.ui.createDocumentUIData?.individualData,
  buyerDetails: state.ui.createDocumentUIData?.buyerDetails,
  supplierDetails: state.ui.createDocumentUIData.supplierDetails,
  documentType: state.ui.createDocumentUIData.documentType,
  supplierAddress: state.api.getAddress?.data,
  supplierComapnyData: state.api.login?.data?.token,
  documentTo: state.ui.createDocumentUIData?.documentTo,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentBuyerData: (payload: any) => dispatch(setDocumentBuyerData(payload)),
  setDocumentSupplierData: (payload: any) => dispatch(setDocumentSupplierData(payload)),
  getAddress: (payload: any) => dispatch(getAddress(payload)),
  resetCreateDocumentData: () => dispatch(resetCreateDocumentData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument);
