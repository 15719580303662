import * as type from "../../types/API/newsLetters";

export function getNewsLetters(payload: any) {
  return {
    type: type.GET_NEWS_LETTERS,
    payload: payload,
  };
}

export function addNewsLetters(payload: any) {
  return {
    type: type.ADD_NEWS_LETTERS,
    payload: payload,
  };
}

export function unsubscribeNewsLetter (payload: any) {
  return {
    type: type.UNSUBSCRIBE_NEWS_LETTERS,
    payload: payload,
  };
}

