import * as XLSX from 'xlsx';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const downloadExcel = async (data: any, name: string, error?: any) => {
  if (name === "BulkUploadAlternateUnit" || name === "Bulk Upload Alternate Unit Error Data.") {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const units = data.map((unit: any) => `${unit.name} (${unit.code})`);
    const safeList = (list: any) => (list.length ? `"${list.join(",")}"` : `"N/A"`);

    worksheet.columns = [
      { header: "* Item ID", key: "* Item ID" },
      { header: "Item Name", key: "Item Name" },
      { header: "* Base Unit", key: "* Base Unit" },
      { header: "* Alternate Unit", key: "* Alternate Unit" },
      { header: "* Conversion Factor", key: "* Conversion Factor" },
      { header: "Unit Conversion Formula", key: "Unit Conversion Formula" }
    ];
    if (error) {
      worksheet.columns = [...worksheet.columns, { header: "Error", key: "Error" }];
    }

    const sampleData = error ? error : [
      { "* Item ID": 1, "Item Name": "Laptop", "* Base Unit": units[0], "* Alternate Unit": units[0], "* Conversion Factor": 10 },
    ];

    sampleData.forEach((row: any, index: number) => {
      const rowData = {
        ...row,
        "Unit Conversion Formula": { formula: `=1 & " " & D${index + 2} & " = " & E${index + 2} & " * " & C${index + 2} & " " & B${index + 2}` },
      };
      worksheet.addRow(rowData);
    });

    // sampleData.forEach((row:any) => worksheet.addRow(row));

    worksheet.getColumn("* Base Unit").eachCell((cell, rowNumber) => {
      if (rowNumber !== 1) {
        cell.dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [safeList(units)],
          showErrorMessage: true,
          errorTitle: "Invalid Selection",
          error: "Please select a valid Base Unit.",
        };
      }
    });

    worksheet.getColumn("* Alternate Unit").eachCell((cell, rowNumber) => {
      if (rowNumber !== 1) {
        cell.dataValidation = {
          type: "list",
          allowBlank: false,
          formulae: [safeList(units)],
          showErrorMessage: true,
          errorTitle: "Invalid Selection",
          error: "Please select a valid Alternate Unit.",
        };
      }
    });
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `${name}.xlsx`);
    return;
  }
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, name + '.xlsx');
}

export const downloadExcelWithDropDown = async (data: any[], name: string, categories: any, error: null | boolean) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  let subCategories: any = [];
  let microCategories: any = [];
  categories.forEach((category: any) => {
    if (category?.child?.length) {
      subCategories = [...subCategories, ...category.child];
    }
  });

  subCategories.forEach((subCategory: any) => {
    if (subCategory?.child?.length) {
      microCategories = [...microCategories, ...subCategory.child];
    }
  });

  worksheet.columns = [
    { header: "Item ID", key: "Item ID" },
    { header: "Item Name", key: "Item Name" },
    { header: "Item type", key: "Item type" },
    { header: "Category", key: "Category" },
    { header: "Sub Category", key: "Sub Category" },
    { header: "Micro Category", key: "Micro Category" },
    { header: "HSN", key: "HSN" },
    { header: "Price", key: "Price" },
    { header: "Tax Type", key: "Tax Type" },
    { header: "Tax", key: "Tax" },
    { header: "Min Stock", key: "Min Stock" },
    { header: "Max Stock", key: "Max Stock" },
    { header: "Description", key: "Description" },
  ];
  if (error) worksheet.columns = [...worksheet.columns, { header: "Error", key: "Error" }];
  console.log(data, error);
  data.forEach((item) => {
    worksheet.addRow({ ...item });
  });

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell((cell) => {
      cell.protection = { locked: false };
    });
  });

  worksheet.getColumn("Item ID").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.protection = { locked: true };
    }
  });

  worksheet.getColumn("Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = categories?.findIndex((category: any) => category?.id === data[rowNumber - 2]?.Category);
      if (index != -1) {
        cell.value = categories[index]?.name;
      } else {
        cell.value = '';
        if (error) cell.value = data[rowNumber - 2]?.Category || '';
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${categories.map((category: any) => category.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Category.",
      };
    }
  });

  worksheet.getColumn("Sub Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = subCategories?.findIndex((subcategory: any) => subcategory?.id === data[rowNumber - 2]?.['Sub Category']);
      if (index != -1) {
        cell.value = subCategories[index]?.name;
      } else {
        cell.value = '';
        if (error) cell.value = data[rowNumber - 2]?.['Sub Category'] || '';
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${subCategories.map((subCategory: any) => subCategory.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Sub Category.",
      };
    }
  });

  worksheet.getColumn("Micro Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = microCategories?.findIndex((microCategory: any) => microCategory?.id === data[rowNumber - 2]?.['Micro Category']);
      if (index != -1) {
        cell.value = microCategories[index]?.name;
      } else {
        cell.value = '';
        if (error) cell.value = data[rowNumber - 2]?.['Micro Category'];
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${microCategories.map((microCategory: any) => microCategory.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Micro Category.",
      };
    }
  });
  const numericColumns = ["Price", "Tax", "Min Stock", "Max Stock"];
  numericColumns.forEach((column) => {
    worksheet.getColumn(column).eachCell((cell, rowNumber) => {
      if (rowNumber > 1) {
        cell.dataValidation = {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          allowBlank: true,
          showErrorMessage: true,
          errorTitle: "Invalid Input",
          error: `Please enter a valid numeric value for ${column}.`,
        };
      }
    });
  });

  worksheet.getColumn("Tax Type").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Inclusive,Exclusive"'],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Tax Type.",
      };
    }
  });

  worksheet.getColumn("Item type").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Buy,Sell,Both"'],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Item type.",
      };
    }
  });
  await worksheet.protect("password", { selectLockedCells: true, selectUnlockedCells: true });
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${name}.xlsx`);
};

export const downloadExcelTemplateForBulkupload = async (name: string, categories: any, metricsUnit: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  let subCategories: any[] = [];
  let microCategories: any[] = [];

  categories.forEach((category: any) => {
    if (category?.child?.length) {
      subCategories = [...subCategories, ...category.child];
    }
  });

  subCategories.forEach((subCategory: any) => {
    if (subCategory?.child?.length) {
      microCategories = [...microCategories, ...subCategory.child];
    }
  });

  worksheet.columns = [
    { header: "* Item ID", key: "Item ID" },
    { header: "* Item Name", key: "Item Name" },
    { header: "* Item Type", key: "Item Type" },
    { header: "Category", key: "Category" },
    { header: "Sub Category", key: "Sub Category" },
    { header: "Micro Category", key: "Micro Category" },
    { header: "* Metrics Unit", key: "Metrics Unit" },
    { header: "HSN", key: "HSN" },
    { header: "Price", key: "Price" },
    { header: "Tax Type", key: "Tax Type" },
    { header: "Tax", key: "Tax" },
    { header: "Min Stock", key: "Min Stock" },
    { header: "Max Stock", key: "Max Stock" },
    { header: "Description", key: "Description" },
  ];

  const dummyRow = {
    "Item ID": "1001",
    "Item Name": "Sample Item",
    "Item Type": "Buy",
    Category: "",
    "Sub Category": "",
    "Micro Category": "",
    "Metrics Unit": metricsUnit.length ? `${metricsUnit[0].name} (${metricsUnit[0].code})` : "",
    HSN: "123456",
    Price: "0.00",
    "Tax Type": "",
    "Tax": "0",
    "Min Stock": "1",
    "Max Stock": "100",
    Description: "Sample description",
  };

  worksheet.addRow(dummyRow);

  const categoryList = categories.map((cat: any) => cat.name).join(",");
  const subCategoryList = subCategories.map((sub: any) => sub.name).join(",");
  const microCategoryList = microCategories.map((micro: any) => micro.name).join(",");
  metricsUnit = metricsUnit.map((unit: any) => `${unit.name} (${unit.code})`).join(",");

  const safeList = (list: string) => (list.length ? `"${list}"` : `"N/A"`);
  const rowNum = 2;

  worksheet.getCell(`C${rowNum}`).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: ['"Buy,Sell,Both"'],
    showErrorMessage: true,
    errorTitle: "Invalid Selection",
    error: "Please select a valid Item Type.",
  };

  worksheet.getColumn("Tax Type").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Inclusive,Exclusive"'],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Tax Type.",
      };
    }
  });

  worksheet.getCell(`D${rowNum}`).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: [safeList(categoryList)],
    showErrorMessage: true,
    errorTitle: "Invalid Selection",
    error: "Please select a valid Category.",
  };

  worksheet.getCell(`E${rowNum}`).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: [safeList(subCategoryList)],
    showErrorMessage: true,
    errorTitle: "Invalid Selection",
    error: "Please select a valid Sub Category.",
  };

  worksheet.getCell(`F${rowNum}`).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: [safeList(microCategoryList)],
    showErrorMessage: true,
    errorTitle: "Invalid Selection",
    error: "Please select a valid Micro Category.",
  };

  worksheet.getCell(`G${rowNum}`).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: [safeList(metricsUnit)],
    showErrorMessage: true,
    errorTitle: "Invalid Selection",
    error: "Please select a valid Metrics Unit.",
  };

  const numericColumns = ["Price", "Min Stock", "Max Stock"];
  numericColumns.forEach((column) => {
    worksheet.getColumn(column).eachCell((cell, rowNumber) => {
      if (rowNumber === rowNum) {
        cell.dataValidation = {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          allowBlank: true,
          showErrorMessage: true,
          errorTitle: "Invalid Input",
          error: `Please enter a valid numeric value for ${column}.`,
        };
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${name}.xlsx`);
};

export const downloadBulkUploadErrorData = async (data: any[], name: string, categories: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  let subCategories: any = [];
  let microCategories: any = [];
  categories.forEach((category: any) => {
    if (category?.child?.length) {
      subCategories = [...subCategories, ...category.child];
    }
  });

  subCategories.forEach((subCategory: any) => {
    if (subCategory?.child?.length) {
      microCategories = [...microCategories, ...subCategory.child];
    }
  });

  worksheet.columns = [
    { header: "* Item ID", key: "* Item ID" },
    { header: "* Item Name", key: "* Item Name" },
    { header: "* Item Type", key: "* Item Type" },
    { header: "Category", key: "Category" },
    { header: "Sub Category", key: "Sub Category" },
    { header: "Micro Category", key: "Micro Category" },
    { header: "HSN", key: "HSN" },
    { header: "Price", key: "Price" },
    { header: "Tax Type", key: "Tax Type" },
    { header: "Tax", key: "Tax" },
    { header: "Min Stock", key: "Min Stock" },
    { header: "Max Stock", key: "Max Stock" },
    { header: "Description", key: "Description" },
    { header: "Error", key: "Error" }
  ];
  data.forEach((item) => {
    worksheet.addRow({ ...item });
  });

  worksheet.getColumn("Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = categories?.findIndex((category: any) => category?.id === data[rowNumber - 2]?.Category);
      if (index != -1) {
        cell.value = categories[index]?.name;
      } else {
        cell.value = '';
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${categories.map((category: any) => category.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Category.",
      };
    }
  });

  worksheet.getColumn("Sub Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = subCategories?.findIndex((subcategory: any) => subcategory?.id === data[rowNumber - 2]?.['Sub Category']);
      if (index != -1) {
        cell.value = subCategories[index]?.name;
      } else {
        cell.value = '';
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${subCategories.map((subCategory: any) => subCategory.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Sub Category.",
      };
    }
  });

  worksheet.getColumn("Micro Category").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      const index = microCategories?.findIndex((microCategory: any) => microCategory?.id === data[rowNumber - 2]?.['Micro Category']);
      if (index != -1) {
        cell.value = microCategories[index]?.name;
      } else {
        cell.value = '';
      }
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`"${microCategories.map((microCategory: any) => microCategory.name).join(",")}"`],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Micro Category.",
      };
    }
  });
  const numericColumns = ["Price", "Tax", "Min Stock", "Max Stock"];
  numericColumns.forEach((column) => {
    worksheet.getColumn(column).eachCell((cell, rowNumber) => {
      if (rowNumber > 1) {
        cell.dataValidation = {
          type: "decimal",
          operator: "greaterThanOrEqual",
          formulae: [0],
          allowBlank: true,
          showErrorMessage: true,
          errorTitle: "Invalid Input",
          error: `Please enter a valid numeric value for ${column}.`,
        };
      }
    });
  });

  worksheet.getColumn("Tax Type").eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Inclusive,Exclusive"'],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Tax Type.",
      };
    }
  });

  worksheet.getColumn("* Item Type")?.eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: ['"Buy,Sell,Both"'],
        showErrorMessage: true,
        errorTitle: "Invalid Selection",
        error: "Please select a valid Item type.",
      };
    }
  });
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${name}.xlsx`);
};

