import { Card, notification, Tooltip } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { AppConstants } from "../../../../Appconstants";
import { connect } from "react-redux";
import { setDocumentAttachmentsData } from "../../../../redux/actions/UI/createDocument";

interface IAttachmentsProps {
  setDocumentAttachmentsData: Function;
}

const Attachments: React.FC<IAttachmentsProps> = ({
  setDocumentAttachmentsData,
}) => {
  const [attachments, setAttachments] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const openNotificationWithIcon = (type: any, message: string) => {
    notification.open({
      message: message,
      type: type,
      duration: 3,
    });
  };
  
  useEffect(() => {
    setDocumentAttachmentsData(attachments);
  }, [attachments]);

  const handleAttachmentsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    
    const selectedFiles = Array.from(event.target.files);
    const fileSizeLimit = 5 * 1024 * 1024;
    const maxFileCount = 5;
    
    if (attachments.length + selectedFiles.length > maxFileCount) {
      openNotificationWithIcon("error", `You can upload a maximum of ${maxFileCount} files.`);
      return;
    }

    const validFiles = selectedFiles.filter(file => {
      if (file.size > fileSizeLimit) {
        openNotificationWithIcon("error", `File "${file.name}" exceeds the 5 MB size limit.`);
        return false;
      }
      return true;
    });
    
    setAttachments(prev => [...prev, ...validFiles]);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleDeleteAttachment = (index: number) => {
    setAttachments(prev => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1 fileCard">
      <div className="card-content">
        <div className="title mb-10 fS-20">
          {AppConstants.ATTACHMENTS_DETAIL.ATTACHMENTS_LABEL} &nbsp;
          <Tooltip title={AppConstants.ATTACHMENTS_DETAIL.ATTACHMENTS_LABEL}>
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </div>
        <input
          type="file"
          multiple
          accept="*/*"
          onChange={handleAttachmentsChange}
          ref={fileInputRef}
        />
        {attachments.length > 0 ? (
          attachments.map((attachment, index) => (
            <div key={index} className="flexBox fileList">
              <div>{attachment?.name}</div>
              <div className="deleteIcon">
                <DeleteOutlined
                  className="delete-icon"
                  onClick={() => handleDeleteAttachment(index)}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="file-size-info">
            {AppConstants.ATTACHMENTS_DETAIL.ATTACHMENTS_UPLOAD_DETAILS}
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentAttachmentsData: (payload: File[]) => dispatch(setDocumentAttachmentsData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
