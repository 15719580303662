import * as type from '../../types/API/storeTypes'

export function addStores(payload: any) {
    return {
        type: type.ADD_STORE,
        payload: payload
    }
}

export function getStores(payload: any) {
    return {
        type: type.GET_STORE,
        payload: payload
    }
}

export function getStoreById(payload: any) {
    return {
        type: type.GET_STORE_BY_ID,
        payload: payload
    }
}

export function getStoreByItems(payload: any) {
    return {
        type: type.GET_STORE_BY_ITEMS,
        payload: payload
    }
}

export function editStores(payload: any) {
    return {
        type: type.EDIT_STORE,
        payload: payload
    }
}

export function deleteStores(payload: any) {
    return {
        type: type.DELETE_STORE,
        payload: payload
    }
}

export function getStoreWithItems(payload: any) {
    return {
        type: type.GET_STORE_BY_ITEM,
        payload: payload
    }
}
