import { QRCode } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
interface IDocumentSlicerProps {
  dataToPreview: any;
  companyName: string;
  logo: string;
  logisticsData: any[];
}

const DocumentSlicer: React.FC<IDocumentSlicerProps> = ({ dataToPreview, companyName, logo, logisticsData, }) => {
    useEffect(() => {
        console.log(dataToPreview, "logisticDetails") 
      }, [])
      
    return <div>
        <div className="documentHeader">
            <div>
                <img
                    src={logo}
                    className="logo"
                    alt="Ease Margin"
                    style={{ margin: "0px 0px 0px 0px", height: "40px" }}
                />
                <div className='mt-20' style={{ fontSize: '17px', fontWeight: '500' }}>{companyName}</div>
                <div className='title'>Address: {dataToPreview?.supplierBillingAddress}</div>
                <div className='title'>Contact: +91-9876543210</div>
                <div className='title'>Email: info@easemargin.com</div>
                <div className='title'>Website: www.easemargin.com</div>
                <div className='title'>
                {dataToPreview?.documentType} Date: {new Date(dataToPreview?.documentDate).toLocaleDateString("en-GB")}
                </div>
            </div>
            <div>
                <h3>{dataToPreview?.documentType}</h3>
                <div className='title'>{dataToPreview?.documentType}: {dataToPreview?.documentNumber}</div>
            </div>
        </div>
        <h6 className="mb-10">Bill To:</h6>
        <hr></hr>
        <div className="buyerSupplierContainer mt-10">
            <div className="supplierDetails" style={{ maxWidth: '40%' }}>
                <div style={{ fontSize: '17px', fontWeight: '500' }}>{dataToPreview?.buyerName}</div>
                <div className='title'>Contact Number: {dataToPreview?.buyerContactNumber}</div>
                <div className='title'>Billing Address: {dataToPreview?.buyerBillingAddress}</div>
                {dataToPreview?.buyerDeliveryAddress && <div className='title'>Delivery Address: {dataToPreview?.buyerDeliveryAddress} </div>}
            </div>
            <div className="flexBox supplierDetails">
                <div>
                    {/* <div>Return Receive Date: {dataToPreview?.returnRecieveDate ? new Date(dataToPreview.returnRecieveDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>Invoice Date: {dataToPreview?.invoiceDate ? new Date(dataToPreview.invoiceDate).toLocaleDateString("en-GB") : "-"}</div> */}
                    {/* {JSON.stringify(dataToPreview)} */}
                    {dataToPreview?.indent_number && (<div className='title'>Indent Number: {dataToPreview.indent_number}</div>)}
                    {dataToPreview?.indent_date && (<div className='title'>Indent Date: {new Date(dataToPreview.indent_date).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.quotationNumber && (<div className='title'>Quotation Number: {dataToPreview.quotationNumber}</div>)}
                    {dataToPreview?.quotationDate && (<div className='title'>Quotation Date: {new Date(dataToPreview.quotationDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.purchaseOrderNumber && (<div className='title'>Purchase Order Number: {dataToPreview?.purchaseOrderNumber}</div>)}
                    {dataToPreview?.purchaseOrderDate && (<div className='title'>Purchase Order Date: {new Date(dataToPreview.purchaseOrderDate).toLocaleDateString("en-GB")}</div> )}
                    {dataToPreview?.enquiryNumber && (<div className='title'>Enquiry Number: {dataToPreview.enquiryNumber}</div>)}
                    {dataToPreview?.enquiryDate && (<div className='title'>Enquiry Date: {new Date(dataToPreview.enquiryDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.deliveryDate && (<div className='title'>Exp. Delivery Date: {new Date(dataToPreview.deliveryDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.POCName && (<div className='title'>POC Name: {dataToPreview?.POCName} </div>)}
                    {dataToPreview?.POCNumber && (<div  className='title'>POC Number: {dataToPreview?.POCNumber} </div>)}
                    {dataToPreview?.billDate && (<div className='title'>Bill Date: {new Date(dataToPreview.billDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.orderConfirmationNumber && (<div className='title'>OC Number: {dataToPreview?.orderConfirmationNumber}</div>)}
                    {dataToPreview?.orderConfirmationDate && (<div className='title'>OC Date: {new Date(dataToPreview.orderConfirmationDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.TDDate && (<div className='title'>Transportation Doc Date: {new Date(dataToPreview.TDDate).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.supplier_invoice_number && (<div className='title'>Supplier Invoice Number: {dataToPreview?.supplier_invoice_number}</div>)}
                    {dataToPreview?.supplier_invoice_date && (<div className='title'>Supplier Invoice Date: {new Date(dataToPreview.supplier_invoice_date).toLocaleDateString("en-GB")}</div>)}

                    {dataToPreview?.challan_number && (<div className='title'>Challan Number: {dataToPreview?.challan_number}</div>)}
                    {dataToPreview?.challan_date && (<div className='title'>Challan Date: {new Date(dataToPreview.challan_date).toLocaleDateString("en-GB")}</div>)}
                    {dataToPreview?.replyDate && (<div className='title'>Reply Date: {new Date(dataToPreview.replyDate).toLocaleDateString("en-GB")}</div> )}
                    {dataToPreview?.returnRecieveDate && (<div className='title'>Return Recieved Date: {new Date(dataToPreview.returnRecieveDate).toLocaleDateString("en-GB")}</div> )}
                    {dataToPreview?.creditNoteNumber && (<div className='title'>Credit Note Number: {dataToPreview.creditNoteNumber}</div> )}
                    {dataToPreview?.creditNotedate && (<div className='title'>Credit Note Date: {new Date(dataToPreview.creditNotedate).toLocaleDateString("en-GB")}</div> )}
                </div>
                <div>
                {dataToPreview?.paymentTerm && (<div className='title'>Payment Term: {dataToPreview?.paymentTerm}</div>)}
                {dataToPreview?.paymentDate && (<div className='title'>Payment Date: {new Date(dataToPreview.paymentDate).toLocaleDateString("en-GB")}</div>)}
                {dataToPreview?.store && <div className='title'>Store: {dataToPreview?.store}</div>}
                {dataToPreview?.transporterName && <div className='title'>Transporter name: {dataToPreview?.transporterName} </div>}
                {dataToPreview?.TGNumber && <div className='title'>Transporter GST No: {dataToPreview?.TGNumber} </div>}
                {dataToPreview?.TDNumber && <div className='title'>Transportation Doc. No: {dataToPreview?.TDNumber} </div>}
                {dataToPreview?.VehicleNumber && <div className='title'>Vehicle Number: {dataToPreview?.VehicleNumber} </div>}
                {dataToPreview?.invoiceNumber && <div className='title'>Invoice Number: {dataToPreview?.invoiceNumber} </div>}
                {dataToPreview?.invoiceDate && (<div className='title'>Invoice Date: {new Date(dataToPreview.invoiceDate).toLocaleDateString("en-GB")}</div>)}
                {dataToPreview?.Attention && (<div className='title'>Kind Attention: {dataToPreview?.Attention}</div>)}
            {/* 
                    <div>Invoice No: {dataToPreview?.invoiceNumber} </div> */}
          </div>
          <div>
            {/* <div>TD No.: {dataToPreview?.TDNumber}</div>
                    <div>TD Date: {dataToPreview?.TDDate ? new Date(dataToPreview.TDDate).toLocaleDateString("en-GB") : "-"}</div>
                    <div>TG No: {dataToPreview?.TGNumber}</div> 
                    <div>Bill Date: {dataToPreview?.billDate ? new Date(dataToPreview.billDate).toLocaleDateString("en-GB") : "-"}</div>
                  
                    <div>Exp. Payment Date: {dataToPreview?.paymentDate ? new Date(dataToPreview.paymentDate).toLocaleDateString("en-GB") : "-"}</div> */}
                </div>
            </div>
        </div>
        <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid silver' }}>
            {(() => {
                const showDiscountColumn = dataToPreview?.items?.some((item: any) => item.discountOne > 0 || item.discountTwo > 0);
                const showTotalDiscountColumn = dataToPreview?.items?.some((item: any) => item.discountTwo > 0 && item.totalDiscount > 0);
                return (
                    <>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>S.No</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Item Description</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>UOM</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Qty.</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Price</th>
                                {showDiscountColumn && (
                                    <th style={{ border: '1px solid silver', padding: '8px' }}>Discount %</th>
                                )}
                                {showTotalDiscountColumn && (
                                    <th style={{ border: '1px solid silver', padding: '8px' }}>Total Discount %</th>
                                )}
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Tax Type</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Tax %</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total Tax</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total Before Tax</th>
                                <th style={{ border: '1px solid silver', padding: '8px' }}>Total After Tax</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataToPreview?.items?.map((item: any, index: number) => {
                                if (!item.quantity || item.quantity === 0) return null;

                                return (
                                    <tr key={item.id}>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{index + 1}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            <span>{item.itemName}</span>
                                            <br />
                                            <span>{item.HSN !== '' ? `HSN Code - ${item.HSN}` : ''}</span>
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.UOM}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.quantity}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {Number(item.price).toFixed(2)}</td>
                                        {showDiscountColumn && (
                                            <td style={{ border: '1px solid silver', padding: '8px' }}>
                                                {item.discountOne > 0 || item.discountTwo > 0
                                                    ? `${item.discountOne > 0 ? `${item.discountOne}%` : ''} ${item.discountTwo > 0 ? `- ${item.discountTwo}%` : ''
                                                        }`.trim()
                                                    : ''}
                                            </td>
                                        )}
                                        {showTotalDiscountColumn && (
                                            <td style={{ border: '1px solid silver', padding: '8px' }}>
                                                {item.discountTwo > 0 && item?.totalDiscount > 0
                                                    ? `${Number(item.totalDiscount).toFixed(2)}%`
                                                    : ''}
                                            </td>
                                        )}
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item.taxType}</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>{item?.tax}%</td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalTax).toFixed(2)}
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalBeforeTax).toFixed(2)}
                                        </td>
                                        <td style={{ border: '1px solid silver', padding: '8px' }}>
                                            ₹ {Number(item.totalAfterTax).toFixed(2)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </>
                );
            })()}
        </table>

        {dataToPreview?.additionalCharges && dataToPreview.additionalCharges.some((item: any) => item.chargingFor && item.price !== 0 && item.total !== 0) ? (
            <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid silver' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>S.No</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Additional Charges</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Price</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Tax%</th>
                        <th style={{ border: '1px solid silver', padding: '8px' }}>Total Charge</th>
                    </tr>
                </thead>
                <tbody>
                    {dataToPreview.additionalCharges.map((item: any, index: number) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{index + 1}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{item.chargingFor}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {item.price}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>{item.tax}</td>
                            <td style={{ border: '1px solid silver', padding: '8px' }}>₹ {item.total}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : null}

        <div className={`bankDetailsContainer mb-10 mt-10 col-gap-100 ${!dataToPreview?.bankDetails[0]?.bankName ? 'no-bank' : ''}`}>
            
        {dataToPreview?.bankDetails[0]?.bankName && (<div className=" ">
            <div>
                <h6 className='mb-10'>Bank Details</h6>
                <div className="totals">
                    <div>
                        <span className="fw-500">Bank Name: </span>
                        <span>{dataToPreview?.bankDetails[0]?.bankName}</span>
                    </div>                         
                    <div>
                        <span className="fw-500">Account Number: </span>
                        <span>{dataToPreview?.bankDetails[0]?.accountNumber}</span>
                    </div>
                    <div>
                        <span className="fw-500">IFSC Code: </span><span>{dataToPreview?.bankDetails[0]?.IFSCCode}</span>
                    </div>                       
                </div>
            </div>
            </div>)}
            
            {/* {dataToPreview?.additionalDetails && ( 
            <div>
                <div>Pay Using UPI</div>
                <QRCode
                    style={{ marginBottom: 16, padding: '5px'}}
                    size={105}
                    errorLevel={'L'}
                    value="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
                />
            </div>
        )} */}
            <div></div>
            <div className="totals">
                <div>
                    <span className="fw-500">Total Before Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalBeforeTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div>
                    <span className="fw-500">Total Tax (CGST and SGST)</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div>
                    <span className="fw-500">Total After Tax</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div>
                    <span className="fw-500">Additional Charges</span>
                    <span className="fw-500">
                        ₹ {dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + charge.total, 0).toFixed(2) ?? 0}
                    </span>
                </div>
                <div className="grand-total">
                    <span className="fw-500">Grand Total</span>
                    <span className="fw-500">
                        ₹ {(
                            dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0) +
                            dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + Number(charge.total), 0)
                        ).toFixed(2) ?? 0}
                    </span>
                </div>
            </div>
        </div>
        <hr></hr>
        <div className="flexBox">
            <div>
                {dataToPreview?.logisticDetailsId && (
                <>
                    <h6 className="mt-10 ">Logistic Details</h6>
                    <span>
                        {logisticsData.find(
                            (doc) => doc.id === dataToPreview.logisticDetailsId
                        )?.description}
                    </span>
                </>
                )}
                {dataToPreview?.documentComments && dataToPreview.documentComments.length > 0 && (
                <>
                    <h6 className="mt-10">Comments</h6>
                    {dataToPreview?.documentComments?.map((comment: { id: number; documentId: number; commentText: string; createdBy: number; createdAt: string; updatedAt: string }, index: number) => (
                        <span key={comment.id} className="block">
                            {comment.commentText}
                        </span>
                    ))}
                </>
                )}

                {dataToPreview?.additionalDetails && (<><h6 className="mt-10 ">Additional Details</h6>
                <span>
                    {dataToPreview?.additionalDetails || "No additional details"}
                </span></>)}

                {dataToPreview?.attachments && dataToPreview?.attachments.length > 0 && (
                <>
                    <h6 className=" mt-10">Attachments</h6>
                    <div>
                    {dataToPreview.attachments?.map((attachment: string, index: number) => {
                        const fileName = attachment?.split('/')?.pop(); 
                        const cleanedFileName = fileName?.replace(/^\d+-/, '');
                        return (
                        <div key={index}>
                            <a href={attachment} target="_blank" rel="noopener noreferrer"   style={{ color: '#1677ff', textDecoration: 'underline'}}>
                            {cleanedFileName}
                            </a>
                        </div>
                        );
                    })}
                    </div>
                </>
                )}

                {dataToPreview?.termsCondition && 
                    Array.isArray(dataToPreview?.termsCondition) &&
                    dataToPreview?.termsCondition.length > 0 && (
                        <>
                            <h6 className="mb-10 mt-10">Terms and Conditions</h6>
                            {/* {JSON.stringify(dataToPreview?.termsCondition)} */}
                            <div>
                                {dataToPreview?.termsCondition?.map((termObj: { term: string; desc: string }, index: number) => (
                                    <div key={index} style={{ marginBottom: '8px', display: 'block' }}>
                                        <span className="fw-500">{termObj.term}:</span> {termObj.desc}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
            </div>
            {dataToPreview?.signature && (<div className="mt-10">
                <div>
                    <img src={dataToPreview?.signature} alt="Signature" />
                    <p>Authorized Signature</p>
                </div>
            </div>
            )}
        </div>
    </div>;
}

const mapStateToProps = (state: any) => ({
  logisticsData: state.api.getLogisticDetails?.data, 
});

const mapDispatchToProps = (dispatch: any) => ({ 
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentSlicer);
