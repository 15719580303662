import React, { useEffect, useRef, useState } from "react";
import { Card, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import "./BankDetails.css";
import { getBankDetail } from "../../../redux/actions/API/bankDetail";
import { setBankDetails } from "../../../redux/actions/UI/createDocument";
import noData from '../../../Utility/images/noData.png'

interface BankData {
  id: number;
  bankName: string;
  accountHolderName: string;
  accountNumber: string | number;
  branch: string;
  swiftCode?: string;
  SWIFTCode?: string;
  IFSCCode: string;
  MICRCode?: string;
  address: string;
  companyId: number;
}

interface StoreBankData {
  accountName: string;
  accountNumber: string | number;
  SWIFTCode?: string;
  IFSCCode: string;
  bankName: string;
  branch: string;
  [key: string]: any;
}

interface BankDetailsProps {
  getBankDetail: (payload: { companyId: number }) => void;
  setBankDetails: (payload: BankData | null) => void;
  bankDetailData: BankData[];
  companyId: number;
  bankDetailsDataFromStore?: StoreBankData;
}

const BankDetails: React.FC<BankDetailsProps> = ({
  bankDetailData = [],
  companyId,
  getBankDetail,
  setBankDetails,
  bankDetailsDataFromStore
}) => {
  const [selectedBank, setSelectedBank] = useState<BankData | null>(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    getBankDetail({ companyId: Number(companyId) });
  }, [companyId, getBankDetail]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (bankDetailData?.length === 0) return;

    if (bankDetailsDataFromStore?.accountNumber) {
      const matchedBank = bankDetailData.find(bank => 
        bank.accountHolderName?.toLowerCase() === bankDetailsDataFromStore.accountName?.toLowerCase() &&
        String(bank.accountNumber) === String(bankDetailsDataFromStore.accountNumber)
      );

      if (matchedBank) {
        setSelectedBank(matchedBank);
        return;
      }
    }
  }, [bankDetailsDataFromStore]);

    useEffect(() => {
      if (bankDetailData?.length > 0 && !selectedBank) {
        if (bankDetailsDataFromStore?.accountNumber) {
          const matchedBank = bankDetailData?.find(bank => 
            bank.accountHolderName?.toLowerCase() === bankDetailsDataFromStore.accountName?.toLowerCase() &&
            String(bank.accountNumber) === String(bankDetailsDataFromStore.accountNumber)
          );
  
          if (matchedBank) {
            setSelectedBank(matchedBank);
            return;
          }
        }
  
        // Fallback to first bank
        setSelectedBank(bankDetailData[0]);
        setBankDetails(bankDetailData[0]);
      }
    }, [bankDetailData]);

  const handleAccountChange = (value: string) => {
    const selected = bankDetailData.find(
      bank => bank.accountHolderName === value
    );
    if (selected) {
      setSelectedBank(selected);
      setBankDetails(selected);
    }
  };

  return (
    <Card className="custom-card no-header-border flex-grow-1">
      <div className="card-content">
        <div className="flexBox mobAddCategory">
          <div className="title mb-10 fS-20">
            Bank Details
            <Tooltip title="Select bank account details">
              <InfoCircleOutlined className="info-icon ml-10" />
            </Tooltip>
          </div>
          <Select
            className="itemGridFields-lg"
            placeholder="Select bank account"
            onChange={handleAccountChange}
            value={selectedBank?.accountHolderName || undefined}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children?.toString().toLowerCase() ?? '').includes(input.toLowerCase())
            }
          >
            {bankDetailData?.map(bank => (
              <Select.Option 
                key={bank.id} 
                value={bank.accountHolderName}
              >
                {bank.accountHolderName}
              </Select.Option>
            ))}
          </Select>
        </div>

        {selectedBank ? (
          <div className="flexBox mt-20">
            <div className="flexBox mobAddCategory">
              <div>
                <div className="title fS-14">Bank Name:</div>
                <div className="title fS-14">A/C Holder Name:</div>
                <div className="title fS-14">Branch:</div>
                <div className="title fS-14">A/C Number:</div>
              </div>
              <div>
                <div>{selectedBank.bankName}</div>
                <div>{selectedBank.accountHolderName}</div>
                <div>{selectedBank.branch}</div>
                <div>{selectedBank.accountNumber}</div>
              </div>
            </div>
            <div className="flexBox mobAddCategory">
              <div>
                <div className="title fS-14">SWIFT Code:</div>
                <div className="title fS-14">IFSC Code:</div>
                <div className="title fS-14">MICR Code:</div>
              </div>
              <div>
                <div>{selectedBank.swiftCode || selectedBank.SWIFTCode || 'N/A'}</div>
                <div>{selectedBank.IFSCCode}</div>
                <div>{selectedBank.MICRCode || 'N/A'}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="center">
            <img src={noData} alt="No bank details available" />
          </div>
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  bankDetailData: state.api.getBankDetail?.data,
  companyId: state.api.login.data.companyId,
  bankDetailsDataFromStore: state.ui.createDocumentUIData.bankDetails,
});

const mapDispatchToProps = (dispatch: any) => ({
  getBankDetail: (payload: any) => dispatch(getBankDetail(payload)),
  setBankDetails: (payload: any) => dispatch(setBankDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);