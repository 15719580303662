import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { IManageCategoriesProps } from "./IManageCategories";
import { Alert, Button, Drawer, Form, Input, Space, Tree, Tooltip, notification } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined, ArrowLeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import './manageCategories.css';
import { AppConstants } from "../../../Appconstants";
import { addCategory, deleteCategory, editCategory, getCategories } from "../../../redux/actions/API/categories";
import Loader from "../../../Home/Loader/Loader";

const { TreeNode } = Tree;

const ManageCategories: React.FC<IManageCategoriesProps> = (props) => {
    const [isManageCategoryOpen, setIsManageCategoryOpen] = useState<boolean>(props.isManageCategoryOpen);
    const [isAddManageCategoryOpen, setIsAddManageCategoryOpen] = useState<boolean>(props.isAddManageCategoryOpen);
    const [categoryName, setCategoryName] = useState<string>('');
    const [categoryDescription, setCategoryDescription] = useState<string>('');
    const [categoryLevel, setCategoryLevel] = useState<string>('Category');
    const [categories, setCategories] = useState<any>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [parentId, setParentId] = useState<string | null>(null);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [parentCategory, setParentCategory] = useState<string | null>(null);
    const [grandparentCategory, setGrandparentCategory] = useState<string | null>(null);
    const formRef = useRef<any>(null);
    const prevPropsRef = useRef<IManageCategoriesProps>();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredCategories, setFilteredCategories] = useState(props.categoryData);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
    const [form] = Form.useForm();

    type NotificationType = "success" | "info" | "warning" | "error";

    const openNotificationWithIcon = (
        type: NotificationType,
        message: string
    ) => {
        notification.open({
            message: message,
            type: type,
            duration: 3,
        });
    };

    useEffect(() => {
        props.getCategories({ companyId: Number(props.companyId) });
    }, [])

    useEffect(() => {
        if (prevPropsRef?.current?.getCategoriesState?.loading && !props.getCategoriesState?.loading) {
            if (props.getCategoriesState?.error) {
                setLoading(false);
                openNotificationWithIcon("error", 'Something went wrong');
            } else {
                setLoading(false);
            }
        }

        if (prevPropsRef?.current?.addCategoryState?.loading && !props.addCategoryState?.loading) {
            if (props.addCategoryState?.error) {
                openNotificationWithIcon("error", 'Something went wrong');
                props.getCategories({ companyId: Number(props.companyId) });
            } else {
                props.getCategories({ companyId: Number(props.companyId) });
                openNotificationWithIcon("success", props.addCategoryState?.data?.message);
                resetForm();
            }
        }

        if (prevPropsRef?.current?.editCategoriesState?.loading && !props.editCategoriesState?.loading) {
            if (props.editCategoriesState?.error) {
                openNotificationWithIcon("error", 'Something went wrong');
                props.getCategories({ companyId: Number(props.companyId) });
            } else {
                props.getCategories({ companyId: Number(props.companyId) });
                openNotificationWithIcon("success", props.editCategoriesState?.data?.message);
                resetForm();
            }
        }

        if (prevPropsRef?.current?.deleteCategoryState?.loading && !props.deleteCategoryState?.loading) {
            if (props.deleteCategoryState?.error) {
                props.getCategories({ companyId: Number(props.companyId) });
                openNotificationWithIcon("error", 'Something went wrong');
            } else {
                props.getCategories({ companyId: Number(props.companyId) });
                openNotificationWithIcon("success", props.deleteCategoryState?.data?.message);
            }
        }
        prevPropsRef.current = props;
    }, [props.getCategoriesState, props.addCategoryState, props.deleteCategoryState, props.editCategoriesState]
    );

    useEffect(() => {
        setIsManageCategoryOpen(props.isManageCategoryOpen);
    }, [props.isManageCategoryOpen]);

    useEffect(() => {
        setIsAddManageCategoryOpen(props.isAddManageCategoryOpen);
    }, [props.isAddManageCategoryOpen]);

    useEffect(() => {
        if (props.categoryData) {
            setCategories(props.categoryData);
        }
    }, [props.categoryData]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleSubmitCategory = () => {
        formRef.current
            .validateFields()
            .then((values: any) => {
                setLoading(true);
                const newCategory = {
                    categoryName: values.category,
                    companyId: Number(props.companyId),
                    parentId: parentId,
                    addedBy: Number(props.userId),
                    description: values.description,
                    ip_address: "127.0.0.1",
                };

                if (editMode && selectedNode) {
                    const updatedCategory = {
                        id: selectedNode.id,
                        categoryName: values.category,
                        description: values.description,
                        addedBy: Number(props.userId),
                        ip_address: "127.0.0.1",
                        companyId: Number(props.companyId),
                    };

                    props.editCategories(updatedCategory);

                    const updatedCategories = updateCategoryTree(
                        categories,
                        selectedNode.key,
                        updatedCategory
                    );
                    setCategories(updatedCategories);
                } else {
                    props.addCategory(newCategory);
                    const updatedCategories = parentId
                        ? addSubCategory(categories, parentId, newCategory)
                        : [...categories, newCategory];
                    if (parentId) {
                        setExpandedKeys((prevKeys) => [...prevKeys, parentId]);
                    }
                    setCategories(updatedCategories);
                }
            })
            .catch((errorInfo: any) => {
                console.error("Validation failed:", errorInfo);
            });
    };

    const resetForm = () => {
        formRef?.current?.resetFields();
        form.resetFields();
        setCategoryName('');
        setCategoryDescription('');
        setEditMode(false);
        setIsAddManageCategoryOpen(false);
        setParentId(null);
        setCategoryLevel(AppConstants.MANAGE_CATEGORIES_CONSTANTS.CATEGORY);
        setParentCategory(null);
        setGrandparentCategory(null);
    };

    const handleAddSubCategory = (category: any) => {
        setSelectedNode(category);
        setCategoryName('');
        setCategoryDescription('');
        setParentId(category.id);

        if (category.parentId) {
            setCategoryLevel(AppConstants.MANAGE_CATEGORIES_CONSTANTS.MICRO_CATEGORY);
            const grandparent = getParentCategory(categories, category.parentId);
            setParentCategory(category.name);
            setGrandparentCategory(grandparent ? grandparent.name : null);
        } else {
            setCategoryLevel(AppConstants.MANAGE_CATEGORIES_CONSTANTS.SUB_CATEGORY);
            setParentCategory(category.name);
            setGrandparentCategory(null);
        }
        setExpandedKeys((prevKeys) => [...prevKeys, category.id]);
        setIsAddManageCategoryOpen(true);
    };

    const handleEdit = (category: any) => {
        setSelectedNode(category);
        setEditMode(true);
        setCategoryLevel(
            category.parentId
                ? category.child?.length
                    ? AppConstants.MANAGE_CATEGORIES_CONSTANTS.SUB_CATEGORY
                    : AppConstants.MANAGE_CATEGORIES_CONSTANTS.MICRO_CATEGORY
                : AppConstants.MANAGE_CATEGORIES_CONSTANTS.CATEGORY
        );

        form.setFieldsValue({
            category: category.name,
            description: category.description,
        });

        setIsAddManageCategoryOpen(true);
    };

    const handleDelete = (category: any) => {
        setLoading(true)
        props.deleteCategory({ id: category.id });
    };

    const addSubCategory = (categories: any[], parentId: string, newCategory: any): any[] => {
        return categories.map((category) => {
            if (category.id === parentId) {
                return {
                    ...category,
                    child: [...(category.child || []), newCategory],
                };
            }
            return category.child
                ? { ...category, child: addSubCategory(category.child, parentId, newCategory) }
                : category;
        });
    };

    const updateCategoryTree = (categories: any[], key: string, updatedNode: any): any[] => {
        return categories.map(category => {
            if (category.id === key) {
                return { ...updatedNode };
            } else if (category.child) {
                return { ...category, child: updateCategoryTree(category.child, key, updatedNode) };
            }
            return category;
        });
    };

    const getParentCategory = (categories: any[], parentId: string): any | null => {
        for (let category of categories) {
            if (category.id === parentId) return category;
            if (category.child) {
                const result = getParentCategory(category.child, parentId);
                if (result) return result;
            }
        }
        return null;
    };

    const toggleExpand = (key: string) => {
        setExpandedKeys((prevKeys) => {
            if (prevKeys.includes(key)) {
                return prevKeys.filter(k => k !== key);
            }
            return [...prevKeys, key];
        });
    };

    const renderSwitcherIcon = (category: any) => {
        const isExpanded = expandedKeys.includes(category.id);
        const haschild = category.child && category.child.length > 0;

        if (haschild) {
            return isExpanded ? (
                <DownOutlined className="expand-icon" onClick={(e) => toggleExpand(category.id)} />
            ) : (
                <RightOutlined className="expand-icon" onClick={(e) => toggleExpand(category.id)} />
            );
        } else {
            return null;
        }
    };

    const filterCategories = (categories: any, query: any) => {
        if (!query) return categories;
        return categories
            .map((category: { name: string; child: any; }) => {
                if (
                    category.name.toLowerCase().includes(query.toLowerCase()) ||
                    (category.child && filterCategories(category.child, query).length > 0)
                ) {
                    return {
                        ...category,
                        child: category.child
                            ? filterCategories(category.child, query)
                            : []
                    };
                }
                return null;
            })
            .filter(Boolean);
    };

    const handleSearch = (e: any) => {
        const query = e.target.value;
        setSearchQuery(query);
        setFilteredCategories(filterCategories(props.categoryData, query));
    };

    const renderTreeNodes = (data: any[], parentKey = "") =>
        data.map((category, index) => {
            const uniqueKey = category.id ? category.id.toString() : `category-${parentKey}-${index}`;
            const isTopLevel = !category.parentId;
            const isSubCategory = category.parentId && !getParentCategory(categories, category.parentId)?.parentId;
            const categoryLevel = isTopLevel
                ? AppConstants.MANAGE_CATEGORIES_CONSTANTS.CATEGORY
                : isSubCategory
                    ? AppConstants.MANAGE_CATEGORIES_CONSTANTS.SUB_CATEGORY
                    : AppConstants.MANAGE_CATEGORIES_CONSTANTS.MICRO_CATEGORY;
            return (
                <TreeNode
                    title={(
                        <div className="categoryBox" style={{ gap: isTopLevel ? (!isMobileView ? 260 : 90) : isSubCategory ? (!isMobileView ? 236 : 66) : (!isMobileView ? 212 : 44) }}>
                            <span>{category.name}</span>
                            <div className="buttonContainer" style={{ justifyContent: isMobileView ? 'flex-start' : 'flex-end', gap: isMobileView ? '0px' : '15px', minWidth: isMobileView ? 'auto' : '250px' }}>
                                {(isTopLevel || isSubCategory) && (
                                    <Tooltip title={isTopLevel ? AppConstants.MANAGE_CATEGORIES_CONSTANTS.ADD_SUB_CATEGORY : AppConstants.MANAGE_CATEGORIES_CONSTANTS.ADD_MICRO_CATEGORY}>
                                        <Button
                                            className="actionIcons"
                                            icon={<PlusOutlined />}
                                            onClick={() => handleAddSubCategory(category)}
                                        />
                                    </Tooltip>
                                )}
                                <Tooltip title={`Edit ${categoryLevel}`}>
                                    <Button
                                        className="actionIcons"
                                        icon={<EditOutlined />}
                                        onClick={() => handleEdit(category)}
                                    />
                                </Tooltip>
                                <Tooltip title={`Delete ${categoryLevel}`}>
                                    <Button
                                        className="actionIcons"
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleDelete(category)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    )}
                    key={uniqueKey}
                    selectable={false}
                    switcherIcon={renderSwitcherIcon(category)}
                >
                    {category.child && renderTreeNodes(category.child, uniqueKey)}
                </TreeNode>
            );
        });

    const handleCancel = () => {
        resetForm();
        props.categoryDrawerStatus(false);
        setIsManageCategoryOpen(false);
        setIsAddManageCategoryOpen(false);
    };

    return (
        <>
            <Loader loading={loading}></Loader>
            <Drawer
                title={`Manage ${categoryLevel}`}
                placement="right"
                size="large"
                onClose={handleCancel}
                open={isManageCategoryOpen}
                extra={
                    <Space>
                        <Button onClick={handleCancel} className="buttomCancelMobView">Cancel</Button>
                        {isAddManageCategoryOpen &&
                            <Button type="primary" onClick={() => handleSubmitCategory()}>
                                {!editMode ? 'Submit' : 'Update'}
                            </Button>
                        }
                    </Space>
                }
            >
                {isAddManageCategoryOpen ? (
                    <>
                        <p className="backLinkSM" onClick={() => {
                            resetForm();
                            setIsAddManageCategoryOpen(false)
                        }
                        }>
                            <ArrowLeftOutlined /> Back to Categories
                        </p>
                        <Alert message={AppConstants.MANAGE_CATEGORIES_CONSTANTS.ABOUT_CATEGORIES_MESSAGE} type="info" />
                        <Form layout="vertical" form={form} className="category-form" ref={formRef}>
                            {grandparentCategory && (
                                <Form.Item label="Category">
                                    <Input value={grandparentCategory} disabled />
                                </Form.Item>
                            )}
                            {parentCategory && (
                                <Form.Item label={grandparentCategory ? AppConstants.MANAGE_CATEGORIES_CONSTANTS.SUB_CATEGORY : AppConstants.MANAGE_CATEGORIES_CONSTANTS.CATEGORY}>
                                    <Input value={parentCategory} disabled />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="category"
                                label={`${categoryLevel} Name`}
                                rules={[
                                    { required: true, message: `Please enter ${categoryLevel.toLowerCase()} name` },
                                    {
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            const isDuplicate = props.categoryData?.some(
                                                (category: any) => category.name.toLowerCase() === value.trim().toLowerCase()
                                            );
                                            if (isDuplicate) {
                                                return Promise.reject(new Error(`${categoryLevel} name already exists. Please use a different name.`));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={`${categoryLevel} name`}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    value={categoryName}
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={`${categoryLevel} Description`}
                            >
                                <Input.TextArea
                                    placeholder={`Description of ${categoryLevel}`}
                                    onChange={(e) => setCategoryDescription(e.target.value)}
                                    value={categoryDescription}
                                />
                            </Form.Item>
                        </Form>
                    </>
                ) : (
                    <>
                        <Alert message={AppConstants.MANAGE_CATEGORIES_CONSTANTS.ABOUT_CATEGORIES_MESSAGE} type="info" />
                        <div className="flexBox mt-20 mobAddCategory">
                            <Input placeholder="Search Category" className="input-search-category" value={searchQuery}
                                onChange={handleSearch} />
                            <Button
                                type="primary"
                                className="buttonAddCategory"
                                onClick={() => {
                                    resetForm();
                                    setIsAddManageCategoryOpen(true);
                                    setEditMode(false);
                                    setCategoryLevel('Category');
                                    setSelectedNode(null);
                                }}
                            >
                                Add New Category
                            </Button>
                        </div>
                        {searchQuery ? (
                            filteredCategories && filteredCategories.length > 0 ? (
                                <Tree className="list-category" showLine defaultExpandAll>{renderTreeNodes(filteredCategories)}</Tree>
                            ) : (
                                <div className="list-category center">No Data Found!</div>
                            )
                        ) : (
                            categories && categories.length > 0 ? (
                                <Tree className="list-category" key={categories.id} showLine defaultExpandAll>{renderTreeNodes(categories)}</Tree>
                            ) : (
                                <div className="list-category center">No Data Found!</div>
                            )
                        )}
                    </>
                )}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    categoryData: state.api.getCategory?.data?.data,
    companyId: state.api.login.data.companyId,
    userId: state.api.login.data.id,
    getCategoriesState: state.api?.getCategory,
    addCategoryState: state.api.addCategory,
    editCategoriesState: state.api?.editCategory,
    deleteCategoryState: state.api.deleteCategory,
});

const mapDispatchToProps = (dispatch: any) => ({
    getCategories: (payload: any) => dispatch(getCategories(payload)),
    addCategory: (payload: any) => dispatch(addCategory(payload)),
    editCategories: (payload: any) => dispatch(editCategory(payload)),
    deleteCategory: (payload: any) => dispatch(deleteCategory(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories);