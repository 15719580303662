import React, { useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Input,
  Popover,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { getStores } from "../../../redux/actions/API/storesActions";
import SeriesMetaDataModal from "../SeriesMetaData/SeriesMetaDataModal";
import { getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { AppConstants } from "../../../Appconstants";
import "./documentMetaDataForm.css";
import { getDocuments } from "../../../redux/actions/API/documents";
import {
  setAdditionalDetailsData,
  setAdditionalChargesData,
  setDocumentItemFormData,
  setDocumentItemGridData,
  setDocsTermsCondition,
  setLogisticDetailsData,
  setBankDetails,
} from "../../../redux/actions/UI/createDocument";
import { CustomField } from "./documentItemGrid/TableMetaDataConstant";
import moment from "moment";
import CustomMetaDataModal from "../CustomMetaDataModal";
import { getPaymentTerm } from "../../../redux/actions/API/paymentTerm";
import { getTransporterDetails } from "../../../redux/actions/API/transporterDetails";

interface IDocumentItemFormProps {
  companyId: number;
  userId: number;
  dataSource: [];
  getStores: Function;
  storesData: any;
  seriesData: any;
  paymentTermData: any;
  transporterData: any;
  documentsData: any;
  setDocumentItemForm: Function;
  getDocumentSeries: Function;
  getPaymentTerm: Function;
  getDocuments: Function;
  documentType: string;
  documentValidationData: any;
  setDocsTermsCondition: Function;
  setLogisticDetailsData: Function;
  setAdditionalDetailsData: Function;
  setAdditionalChargesData: Function;
  setDocumentItemGridData: Function;
  getTransporterDetails: Function;
  setBankDetails: Function;
  logisticsData: any[];
}

interface DocumentData {
  value: string;
  label: string;
}

const DocumentMetaDataForm: React.FC<IDocumentItemFormProps> = ({
  storesData,
  seriesData,
  getStores,
  documentsData,
  paymentTermData,
  transporterData,
  companyId,
  getPaymentTerm,
  setDocumentItemForm,
  documentType,
  documentValidationData,
  setDocsTermsCondition,
  setLogisticDetailsData,
  setAdditionalDetailsData,
  setAdditionalChargesData,
  setDocumentItemGridData,
  getTransporterDetails,
  logisticsData,
  setBankDetails,
}) => {
  const [documentMetaData, setDocumentMetaData] = useState<any>({
    documentNumber: "",
    currentSeriesNumber: 0,
    seriesId: 0,
    documentDate: moment(),
    enquiryNumber: "",
    quotationNumber: "",
    quotationDate: "",
    // enquiryDate: moment(),
    deliveryDate: "",
    store: "",
    paymentTerm: "",
    ammendment: 0,
    paymentDate: "",
    POCName: "",
    POCNumber: "",
    expectedReplyDate: "",
    kindAttention: "",
    purchaseOrderNumber: "",
    purchaseOrderDate: "",
    orderConfirmationNumber: "",
    orderConfirmationDate: "",
    transporterName: "",
    transporterGSTNumber: "",
    transportationDocumentNumber: "",
    vehicleNumber: "",
    transportationDocumentDate: "",
    invoiceNumber: "",
    returnRecieveDate: "",
    invoiceDate: "",
    creditNoteNumber: "",
    supplierInvoiceNumber: "",
    challanNumber: "",
    indent_number: "",
    gRNNumber: "",
    dNNDate: "",
    dNNumber: "",
    payToTransporter: "",
    indent_date: "",
    gRNDate: "",
    inspectionDate: "",
    challanDate: "",
    supplierInvoiceDate: "",
    creditNoteDate: "",
    billDate: "",
  });
  const prevPropsRef = useRef<any>();
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [selectedDocument, setSelectedDocument] = useState<string>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [storeDropdownData, setStoreDropdownData] = useState(storesData);
  const [docValidData, setDocValidData] = useState(documentValidationData);
  const [inputSeriesNumber, setInputSeriesNumber] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<"form" | "table">("form");
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [category, setCategory] = useState("");
  const [createdBy, setCreatedBy] = useState("Admin");
  const [currentField, setCurrentField] = useState<CustomField | null>(null);
  const [hideNonMandatory, setHideNonMandatory] = useState(false);

  const toggleLabel = hideNonMandatory ? "Hide" : "Show";
  const popoverContent = !hideNonMandatory
    ? "If you toggle this, the non-mandatory fields will be shown."
    : "If you toggle this, the non-mandatory fields will be hidden.";

  const handleOpenModal = (mode: "form" | "table") => {
    setModalMode(mode);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSaveFields = (
    fields: Omit<CustomField, "level" | "createdBy" | "createdAt">[],
    selectedCategory: string
  ) => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    const updatedFields: CustomField[] = fields.map((field) => ({
      ...field,
      level: modalMode === "form" ? "primary" : "item",
      createdBy,
      createdAt: currentTime,
      category: selectedCategory,
    }));

    setCustomFields((prevFields) => [...prevFields, ...updatedFields]);
    setCategory(selectedCategory);
    handleCloseModal();
  };

  useEffect(() => {
    if (
      prevPropsRef?.current?.documentValidationData !== documentValidationData
    ) {
      setDocValidData(documentValidationData);
    }
    prevPropsRef.current = {
      documentValidationData,
    };
  }, [documentValidationData]);

  useEffect(() => {
    getStores({ companyId: Number(companyId) });
    getPaymentTerm({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    setDocumentItemForm({ documentMetaData });
  }, [documentMetaData]);

  useEffect(() => {
    generateSeriesData();
  }, [seriesData]);

  useEffect(() => {
    getTransporterDetails({ companyId: Number(companyId) });
    generateStoreData();
  }, [storesData]);

  const handleFieldChange = (field: string, value: string) => {
    setDocumentMetaData((prevFormData: any) => {
      const updatedFormData = prevFormData?.map((form: any) =>
        form.documentNumber === documentMetaData.documentNumber
          ? { ...form, [field]: value }
          : form
      );
      setDocumentItemForm(updatedFormData);
      return updatedFormData;
    });
  };

  const handleSeriesModalClose = () => {
    setIsVisible(false);
  };

  const handleSeriesSelection = (selectedSeries: string) => {
    setSelectedDocument(selectedSeries);
    handleFieldChange("documentNumber", selectedSeries);
    handleSeriesModalClose();
  };

  const generateSeriesData = () => {
    let data: { value: string; label: string }[] = [];
    seriesData?.forEach((series: { seriesName: string }) => {
      data.push({ value: series.seriesName, label: series.seriesName });
    });

    const filteredSeries = seriesData?.filter(
      (series: { default: number; DocType: string }) =>
        series.default === 1 && series.DocType == documentType
    );

    if (filteredSeries && filteredSeries.length > 0) {
      const { prefix, nextNumber } = filteredSeries[0];
      setDocumentNumber(`${prefix}-${nextNumber}`);
      setDocumentMetaData({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id,
      });
      setDocumentItemForm({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id,
      });
    } else {
    }
  };

  const generateStoreData = () => {
    let data: any = [];
    storesData?.map((store: any) => {
      data.push({ value: store.name, label: store.name });
    });
    setStoreDropdownData(data);
  };

  const calculateExpectedPaymentDate = (daysToAdd: number) => {
    return moment().add(daysToAdd, "days");
  };

  const returnDocumentTypeTitle = () => {
    switch (documentType) {
      case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
        return "Sales Enquiry";
      case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
        return "Sales Quotation";
      case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
        return "Order Confirmation";
      case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
        return "Delivery Challan";
      case AppConstants.DOCUMENT_TYPE.INVOICE:
        return "Invoice";
      case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
        return "Sales Return";
      case AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE:
        return "Goods Receive Notes";
      case AppConstants.DOCUMENT_TYPE.QUALITY_REPORT:
        return "Quality Report";
      case AppConstants.DOCUMENT_TYPE.DEBIT_NOTE:
        return "Debit Note";
      case AppConstants.DOCUMENT_TYPE.CREDIT_NOTE:
        return "Credit Note";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN:
        return "Purchase Return";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER:
        return "Purchase Order";
    }
  };

  const returnDeliveryLabel = () => {
    switch (documentType) {
      case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.INVOICE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE:
        return "Delivery Date";
      case AppConstants.DOCUMENT_TYPE.QUALITY_REPORT:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.DEBIT_NOTE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.CREDIT_NOTE:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN:
        return "Expected Delivery Date";
      case AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER:
        return "Delivery Date";
    }
  };

  const renderQuotationNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Sales Quotation") || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "Quotation Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label}</label>
        <Select
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber || null}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            if (!value) {
              setDocumentItemGridData([]);
              setDocsTermsCondition([]);
              setLogisticDetailsData([]);
              setAdditionalDetailsData([]);
              setAdditionalChargesData([]);
              setBankDetails([]);
              return;
            }
            const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);

            console.log(selectedDocument, "selectedDocument in Quotation");

            const selectedLogisticsDetail = logisticsData.find((log) => log.id === selectedDocument?.logisticDetailsId);

            const quotationDate = selectedDocument?.documentDate || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;
            const store = selectedDocument?.store || null;
            const paymentTerm = selectedDocument?.paymentTerm || null;

            let calculatedPaymentDate: any = null;
            if (paymentTerm) {
              const selectedPaymentTerm = paymentTermData.find(
                (term: any) => term.name === paymentTerm
              );
              calculatedPaymentDate = selectedPaymentTerm
                ? calculateExpectedPaymentDate(selectedPaymentTerm.days)
                : null;
            } else {
              calculatedPaymentDate = null;
            }
            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              quotationNumber: value,
              quotationDate:
                documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION
                  ? quotationDate
                  : documentMetaData.documentDate,
              deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
              paymentTerm: paymentTerm !== "" ? paymentTerm : null,
              paymentDate: calculatedPaymentDate,
            });

            setDocumentItemForm({
              ...documentMetaData,
              quotationNumber: value,
              quotationDate:
                documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION
                  ? quotationDate
                  : documentMetaData.quotationDate,
              deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
              paymentTerm: paymentTerm || documentMetaData.paymentTerm,
              paymentDate: calculatedPaymentDate,
            });
            let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            console.log(filterByDocumentNumber[0]?.additionalCharges, "filterByDocumentNumber");

            let termsConditionData = filterByDocumentNumber[0]?.termsCondition; 
            const termsConditionArray = JSON.parse(termsConditionData?.termsCondition || '[]');

            setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            setDocsTermsCondition(termsConditionArray),
            setLogisticDetailsData(selectedLogisticsDetail);
            setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
            setAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
            setBankDetails(filterByDocumentNumber[0]?.bankDetails);
          }}
        />
      </Col>
    );
  };

  const renderQuotationDate = () => {
    const label = "Quotation Date";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>{label}</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={
              documentMetaData.quotationDate
                ? moment(documentMetaData.quotationDate)
                : null
            }
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                quotationDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                quotationDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderEnquiryNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Sales Enquiry") || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "Enquiry Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label}</label>
        <Select
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber || null}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            if (!value) {
              setDocumentItemGridData([]);
              setAdditionalChargesData([]);
              setLogisticDetailsData([]);
              setAdditionalDetailsData([]);
              setDocsTermsCondition([]);
              return;
            }
            const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);
            console.log("selectedDocument in enquiry", selectedDocument);
            const selectedLogisticsDetail = logisticsData.find(
              (log) => log.id === selectedDocument?.logisticDetailsId
            );
            const enquiryDate = selectedDocument?.documentDate || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;
            const store = selectedDocument?.store || null;
            const paymentTerm = selectedDocument?.paymentTerm || "";

            let calculatedPaymentDate: any = null;
            if (paymentTerm) {
              const selectedPaymentTerm = paymentTermData.find(
                (term: any) => term.name === paymentTerm
              );
              calculatedPaymentDate = selectedPaymentTerm
                ? calculateExpectedPaymentDate(selectedPaymentTerm.days)
                : null;
            } else {
              calculatedPaymentDate = null;
            }
            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              enquiryNumber: value,
              enquiryDate:
                documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
                  ? enquiryDate
                  : documentMetaData.documentDate,
              deliveryDate: deliveryDate,
              store: store,
              paymentTerm: paymentTerm !== "" ? paymentTerm : null,
              paymentDate: calculatedPaymentDate,
            });

            setDocumentItemForm({
              ...documentMetaData,
              enquiryNumber: value,
              enquiryDate:
                documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
                  ? enquiryDate
                  : documentMetaData.enquiryDate,
              deliveryDate: deliveryDate,
              store: store,
              paymentTerm: paymentTerm !== "" ? paymentTerm : null,
              paymentDate: calculatedPaymentDate,
            });
            let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);

            let termsConditionData = filterByDocumentNumber[0]?.termsCondition; 
            const termsConditionArray = JSON.parse(termsConditionData?.termsCondition || '[]');
            console.log(termsConditionArray, "Filter Document Number"); 
            setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            setDocsTermsCondition(termsConditionArray),
            setLogisticDetailsData(selectedLogisticsDetail);
            setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
            setAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
          }}
        />
      </Col>
    );
  };

  const renderEnquiryDate = () => {
    const label = "Enquiry Date";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label}</label>
        <DatePicker
          disabled
          className="documentMetaDataFormFields"
          value={documentMetaData.enquiryDate ? moment(documentMetaData.enquiryDate) : null}
          onChange={(date) => {
            setDocumentMetaData({
              ...documentMetaData,
              enquiryDate: date,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryDate: date,
            });
          }}
        />
      </Col>
    );
  };

  const renderDocumentNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div className="flexBox rowMobileDocumentLabel">
          <label>
            {returnDocumentTypeTitle()} Number
            <span className="deleteIcon"> *</span>
          </label>
          <span className="expoText" onClick={() => setIsVisible(true)}>
            Customize
          </span>
        </div>
        <Input
          placeholder={`Please enter ${returnDocumentTypeTitle()?.toLocaleLowerCase()} number`}
          value={documentNumber}
          disabled
          style={{ marginTop: "5px" }}
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              documentNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              documentNumber: ev.target.value,
            });
          }}
        />
        {docValidData && !docValidData[1] && (
          <span className="errorText">
            * {returnDocumentTypeTitle()} Number is required
          </span>
        )}
      </Col>
    );
  };

  const renderDocumentDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>
        {returnDocumentTypeTitle()} Date <span className="deleteIcon">*</span>
        </label>
        <DatePicker
          className="documentMetaDataFormFields"
          disabled
          defaultValue={moment()}
          onChange={() => {
            setDocumentMetaData({
              ...documentMetaData,
              documentDate: moment(),
            });
            setDocumentItemForm({
              ...documentMetaData,
              documentDate: moment(),
            });
          }}
        />
        {docValidData && !docValidData[2] && (
          <span className="errorText">* Document Date is required</span>
        )}
      </Col>
    );
  };

  const renderExpectedDeliveryDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>{returnDeliveryLabel()}</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={
              documentMetaData?.deliveryDate
                ? moment(documentMetaData.deliveryDate)
                : null
            }
            onChange={(date) => {
              setDocumentMetaData({
                ...documentMetaData,
                deliveryDate: date ? date.toISOString() : null,
              });
              setDocumentItemForm({
                ...documentMetaData,
                deliveryDate: date ? date.toISOString() : null,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderStore = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Store <span className="deleteIcon">*</span></label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select store"
          value={documentMetaData?.store || undefined}
          options={storesData?.map((stores: { name: any }) => ({
            value: stores.name,
            label: `${stores.name}`,
          }))}
          onChange={(option) => {
            const selectedStoreName = storesData.find(
              (stores: any) => stores.name === option
            );

            setDocumentMetaData({
              ...documentMetaData,
              store: selectedStoreName.name,
            });
            setDocumentItemForm({
              ...documentMetaData,
              store: selectedStoreName.name,
            });
          }}
        />
        {docValidData && !docValidData[3] && (
          <span className="errorText">* Store is required</span>
        )}
      </Col>
    );
  };

  const renderAmmendment = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Ammendment</label>
        <Input
          value={0}
          className="documentMetaDataFormFields"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              ammendment: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              ammendment: ev.target.value,
            });
          }}
          disabled
        />
      </Col>
    );
  };

  const renderPaymentTerm = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Payment Term</label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select Payment Term"
          allowClear
          value={documentMetaData.paymentTerm || undefined}
          options={paymentTermData?.map(
            (paymentTerm: { name: any; days: any }) => ({
              value: paymentTerm.name,
              label: `${paymentTerm.name} (${paymentTerm.days} Days)`,
            })
          )}
          onChange={(option: any) => {
            const selectedPayment = paymentTermData.find(
              (term: any) => term.name === option
            );

            const calculatedDate = selectedPayment
              ? calculateExpectedPaymentDate(selectedPayment.days)
              : null;

            setDocumentMetaData({
              ...documentMetaData,
              paymentTerm: option,
              paymentDate: calculatedDate,
            });
            setDocumentItemForm({
              ...documentMetaData,
              paymentTerm: option,
              paymentDate: calculatedDate,
            });
          }}
        />
      </Col>
    );
  };

  const renderExpectedPaymentDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Payment Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={documentMetaData.paymentDate || null}
            onChange={(date) => {
              setDocumentMetaData({
                ...documentMetaData,
                paymentDate: date,
              });
              setDocumentItemForm({
                ...documentMetaData,
                paymentDate: date,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderBillDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>
            Bill Date <span className="deleteIcon">*</span>
          </label>
          <DatePicker
            // defaultValue={moment()}
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                billDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                billDate: option,
              });
            }}
          />
        </div>
        {docValidData && !docValidData[4] && (
          <span className="errorText">* Bill Date is required</span>
        )}
      </Col>
    );
  };

  const renderPOC = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>POC Name</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter POC Name"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              POCName: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              POCName: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPOCNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>POC Number</label>
        <Input
          prefix="+91 "
          maxLength={10}
          inputMode="numeric"
          pattern="[0-9]*"
          className="documentMetaDataFormFields"
          placeholder="Please enter POC Number"
          onKeyDown={(e) => {
            if (e.key.length === 1 && !/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              POCNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              POCNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderExpectedReplyDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Expected Reply Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                expectedReplyDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                expectedReplyDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderKindAttention = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Kind Attention</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Kind Attention..."
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              kindAttention: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              kindAttention: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPurchaseOrderNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER) || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];

    const label = "Purchase Order Number";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label}</label>
        <Select
          className="documentMetaDataFormFields"
          placeholder={`Please enter ${label.toLowerCase()}`}
          value={documentMetaData?.purchaseOrderNumber || null}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}

          onChange={(value: string) => {
            if (!value) {
              setDocumentItemGridData([]);
              return;
            }
            const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);
            const purchaseOrderDate = moment(selectedDocument?.documentDate) || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;
            const store = selectedDocument?.store || null;
 
            setDocumentMetaData({
              ...documentMetaData,
              purchaseOrderNumber: value,
              purchaseOrderDate: purchaseOrderDate,
              deliveryDate: deliveryDate,
              store: store,
            });

            setDocumentItemForm({
              ...documentMetaData,
              purchaseOrderNumber: value,
              purchaseOrderDate: purchaseOrderDate,
              deliveryDate: deliveryDate,
              store: store,
            });
            let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);

            setDocumentItemGridData(filterByDocumentNumber[0]?.items);
          }}
        />

      </Col>
    );
  };

  const renderPurchaseOrderDate = () => {
    const label = "Purchase Order Date";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>{label}</label> 
          <DatePicker 
            className="documentMetaDataFormFields"
            value={documentMetaData?.purchaseOrderDate}
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                purchaseOrderDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                purchaseOrderDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderOrderConfirmationNumber = () => {
    const filteredDocuments =
      documentsData?.filter(
        (document: any) => document.documentType === "Order Confirmation"
      ) || [];
    const data =
      filteredDocuments?.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];

    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Order Confirmation Number</label>
        <Select
          className="documentMetaDataFormFields"
          placeholder="Please enter order confirmation number"
          value={documentMetaData?.orderConfirmationNumber || null}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            if (!value) {
              setDocumentItemGridData([]);
              setDocsTermsCondition([]);
              setLogisticDetailsData([]);
              setAdditionalDetailsData([]);
              setAdditionalChargesData([]);
              setBankDetails([]);
              return;
            }
          
          const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);

          console.log(selectedDocument, "Selected Document in Order confirmation");

          const selectedLogisticsDetail = logisticsData.find((log) => log.id === selectedDocument?.logisticDetailsId);

          const store = selectedDocument?.store || null;
          const purchaseOrderNumber = selectedDocument?.purchaseOrderNumber || null;
          const purchaseOrderDate = selectedDocument?.purchaseOrderDate || null;
          const orderConfirmationDate = selectedDocument?.documentDate || null;
           
          setInputSeriesNumber(value);
          setDocumentMetaData({
            ...documentMetaData,
            orderConfirmationNumber: value,
            orderConfirmationDate: orderConfirmationDate,
            purchaseOrderNumber: purchaseOrderNumber,
            purchaseOrderDate: purchaseOrderDate,
            store: store,
          });
            setDocumentItemForm({
              ...documentMetaData,
              orderConfirmationNumber: value,
              orderConfirmationDate:
                documentType === AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN
                  ? orderConfirmationDate
                  : documentMetaData.orderConfirmationDate,
              purchaseOrderNumber: purchaseOrderNumber,
              purchaseOrderDate: purchaseOrderDate,
              store: store,
            });

            let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            console.log(filterByDocumentNumber[0], "filterByDocumentNumber");

            let termsConditionData = filterByDocumentNumber[0]?.termsCondition; 
            const termsConditionArray = JSON.parse(termsConditionData?.termsCondition || '[]');

            setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            setDocsTermsCondition(termsConditionArray),
            setLogisticDetailsData(selectedLogisticsDetail);
            setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
            setAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
            setBankDetails(filterByDocumentNumber[0]?.bankDetails);
          }}
        />
      </Col>
    );
  };

  const renderOrderConfirmationDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Order Confirmation Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={moment(documentMetaData.orderConfirmationDate)}
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                orderConfirmationDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                orderConfirmationDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderTransporterName = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Transporter Name</label>
        <Select
          className="documentMetaDataFormFields"
          showSearch
          placeholder="Select Transporter Name"
          value={documentMetaData.transporterName || undefined}
          options={transporterData?.map((transporter: { name: any }) => ({
            value: transporter.name,
            label: `${transporter.name}`,
          }))}
          onChange={(option) => {
            const selectedTransporter = transporterData.find(
              (transporter: any) => transporter.name === option
            );

            setDocumentMetaData({
              ...documentMetaData,
              transporterName: selectedTransporter.name,
              transporterGSTNumber: selectedTransporter.GSTNumber,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transporterName: selectedTransporter.name,
              transporterGSTNumber: selectedTransporter.GSTNumber,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransporterGSTNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Transporter GST Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter transporter GST number"
          value={documentMetaData.transporterGSTNumber || ""}
          disabled
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              transporterGSTNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transporterGSTNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransportationDocumentNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Transportation Document Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter transportation document number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              transportationDocumentNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              transportationDocumentNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderVehicleNumber = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>Vehicle Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter vehicle number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              vehicleNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              vehicleNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderTransportationDocumentDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Transportation Document Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                transportationDocumentDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                transportationDocumentDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderInvoiceNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Invoice") || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
      const label = "Invoice";
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <label>{label} <span className="deleteIcon">*</span></label>
        <Select
         className="documentMetaDataFormFields"
         placeholder={`Please select or enter ${label.toLowerCase()}`}
         value={inputSeriesNumber || null}
         options={data?.map((doc: { value: any; label: any }) => ({
           value: doc.value,
           label: doc.label,
         }))}
         onChange={(value: string) => {
          if (!value) {
            setDocumentItemGridData([]);
            setAdditionalChargesData([]);
            setLogisticDetailsData([]);
            setAdditionalDetailsData([]);
            setDocsTermsCondition([]);
            return;
          }
          const selectedDocument = filteredDocuments.find((doc: { documentNumber: string }) => doc.documentNumber === value);
          console.log("selectedDocument in Invoice", selectedDocument);
          const selectedLogisticsDetail = logisticsData.find(
            (log) => log.id === selectedDocument?.logisticDetailsId
          );
          const invoiceDate = selectedDocument?.documentDate || null;
          const purchaseOrderNumber = selectedDocument?.purchaseOrderNumber || null;
          const purchaseOrderDate = selectedDocument?.purchaseOrderDate || null;
          const orderConfirmationNumber = selectedDocument?.orderConfirmationNumber || null;
          const orderConfirmationDate = selectedDocument?.orderConfirmationDate || null;
          const transporterName = selectedDocument?.transporterName || null;
          const transporterGSTNumber = selectedDocument?.transporterGSTNumber || null;
          const store = selectedDocument?.store || null;
          setInputSeriesNumber(value);
          setDocumentMetaData({
            ...documentMetaData,
            invoiceNumber: value,
            invoiceDate:
              documentType === AppConstants.DOCUMENT_TYPE.INVOICE
                ? invoiceDate
                : documentMetaData.documentDate,
            purchaseOrderNumber: purchaseOrderNumber,
            purchaseOrderDate: purchaseOrderDate,
            orderConfirmationNumber: orderConfirmationNumber,
            orderConfirmationDate: orderConfirmationDate,
            transporterName: transporterName,
            transporterGSTNumber: transporterGSTNumber,
            store: store,
          });

          setDocumentItemForm({
            ...documentMetaData,
            invoiceNumber: value,
            invoiceDate:
              documentType === AppConstants.DOCUMENT_TYPE.INVOICE
                ? invoiceDate
                : documentMetaData.enquiryDate,
            purchaseOrderNumber: purchaseOrderNumber,
            purchaseOrderDate: purchaseOrderDate,
            orderConfirmationNumber: orderConfirmationNumber,
            orderConfirmationDate: orderConfirmationDate,
            transporterName: transporterName,
            transporterGSTNumber: transporterGSTNumber,
            store: store,
          });
          let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);

          let termsConditionData = filterByDocumentNumber[0]?.termsCondition; 
          const termsConditionArray = JSON.parse(termsConditionData?.termsCondition || '[]');
          console.log(termsConditionArray, "Filter Document Number"); 
          setDocumentItemGridData(filterByDocumentNumber[0]?.items);
          setDocsTermsCondition(termsConditionArray),
          setLogisticDetailsData(selectedLogisticsDetail);
          setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails]);
          setAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
        }}         
         /> 
        {/* {docValidData && !docValidData[5] && (
          <span className="errorText">* Invoice Number is required</span>
        )} */}
      </Col>
    );
  };

  const renderInvoiceDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Invoice Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            value={documentMetaData?.invoiceDate || null}
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                documentDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                documentDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderReturnRecievedDate = () => {
    return (
      <Col className="gutter-row" span={6} xs={24} sm={24} lg={6}>
        <div>
          <label>Return Recieved Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                returnRecieveDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                returnRecieveDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderCreditNoteNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Credit Note Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter credit note number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              creditNoteNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              creditNoteNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderChallanNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Challan Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter supplier challan number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              challanNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              challanNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderSupplierInvoiceNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Supplier Invoice Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter supplier invoice number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              supplierInvoiceNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              supplierInvoiceNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderSupplierInvoiceDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Supplier Invoice Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                supplierInvoiceDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                supplierInvoiceDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderChallanDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Challan Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                challanDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                challanDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderIndentNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Indent Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter indent number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              indent_number: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              indent_number: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderGRNNumber = () => {
    const filteredDocuments =
      documentsData?.filter(
        (document: any) => document.documentType === "Goods Receive Notes"
      ) || [];
    const data =
      filteredDocuments?.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "GRN Number *";
    return (
      <Col className="gutter-row" span={6}>
        <label>{label}</label>
        {/* <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter GRN number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                gRNNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                gRNNumber: ev.target.value,
              });
            }}
          /> */}
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          // value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = filteredDocuments.find(
              (doc: { documentNumber: string }) => doc.documentNumber === value
            );
            const documentDate = selectedDocument?.documentDate || null;
            const store = selectedDocument?.store || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;

            // setInputSeriesNumber(value);
            // setDocumentMetaData({
            //   ...documentMetaData,
            //   gRNNumber: value,
            //   documentDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? documentDate
            //       : documentMetaData.documentDate,
            //   deliveryDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? deliveryDate
            //       : documentMetaData.deliveryDate,
            //   store: store || documentMetaData.store,
            // });

            // setDocumentItemForm({
            //   ...documentMetaData,
            //   gRNNumber: value,
            //   documentDate:
            //     documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT
            //       ? documentDate
            //       : documentMetaData.documentDate,
            //   // store: store || documentMetaData.store,
            // });
            // let filterByDocumentNumber = filteredDocuments.filter((doc: { documentNumber: any }) => doc.documentNumber === value);
            // setDocumentItemGridData(filterByDocumentNumber[0]?.items);
            // setDocsTermsCondition([filterByDocumentNumber[0]?.termsCondition]),
            // setLogisticDetailsData([filterByDocumentNumber[0]?.logisticDetails,]);
            // setAdditionalDetailsData([filterByDocumentNumber[0]?.additionalDetails,]);
            // setAdditionalChargesData(filterByDocumentNumber[0]?.additionalCharges);
          }}
          // disabled={!inputSeriesNumber}
        />
      </Col>
    );
  };

  const renderDNNumber = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>DN Number</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Please enter GRN number"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              dNNumber: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              dNNumber: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderPayToTransporter = () => {
    return (
      <Col className="gutter-row" span={6}>
        <label>Pay to transporter</label>
        <Input
          className="documentMetaDataFormFields"
          placeholder="Pay to transporter"
          onBlur={(ev: any) => {
            setDocumentMetaData({
              ...documentMetaData,
              payToTransporter: ev.target.value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              payToTransporter: ev.target.value,
            });
          }}
        />
      </Col>
    );
  };

  const renderIndentDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Indent Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                indent_date: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                indent_date: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderGRNDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>GRN Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                gRNDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                gRNDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderInspectionDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Inspection Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                inspectionDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                inspectionDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const renderDNNDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>DN Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                dNNDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                dNNDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  const handleDeleteField = (key: string) => {
    setCustomFields((prevFields) =>
      prevFields.filter((field) => field.key !== key)
    );
  };

  const handleEditField = (updatedField: CustomField) => {
    const updatedFields = customFields?.map((field) =>
      field.key === updatedField.key ? updatedField : field
    );
    setCustomFields(updatedFields);
    handleCloseModal();
  };

  const renderCustomFields = () => {
    return (
      <>
        {isModalVisible && (
          <CustomMetaDataModal
            mode={modalMode}
            isVisible={isModalVisible}
            customField={customFields}
            currentField={currentField}
            onSave={handleSaveFields}
            onClose={handleCloseModal}
            onEdit={handleEditField}
            onDelete={handleDeleteField}
          />
        )}
      </>
    );
  };

  const renderCreditNoteDate = () => {
    return (
      <Col className="gutter-row" span={6}>
        <div>
          <label>Credit Note Date</label>
          <DatePicker
            className="documentMetaDataFormFields"
            onChange={(option) => {
              setDocumentMetaData({
                ...documentMetaData,
                creditNoteDate: option,
              });
              setDocumentItemForm({
                ...documentMetaData,
                creditNoteDate: option,
              });
            }}
          />
        </div>
      </Col>
    );
  };

  return (
    <>
      <div className="documentMetaDataForm">
        <div className="flexBox mobAddCategory">
          <div></div>
          <div>
            <Switch
              checked={hideNonMandatory}
              onChange={(checked) => setHideNonMandatory(checked)}
              style={{ marginRight: "8px" }}
            />
            <span style={{ marginRight: "10px" }}>{toggleLabel}</span>
            <Popover content={popoverContent}>
              <Tooltip>
                <InfoCircleOutlined
                  style={{ fontSize: "16px", color: "#888" }}
                />
              </Tooltip>
            </Popover>
            {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER && (
              <Button color="primary" variant="filled" className="ml-15" onClick={() => handleOpenModal("form")}>
                Add Custom Field
              </Button>
            )}
          </div>
        </div>

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY && (
          <>
            <div className="containerDocumentMobileView">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderDocumentNumber()}
                {renderDocumentDate()}
                {renderStore()}
                {hideNonMandatory && renderAmmendment()}
              </Row>
              {hideNonMandatory && (
                <>
                  <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="mt-10"
                  >
                    {renderPaymentTerm()}
                    {renderExpectedPaymentDate()}
                    {renderPOC()}
                    {renderPOCNumber()}
                  </Row>
                  <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="mt-10"
                  >
                    {renderExpectedDeliveryDate()}
                    {renderExpectedReplyDate()}
                    {renderKindAttention()}
                  </Row>
                </>
              )}
            </div>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION && (
          <>
            <div className="containerDocumentMobileView">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderDocumentNumber()}
                {renderDocumentDate()}
                {renderStore()}
                {hideNonMandatory && renderEnquiryNumber()}
              </Row>
              {hideNonMandatory && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  className="mt-10"
                >
                  {renderEnquiryDate()}
                  {renderExpectedDeliveryDate()}
                  {renderPaymentTerm()}
                  {renderExpectedPaymentDate()}
                </Row>
              )}
              {hideNonMandatory && (
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  className="mt-10"
                >
                  {renderAmmendment()}
                </Row>
              )}
            </div>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderStore()}
              {hideNonMandatory && renderQuotationNumber()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {hideNonMandatory && renderQuotationDate()}
              {hideNonMandatory && renderExpectedDeliveryDate()}
              {hideNonMandatory && renderPaymentTerm()}
              {hideNonMandatory && renderExpectedPaymentDate()}
            </Row>

            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderAmmendment()}
                {renderPurchaseOrderNumber()}
                {renderPurchaseOrderDate()}
              </Row>
            )}
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderStore()}
              {hideNonMandatory && renderPurchaseOrderNumber()}
            </Row>
            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderPurchaseOrderDate()}
                {renderOrderConfirmationNumber()}
                {renderOrderConfirmationDate()}
                {renderTransporterName()}
              </Row>
            )}
            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderTransporterGSTNumber()}
                {renderTransportationDocumentNumber()}
                {renderVehicleNumber()}
                {renderTransportationDocumentDate()}
              </Row>
            )}
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.INVOICE && (
          <>
            <div className="containerDocumentMobileView">
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderDocumentNumber()}
                {renderDocumentDate()}
                {renderBillDate()}
                {hideNonMandatory ? (
                  <>{renderPurchaseOrderNumber()}</>
                ) : (
                  <>{renderExpectedPaymentDate()}</>
                )}
              </Row>

              {hideNonMandatory && (
                <>
                  <Row
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    className="mt-10"
                  >
                    {renderPurchaseOrderDate()}
                    {renderOrderConfirmationNumber()}
                    {renderOrderConfirmationDate()}
                    {renderPaymentTerm()}
                  </Row>
                </>
              )}

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {hideNonMandatory ? (
                  <>
                    {renderExpectedPaymentDate()}
                    {renderTransporterName()}
                    {renderTransporterGSTNumber()}
                  </>
                ) : (
                  <></>
                )}
                {renderStore()}
              </Row>
            </div>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_RETURN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderTransportationDocumentDate()}
              {renderReturnRecievedDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
              {renderOrderConfirmationNumber()}
              {renderOrderConfirmationDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderCreditNoteNumber()}
              {renderCreditNoteDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
              {renderVehicleNumber()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_ORDER && (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {renderCustomFields()}
            </div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderStore()}
              {hideNonMandatory && renderExpectedDeliveryDate()}
            </Row>
            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderIndentNumber()}
                {renderIndentDate()}
                {renderPaymentTerm()}
            </Row>
            )}
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.GOODS_RECEIVED_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderStore()}
              {renderPurchaseOrderNumber()}       
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">             
              {renderPurchaseOrderDate()}
              {hideNonMandatory && renderExpectedDeliveryDate()} 
              {hideNonMandatory &&renderSupplierInvoiceNumber()}
              {hideNonMandatory &&renderSupplierInvoiceDate()}
            </Row>
            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderChallanNumber()}
                {renderChallanDate()}     
                {renderTransporterName()}
                {renderTransportationDocumentNumber()}
              </Row>
            )}
            {hideNonMandatory && (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
                {renderTransportationDocumentDate()}
                {renderVehicleNumber()}
              </Row>
            )}
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.QUALITY_REPORT && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderGRNNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderGRNDate()}
              {renderPurchaseOrderDate()}
              {renderStore()}
              {renderInspectionDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_INVOICE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderSupplierInvoiceNumber()}
              {renderSupplierInvoiceDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
              {renderGRNNumber()}
              {renderTransporterName()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderTransporterGSTNumber()}
              {renderTransportationDocumentNumber()}
              {renderTransportationDocumentDate()}
              {renderPayToTransporter()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.DEBIT_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderCreditNoteNumber()}
              {renderCreditNoteDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.CREDIT_NOTE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderDNNumber()}
              {renderDNNDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>
          </>
        )}
        {documentType === AppConstants.DOCUMENT_TYPE.PURCHASE_RETURN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-10">
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
              {renderTransportationDocumentDate()}
            </Row>
          </>
        )}
      </div>
      <SeriesMetaDataModal
        isVisible={isVisible}
        onClose={handleSeriesModalClose}
        onSelectSeries={handleSeriesSelection}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data,
  seriesData: state.api.getDocumentSeries?.data?.reverse(),
  documentsData: state.api.getDocuments?.data,
  paymentTermData: state.api.getPaymentTerm?.data,
  transporterData: state.api.getTransporterDetails?.data,
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  dataSource: state.ui.createDocumentUIData.formData,
  documentType: state.ui.createDocumentUIData.documentType,
  logisticsData: state.api.getLogisticDetails?.data,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentItemForm: (payload: any) => dispatch(setDocumentItemFormData(payload)),
  getStores: (payload: any) => dispatch(getStores(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
  setDocumentItemGridData: (payload: any) => dispatch(setDocumentItemGridData(payload)),
  setDocsTermsCondition: (payload: any) => dispatch(setDocsTermsCondition(payload)),
  setLogisticDetailsData: (payload: any) => dispatch(setLogisticDetailsData(payload)),
  setAdditionalDetailsData: (payload: any) => dispatch(setAdditionalDetailsData(payload)),
  setAdditionalChargesData: (payload: any) => dispatch(setAdditionalChargesData(payload)),
  setBankDetails: (payload: any) => dispatch(setBankDetails(payload)),
  getPaymentTerm: (payload: any) => dispatch(getPaymentTerm(payload)),
  getTransporterDetails: (payload: any) => dispatch(getTransporterDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentMetaDataForm);
